import { Col, Row, Tabs, Typography } from 'antd';
import QueueAnim from 'rc-queue-anim';
import React, { useEffect, useState } from 'react';
import ReportHistoryTable from '../../components/report/ReportHistoryTable';
import { filter, get, map } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { DEFAULT_REGION_NAME } from '../../config/config';
import { clearReportFiltersAction, getReportDataForOrganisationRequestAction, getReportsForOrganisationRequestAction } from '../../store/report/actions';
import { Organisation } from '../../store/organisations/types';
import { ApplicationState } from '../../store';
import { getTranslatedText } from '../../utils/commonFunctions';
import { CompanyUserRole } from '../../store/companies/types';
import { setSelectedTabAction } from '../../store/organisations/actions';


const { Title } = Typography;
const { TabPane } = Tabs;

interface IProps { }

const ReportHistoryOrganisationPage: React.FC<IProps> = ({ }) => {
    const dispatch = useDispatch();
    const selectedUserOrganisation: Organisation = useSelector(
        (state: ApplicationState) => state.organisations.selectedUserOrganisation
    );
    const regions = get(selectedUserOrganisation, 'SupportedRegions').sort();
    const [selectedRegion, setSelectedRegion] = useState(DEFAULT_REGION_NAME);
    const userCompanies: CompanyUserRole[] = useSelector(
        (state: ApplicationState) => state.companies.userCompanies
    );

    /**
     * Function that fetches the companies by region
     */
    const getCompaniesByRegion = (region: string) => {
        if (!selectedUserOrganisation) return;
        const selectedOrganisationId = get(selectedUserOrganisation, 'OrganisationId');
        const companies = filter(userCompanies, uc => uc.Company.OrganisationId === selectedOrganisationId  && uc.Company.Region === region);

        return companies.map(c => ({
            CompanyId:  c.Company.CompanyId, 
            Name: c.Company.Name,
            IsCalendarView: c.Company.IsCalendarView
        }));                                         
    };

    const handleTabChange = (value: string) => {
        setSelectedRegion(value);
        if (regions) {
            const region = regions.find(x => x == value)
            if (region) {
                const companies = getCompaniesByRegion(region);
                dispatch(setSelectedTabAction({
                    tabName: region,
                    region: region,
                    usingCustomerWorkflow: false,
                    companies: companies ? companies : []
                }));
                dispatch(clearReportFiltersAction());
            }
        }
    };

    const populateReportContent = () => {
        if (regions && regions.length === 1) {
            return <ReportHistoryTable
                getReportsRequestAction={getReportsForOrganisationRequestAction}
                getReportDataRequestAction={(ReportJobId: string) => getReportDataForOrganisationRequestAction(ReportJobId, regions[0])}
                region={regions[0]}
            />
        }

        return <Tabs
            activeKey={selectedRegion}
            onChange={handleTabChange}
        >
            {map(regions, (region) => (
                <TabPane
                    tab={region === DEFAULT_REGION_NAME ? `${region} (Global)` : region}
                    key={region}
                >
                    {selectedRegion === region && <ReportHistoryTable
                        getReportsRequestAction={getReportsForOrganisationRequestAction}
                        getReportDataRequestAction={(ReportJobId: string) => getReportDataForOrganisationRequestAction(ReportJobId, region)}
                        region={selectedRegion}
                    />}
                </TabPane>
            )
            )}
        </Tabs>
    };

    return (<>
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container" type="flex" justify="space-between">
                    <Col>
                        <Title level={3}>{getTranslatedText('Report History')}</Title>
                    </Col>
                </Row>
                <div className="spacer-10" />
                <div className="spacer-10" />
                {populateReportContent()}
            </QueueAnim>
        </Col>
    </>)
};

export default ReportHistoryOrganisationPage;
