import {
    Col,
    Row,
    Tabs,
    Typography
} from 'antd';
import {
    isEmpty,
    map
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import OrganisationTasksHistoryContent from '../../../components/tasks/active_tasks/OrganisationTasksHistoryContent';
import { ApplicationState } from '../../../store';
import { setSelectedTabAction } from '../../../store/organisations/actions';
import {
    OrganisationsState
} from '../../../store/organisations/types';
import { computeOrganisationTabs } from '../../../utils/commonFunctions';
import { clearTasksStateAllTableFiltersAction } from '../../../store/tasks/actions';

const { Title } = Typography;
const { TabPane } = Tabs;

interface IProps {
}

const OrganisationTaskHistoryManagementPage: React.FC<IProps> = ({}: IProps) => {
    const dispatch = useDispatch();
	const userCompanies: OrganisationsState['userCompanies']['data'] = useSelector(
        (state: ApplicationState) => state.organisations.userCompanies.data
    );

	const tabs = useMemo(() => 
		computeOrganisationTabs(userCompanies), 
		[userCompanies]
	);

	const [selectedTab, setSelectedTab] = useState<string>();

    useEffect(() => {
        if (!tabs || isEmpty(tabs)) return;
        setSelectedTab(tabs[0].tabName);
        dispatch(setSelectedTabAction(tabs[0]));
    }, [tabs]);

    const handleTabChange = (value: string) => {
        setSelectedTab(value);
        if (tabs) {
            const tab = tabs.find(x => x.tabName == value)
            if (tab) {
                dispatch(setSelectedTabAction(tab));
                dispatch(clearTasksStateAllTableFiltersAction());
            }
        }
    };
    const populateContent = () => {
        if (tabs && tabs.length === 1) {
            return <OrganisationTasksHistoryContent
                companies={tabs[0].companies}
                usingCustomerWorkflow={tabs[0].usingCustomerWorkflow}
            />
        }

        return <Tabs
            activeKey={selectedTab}
            onChange={handleTabChange}
        >
            {map(tabs, (tab) => (
                <TabPane
                    tab={tab.tabName}
                    key={tab.tabName}
                >
                    {
                        selectedTab === tab.tabName 
                        && <OrganisationTasksHistoryContent
                            companies={tab.companies}
                            usingCustomerWorkflow={tab.usingCustomerWorkflow}
                        />
                    }
                </TabPane>
            )
            )}
        </Tabs>
    };

  	return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container">
                    <Col span={24}>
                        <Title level={3}>
                            Organisation Task Job History
                        </Title>
                    </Col>
                </Row>
                <div className="spacer-15" />
                {
                    populateContent()
                }
            </QueueAnim>
        </Col>
    );
};

export default OrganisationTaskHistoryManagementPage;
