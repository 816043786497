/**
 * File for the `Notification success` widget.
 */

import { Spin, Table } from 'antd';
import {
    clone,
    forEach,
    get,
    includes,
    isEmpty,
    isNumber,
    map,
    isUndefined,
    find
} from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    TooltipFormatter,
    XAxis,
    YAxis,
} from 'recharts';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import {
    widgetDisplayTypeValues,
    populatePayloadForOrganisationWidgets,
    widgetDateRangeValues,
    populatePayloadForOrganisationRegionalWidgets,
} from '../../constants/dashboards';
import { dateFormatYYYYMMDDDash } from '../../constants/dateFormats';
import { ApplicationState } from '../../store';
import { getDashboardNotificationSuccessRequestAction } from '../../store/dashboards/actions';
import { dashboardBypassAPIFetch, nFormatter, getTranslatedText } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    appliedFilterIndicator,
    customFieldIndicator,
} from '../common/FilterBar';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import {
    defaultEndDate,
    defaultStartDate,
} from './NotificationSuccessWidgetFields';
import { commonOrgFormFields } from './organisation/OrganisationWidgetCommonFilters';

interface IProps {
    widgetDetails: DynamicObject;
    readonly preview?: boolean;
    readonly formatCurrency: (
        amount: number,
        cusCurrencyCode?: string,
        cusLocale?: string
    ) => JSX.Element
    readonly isOrgView?: boolean;
    readonly functionRefObj?: any;
    readonly organisationCurrenciesAll?: DynamicObject[];
}
const COLORS = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#F44336',
    '#9C27B0',
    '#FFEB3B',
    '#795548',
    '#8BC34A',
    '#263238',
];

const NotificationSuccessWidget: React.FC<IProps> = ({
    widgetDetails,
    preview,
    formatCurrency,
    isOrgView,
    functionRefObj,
    organisationCurrenciesAll
}: IProps) => {
    const unmountedRef = useRef<any>(null);
    const dispatch = useDispatch();
    const widgetContainerRef = useRef<any>(null);

    /**
     * Variable indicator for when the company is using customer workflow or not.
     */
    const usingCustomerWorkflow: boolean = useSelector(
        (state: ApplicationState) =>
            get(
                state.companies.selectedUserCompany,
                'Company.UsingCustomerWorkflow'
            )
    );

    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );

    const [widgetState, setWidgetState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        dataSource: DynamicObject[];
        tickWidth: number;
    }>({
        lastWidgetDetails: {},
        loading: false,
        dataSource: [],
        tickWidth: 30,
    });

    let currencyValueUsed: any = undefined;
    let currencyCodeUsed: any = undefined;
    let localeUsed: any = undefined;
    if (isOrgView) {
        // const region = widgetDetails.Region;
        // const currencySelectedParsed = region
        //     ? find(organisationCurrenciesAll, ['Name', region])
        //     : undefined;

        const currencySelected = isOrgView
        ? get(widgetDetails, commonOrgFormFields.CURRENCY)
        : undefined;
        
        const currencySelectedParsed = currencySelected
        ? find(organisationCurrenciesAll, ['Value', currencySelected])
        : undefined;

        currencyValueUsed =
            currencySelectedParsed || get(organisationCurrenciesAll, 0);
        currencyCodeUsed = get(currencyValueUsed, 'Value');
        localeUsed = get(currencyValueUsed, 'Locale');
    }

     /**
     * Common function for formatting currencies
     */
     const handleFormatCurrency = (toFormat: number) => {
        return formatCurrency(toFormat, currencyCodeUsed, localeUsed);
    };

    /**
     * Common function for updating the `widgetState` state.
     * @param widgetStateObject
     */
    const updateWidgetStateObject = (widgetStateObject: {}) => {
        setWidgetState({
            ...widgetState,
            ...widgetStateObject,
        });
    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        let customerFieldValue: string = '';
        const customFieldFilters: DynamicObject = {};
        const customFieldsUsed = [CUSTOM_FIELD_TYPES.CUSTOMER];
        if (!usingCustomerWorkflow) {
            customFieldsUsed.push(CUSTOM_FIELD_TYPES.INVOICE);
        }

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            if (includes(wdKey, customFieldIndicator)) {
                const customFieldType = get(
                    wdKey.replace(customFieldIndicator, '').split('--'),
                    0
                );

                if (includes(customFieldsUsed, customFieldType)) {
                    customFieldFilters[wdKey + appliedFilterIndicator] =
                        wdValue;
                }
            }
            else if (includes(wdKey, 'Customer')) {
                customerFieldValue = wdValue;
            }
        });

        let startDate: any = clone(defaultStartDate);
        let endDate: any = clone(defaultEndDate);
        const dateRange = get(widgetDetails, 'dateRangeSelected');
        if (dateRange === widgetDateRangeValues.CUSTOM_DATE_RANGE) {
            const customStartDate =
                get(widgetDetails, 'customDateRangeStart') || defaultStartDate;
            const customEndDate =
                get(widgetDetails, 'customDateRangeEnd') || defaultEndDate;
            startDate =
                moment(customStartDate).format(dateFormatYYYYMMDDDash) +
                'T00:00:00';
            endDate =
                moment(customEndDate).format(dateFormatYYYYMMDDDash) +
                'T23:59:59';
        } else if (dateRange === widgetDateRangeValues.THIS_CALENDAR_YEAR) {
            startDate = moment().format('YYYY-01-01T00:00:00');
            endDate = moment().format('YYYY-12-31T23:59:59');
        } else if (dateRange === widgetDateRangeValues.LAST_CALENDAR_YEAR) {
            startDate = moment()
                .subtract(1, 'year')
                .format('YYYY-01-01T00:00:00');
            endDate = moment()
                .subtract(1, 'year')
                .format('YYYY-12-31T23:59:59');
        } else if (
            dateRange === widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU ||
            dateRange === widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU
        ) {
            const thisYearFinancialYearStart = moment().format(
                'YYYY-07-01T00:00:00'
            );
            const thisYearFinancialYearStartEnd = moment()
                .add(1, 'year')
                .format('YYYY-06-30T23:59:59');
            if (dateRange === widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU) {
                startDate = thisYearFinancialYearStart;
                endDate = thisYearFinancialYearStartEnd;
            } else if (
                dateRange === widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU
            ) {
                startDate = moment(thisYearFinancialYearStart)
                    .subtract(1, 'year')
                    .format(`${dateFormatYYYYMMDDDash}T00:00:00`);
                endDate = moment(thisYearFinancialYearStartEnd)
                    .subtract(1, 'year')
                    .format(`${dateFormatYYYYMMDDDash}T23:59:59`);
            }
        } else if (dateRange === widgetDateRangeValues.SENT_ANYTIME) {
            startDate = null;
            endDate = null;
        }

        let payload: DynamicObject = {
            filters: {
                ...customFieldFilters,
            },
            DateMin: startDate,
            DateMax: endDate,
            Customer: customerFieldValue
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationRegionalWidgets({
                payload,
                widgetDetails,
                organisationCompanies,
                organisationCurrenciesAll,
                currencyCodeUsed
            });
        }
        dispatch(
            getDashboardNotificationSuccessRequestAction(
                payload,
                isOrgView,
                (data: DynamicObject[]) => {
                    if (unmountedRef.current) return;

                    const dataSource = map(
                        get(data, 'NotificationSuccesses'),
                        (nd) => ({
                            AmountPaid: handleFormatCurrency(
                                get(nd, 'AmountPaid', 0)
                            ),
                            OriginalAmount: handleFormatCurrency(
                                get(nd, 'OriginalAmount', 0)
                            ),
                            PercentValue: get(nd, 'AmountPaid', 0),
                            PercentageRecovered:
                                get(nd, 'PercentageRecovered').toFixed(2) + '%',
                            StateName: nd.StateName,
                            'Amount Paid':  get(nd, 'AmountPaid', 0),
                            'Original Amount': get(nd, 'OriginalAmount', 0),
                        })
                    );

                    if (!isEmpty(dataSource)) {
                        dataSource.push({
                            AmountPaid: handleFormatCurrency(
                                get(data, 'AmountPaidTotal', 0)
                            ),
                            OriginalAmount: handleFormatCurrency(
                                get(data, 'InvoiceTotal', 0)
                            ),
                            PercentValue: get(data, 'InvoiceTotal', 0),
                            PercentageRecovered:
                                get(data, 'PercentageRecoveredTotal').toFixed(
                                    2
                                ) + '%',

                            StateName: 'Grand total',
                            'Amount Paid':  get(data, 'AmountPaidTotal', 0),
                            'Original Amount': get(data, 'InvoiceTotal', 0),
                        });
                    }

                    updateWidgetStateObject({
                        dataSource,
                        loading: false,
                        lastWidgetDetails: clone(widgetDetails),
                    });
                },
                payloadCallback
            )
        );
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        const bypassAPIFetching = dashboardBypassAPIFetch(
            widgetState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateWidgetStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    useEffect(initializeWidgetData, [widgetDetails]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    /**
     * Function for formatting the tooltip.
     * @param value
     */
    const tooltipFormatter: TooltipFormatter = (value: any, name: string) => {
        const formattedValue = isNumber(value) ? handleFormatCurrency(value) : value;
        const translatedName = getTranslatedText(name);
        return [formattedValue, translatedName];
    };

    /**
     * Function for rendering the labels for pie chart.
     * @param props
     */
    const renderCustomizedLabel = (props: any) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            midAngle,
            outerRadius,
            StateName,
            percent,
            innerRadius,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const textAnchor = cos >= 0 ? 'start' : 'end';
        const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
        const x = cx + radius * cos;
        const y = cy + radius * sin;
        return (
            <g>
                <text
                    x={x}
                    y={y}
                    textAnchor={textAnchor}
                    fill="#333"
                    dominantBaseline="central"
                >
                    {/*{getTranslatedText(StateName)}*/}
                    {StateName}
                </text>
                <text x={x} y={y} dy={18} textAnchor={textAnchor} fill="#999">
                    {`${(percent * 100).toFixed(2)}%`}
                </text>
            </g>
        );
    };

    /**
     * Function that sorts the order for values inside a tooltip.
     * @param tooltipPayload
     */
    const tooltipItemSorter = (tooltipPayload: any) => {
        if (tooltipPayload.dataKey === blueValueKey) {
            return 1;
        }
        return 0;
    };

    let orangeValueKey = 'Original Amount';
    let blueValueKey = 'Amount Paid';
    const { loading: stateLoading,  dataSource: stateDataSource} = widgetState;
    /**
     * Function that populates the widget content (if table, pie chart, or bar).
     */
    const populateWidgetContent = () => {
        const displayView = get(widgetDetails, 'displayType');
      
        if (displayView === widgetDisplayTypeValues.TABLE || displayView === undefined) {
            const columns = [
                {
                    title: 'Reminder',
                    dataIndex: 'StateName',
                },
                {
                    title: 'Invoice total',
                    dataIndex: 'OriginalAmount',
                },
                {
                    title: 'Paid',
                    dataIndex: 'AmountPaid',
                },
                {
                    title: 'Percentage recovered',
                    dataIndex: 'PercentageRecovered',
                },
            ];

            return (
                <Table
                    rowClassName={(record) => {
                        return get(record, 'StateName') === 'Grand total'
                            ? 'table-total-row'
                            : '';
                    }}
                    className="table-striped-rows table-ws-nw"
                    columns={columns.map(column => ({
                        ...column,
                        title: getTranslatedText(column.title)
                    }))}
                    dataSource={stateDataSource}
                    loading={stateLoading}
                    pagination={false}
                    size="middle"
                    rowKey="StateName"
                    locale={{
                        emptyText: getTranslatedText('No Data'),
                    }}
                />
            );
        } else if (displayView === widgetDisplayTypeValues.PIE_CHART) {
            const pieChartData = clone(stateDataSource);

            const filteredData = (pieChartData).filter((dataPoint: any) => dataPoint["PercentValue"] > 0);

            return (
                <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                    <ResponsiveContainer width="99%" height="99%">
                        <PieChart>
                            <Pie
                                paddingAngle={1}
                                minAngle={1}
                                data={filteredData}
                                label={renderCustomizedLabel}
                                labelLine={true}
                                outerRadius="70%"
                                fill="#8884d8"
                                dataKey="PercentValue"
                                nameKey="StateName"
                                isAnimationActive={false}
                            >
                                {map(filteredData, (_entry, index) => (
                                    <Cell
                                        key={index}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                            <Tooltip formatter={tooltipFormatter} />
                            {preview && <Legend formatter={(value) => getTranslatedText(value)} />}
                        </PieChart>
                    </ResponsiveContainer>
                </Spin>
            );
        } else {
            return (
                <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                    <ResponsiveContainer width="99%" aspect={2.2}>
                        <BarChart
                            data={stateDataSource}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 15,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="category" dataKey="StateName"/>
                            <YAxis type="number" />
                            
                            <Tooltip
                                formatter={tooltipFormatter}
                                itemSorter={tooltipItemSorter}
                            />
                            {preview && <Legend formatter={(value) => getTranslatedText(value)} />}
                            <Bar dataKey={blueValueKey} fill="#0088fe" />
                            <Bar dataKey={orangeValueKey} fill="#fd6a02"/>
                        </BarChart>
                    </ResponsiveContainer>
                </Spin>
            );
        }
        
    };

    return (
        <div className="notification-success-widget-container h-100"
        ref={widgetContainerRef}
        >
            {populateWidgetContent()}
        </div>
    );
};

export default withNumberFormatHandler(NotificationSuccessWidget);
