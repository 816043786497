import API from '@aws-amplify/api';
import { get } from 'lodash';
import {
    all,
    call,
    fork,
    takeLatest,
} from 'redux-saga/effects';
import {
    API_NAME
} from '../../config/config';
import { DynamicObject } from '../../utils/commonInterfaces';
import { DateRecoveryActionTypes } from './types';

/**
 * Function that submits the data recovery details to Rakspace API.
 * @param sagaPayload
 */
function* handleDataRecoveryRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        // To call async functions, use redux-saga's `call()`.

        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/company/restore/create',
            {
                body: payload,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;

            callback(returnData);
        }
    }
}

/**
 * Function that gets the list of hours of back up datas API.
 * @param sagaPayload
 */
function* handleGetBackupPerHourRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        // To call async functions, use redux-saga's `call()`.

        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/company/describe/dbsnapshot',
            {
                body: payload,
            }
        );

        if (callback) callback(res);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchDataRecoveryRequest() {
    yield takeLatest(
        DateRecoveryActionTypes.RECOVER_DATA_REQUEST,
        handleDataRecoveryRequest
    );
}

function* watchGetBackupPerHourRequest() {
    yield takeLatest(
        DateRecoveryActionTypes.GET_BACKUP_PER_HOUR_REQUEST,
        handleGetBackupPerHourRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* dataRecoverySaga() {
    yield all([
        fork(watchDataRecoveryRequest),
        fork(watchGetBackupPerHourRequest)
    ]);
}

export default dataRecoverySaga;
