import Auth from '@aws-amplify/auth';
import { get } from 'lodash';
import {
    API_NAME,
    API_ENDPOINT_DEV,
    AWS_REGION_SE_2,
    AWS_APPSYNC_AUTH_TYPE_COGNITO,
    API_ENDPOINT_TEST,
    API_ENDPOINT_PRETEST,
    API_ENDPOINT_UAT,
    API_ENDPOINT_NONPROD,
    API_ENDPOINT_PROD,
    APPSYNC_ENDPOINT_TEST,
    APPSYNC_ENDPOINT_PRETEST,
    APPSYNC_ENDPOINT_DEV,
    APPSYNC_ENDPOINT_UAT,
    APPSYNC_ENDPOINT_NONPROD,
    APPSYNC_ENDPOINT_PROD,
    USERPOOL_ID_DEV,
    USERPOOL_WEBCLIENT_ID_DEV,
    USERPOOL_ID_TEST,
    USERPOOL_ID_PRETEST,
    USERPOOL_WEBCLIENT_ID_PRETEST,
    USERPOOL_WEBCLIENT_ID_TEST,
    USERPOOL_ID_UAT,
    USERPOOL_WEBCLIENT_ID_UAT,
    USERPOOL_ID_NONPROD,
    USERPOOL_WEBCLIENT_ID_NONPROD,
    USERPOOL_ID_PROD,
    USERPOOL_WEBCLIENT_ID_PROD,
    AWS_APPSYNC_AUTH_TYPE_API_KEY,
} from './config/config';
import CustomSessionStorage from './components/Session/SessionStorage';
import { DynamicObject } from './utils/commonInterfaces';
import { getCurrentBuildEnvironment } from './utils/commonFunctions';

export enum Environments {
    DEV = 'dev',
    PROD = 'prod',
    TEST = 'test',
    UAT = 'uat',
    NONPROD = 'nonprod',
    PRETEST = 'pretest',
}

const customGraphQLHeader = async () => {
    try {
        const currentSession = await Auth.currentSession();
        // const token = get(currentSession, 'idToken.jwtToken');
        const token = get(currentSession, 'accessToken.jwtToken');
        return { Authorization: token };
    } catch (e) {
        console.error(e);
        return {};
        // Potentially you can retrieve it from local storage
    }
};

const customAPIHeader = async () => {
    try {
        const currentSession = await Auth.currentSession();
        // const token = get(currentSession, 'idToken.jwtToken');
        const token = get(currentSession, 'accessToken.jwtToken');
        return { Authorization: token };
    } catch (e) {
        console.error(e);
        return {};
    }
};

export const generateAmplifyConfig = (configData: DynamicObject) => {
    const environment = getCurrentBuildEnvironment();
    const apiGatewayUrlUsed: string | undefined = configData.Url || undefined;
    const appsyncUrlUsed: string | undefined = configData.Url
        ? `${configData.Url}/graphql`
        : undefined;

    let storedData = {
        AppsyncUrl: appsyncUrlUsed || APPSYNC_ENDPOINT_DEV,
        ApiGatewayUrl: apiGatewayUrlUsed || API_ENDPOINT_DEV,
        UserPoolId: USERPOOL_ID_DEV,
        UserPoolWebClientId: USERPOOL_WEBCLIENT_ID_DEV,
    };

    switch (environment) {
        case Environments.TEST:
            storedData = {
                AppsyncUrl: appsyncUrlUsed || APPSYNC_ENDPOINT_TEST,
                ApiGatewayUrl: apiGatewayUrlUsed || API_ENDPOINT_TEST,
                UserPoolId: USERPOOL_ID_TEST,
                UserPoolWebClientId: USERPOOL_WEBCLIENT_ID_TEST,
            };
            break;
        case Environments.PRETEST:
            storedData = {
                AppsyncUrl: appsyncUrlUsed || APPSYNC_ENDPOINT_PRETEST,
                ApiGatewayUrl: apiGatewayUrlUsed || API_ENDPOINT_PRETEST,
                UserPoolId: USERPOOL_ID_PRETEST,
                UserPoolWebClientId: USERPOOL_WEBCLIENT_ID_PRETEST,
            };
            break;    
        case Environments.UAT:
            storedData = {
                AppsyncUrl: appsyncUrlUsed || APPSYNC_ENDPOINT_UAT,
                ApiGatewayUrl: apiGatewayUrlUsed || API_ENDPOINT_UAT,
                UserPoolId: USERPOOL_ID_UAT,
                UserPoolWebClientId: USERPOOL_WEBCLIENT_ID_UAT,
            };
            break;
        case Environments.NONPROD:
            storedData = {
                AppsyncUrl: appsyncUrlUsed || APPSYNC_ENDPOINT_NONPROD,
                ApiGatewayUrl: apiGatewayUrlUsed || API_ENDPOINT_NONPROD,
                UserPoolId: USERPOOL_ID_NONPROD,
                UserPoolWebClientId: USERPOOL_WEBCLIENT_ID_NONPROD,
            };
            break;
        case Environments.PROD:
            storedData = {
                AppsyncUrl: appsyncUrlUsed || APPSYNC_ENDPOINT_PROD,
                ApiGatewayUrl: apiGatewayUrlUsed || API_ENDPOINT_PROD,
                UserPoolId: USERPOOL_ID_PROD,
                UserPoolWebClientId: USERPOOL_WEBCLIENT_ID_PROD,
            };
            break;
    }

    return {
        aws_appsync_graphqlEndpoint: storedData.AppsyncUrl,
        aws_appsync_region: configData.AwsRegion,
        aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_COGNITO,
        // aws_appsync_apiKey: 'da2-uak646xx55bupiflysxwmwjaqi',
        Auth: {
            mandatorySignIn: true,
            region: configData.AwsRegion,
            // userPoolId: 'ap-southeast-2_inkDg3mzW',
            // userPoolWebClientId: '5l61bjsphj8btskp7402uusj82',
            userPoolId: storedData.UserPoolId,
            userPoolWebClientId: storedData.UserPoolWebClientId,
            storage: CustomSessionStorage,
        },
        API: {
            graphql_headers: customGraphQLHeader,
            endpoints: [
                {
                    name: API_NAME,
                    endpoint: storedData.ApiGatewayUrl,
                    custom_header: customAPIHeader,
                },
            ],
        },
    };
};

export const generateAmplifyPublicConfig = (configData: DynamicObject) => {
    const environment = getCurrentBuildEnvironment();
    const apiGatewayUrlUsed: string | undefined = configData.Url || undefined;
    const appsyncUrlUsed: string | undefined = configData.Url
        ? `${configData.Url}/graphql`
        : undefined;
    let storedData = {
        AppsyncUrl: appsyncUrlUsed || APPSYNC_ENDPOINT_DEV,
        ApiGatewayUrl: apiGatewayUrlUsed || API_ENDPOINT_DEV,
    };

    switch (environment) {
        case Environments.TEST:
            storedData = {
                AppsyncUrl: appsyncUrlUsed || APPSYNC_ENDPOINT_TEST,
                ApiGatewayUrl: apiGatewayUrlUsed || API_ENDPOINT_TEST,
            };
            break;
        case Environments.PRETEST:
            storedData = {
                AppsyncUrl: appsyncUrlUsed || APPSYNC_ENDPOINT_PRETEST,
                ApiGatewayUrl: apiGatewayUrlUsed || API_ENDPOINT_PRETEST,
            };
            break;            
        case Environments.UAT:
            storedData = {
                AppsyncUrl: appsyncUrlUsed || APPSYNC_ENDPOINT_UAT,
                ApiGatewayUrl: apiGatewayUrlUsed || API_ENDPOINT_UAT,
            };
            break;
        case Environments.NONPROD:
            storedData = {
                AppsyncUrl: appsyncUrlUsed || APPSYNC_ENDPOINT_NONPROD,
                ApiGatewayUrl: apiGatewayUrlUsed || API_ENDPOINT_NONPROD,
            };
            break;
        case Environments.PROD:
            storedData = {
                AppsyncUrl: appsyncUrlUsed || APPSYNC_ENDPOINT_PROD,
                ApiGatewayUrl: apiGatewayUrlUsed || API_ENDPOINT_PROD,
            };
            break;
    }

    return {
        aws_appsync_graphqlEndpoint: storedData.AppsyncUrl,
        aws_appsync_region: configData.AwsRegion,
        aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_API_KEY,
        aws_appsync_apiKey: configData.AppsyncApiKey,
        API: {
            endpoints: [
                {
                    name: API_NAME,
                    endpoint: storedData.ApiGatewayUrl,
                },
            ],
        },
    };
};

const AMPLIFY_CONFIG_DEV = {
    environment: Environments.DEV,
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_DEV,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_COGNITO,
    // aws_appsync_apiKey: 'da2-uak646xx55bupiflysxwmwjaqi',
    Auth: {
        mandatorySignIn: true,
        region: AWS_REGION_SE_2,
        // userPoolId: 'ap-southeast-2_inkDg3mzW',
        // userPoolWebClientId: '5l61bjsphj8btskp7402uusj82',
        identityPoolId: 'ap-southeast-2:db3de7b1-8a17-4f05-92fd-9747645712e9',
        userPoolId: USERPOOL_ID_DEV,
        userPoolWebClientId: USERPOOL_WEBCLIENT_ID_DEV,
        storage: CustomSessionStorage,
    },
    API: {
        graphql_headers: customGraphQLHeader,
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_DEV,
                custom_header: customAPIHeader,
            },
        ],
    },
    predictions: {
        convert: {
            translateText: {
                region: AWS_REGION_SE_2,
                proxy: false,
                defaults: {
                    sourceLanguage: 'en-AU',
                    targetLanguage: 'en-AU'
                }
            }
        }
    }
};

// eslint-disable-next-line
const AMPLIFY_CONFIG_TEST = {
    environment: Environments.TEST,
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_TEST,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_COGNITO,
    // aws_appsync_apiKey: 'da2-i5eabhmz6bcypfxuijx6wlxqru',
    Auth: {
        mandatorySignIn: true,
        region: AWS_REGION_SE_2,
        userPoolId: USERPOOL_ID_TEST,
        userPoolWebClientId: USERPOOL_WEBCLIENT_ID_TEST,
        storage: CustomSessionStorage,
    },
    API: {
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_TEST,
                custom_header: customAPIHeader,
            },
        ],
    },
};

// eslint-disable-next-line
const AMPLIFY_CONFIG_PRETEST = {
    environment: Environments.PRETEST,
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_PRETEST,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_COGNITO,
    // aws_appsync_apiKey: 'da2-i5eabhmz6bcypfxuijx6wlxqru',
    Auth: {
        mandatorySignIn: true,
        region: AWS_REGION_SE_2,
        userPoolId: USERPOOL_ID_PRETEST,
        userPoolWebClientId: USERPOOL_WEBCLIENT_ID_PRETEST,
        storage: CustomSessionStorage,
    },
    API: {
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_PRETEST,
                custom_header: customAPIHeader,
            },
        ],
    },
};

// eslint-disable-next-line
const AMPLIFY_CONFIG_PROD = {
    environment: Environments.PROD,
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_PROD,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_COGNITO,
    // aws_appsync_apiKey: 'da2-i5eabhmz6bcypfxuijx6wlxqru',
    Auth: {
        mandatorySignIn: true,
        region: AWS_REGION_SE_2,
        userPoolId: USERPOOL_ID_PROD,
        userPoolWebClientId: USERPOOL_WEBCLIENT_ID_PROD,
        storage: CustomSessionStorage,
    },
    API: {
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_PROD,
                custom_header: customAPIHeader,
            },
        ],
    },
};

const AMPLIFY_CONFIG_UAT = {
    environment: Environments.UAT,
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_UAT,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_COGNITO,
    Auth: {
        mandatorySignIn: true,
        region: AWS_REGION_SE_2,
        userPoolId: USERPOOL_ID_UAT,
        userPoolWebClientId: USERPOOL_WEBCLIENT_ID_UAT,
        storage: CustomSessionStorage,
    },
    API: {
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_UAT,
                custom_header: customAPIHeader,
            },
        ],
    },
};

const AMPLIFY_CONFIG_NONPROD = {
    environment: Environments.NONPROD,
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_NONPROD,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_COGNITO,
    Auth: {
        mandatorySignIn: true,
        region: AWS_REGION_SE_2,
        userPoolId: USERPOOL_ID_NONPROD,
        userPoolWebClientId: USERPOOL_WEBCLIENT_ID_NONPROD,
        storage: CustomSessionStorage,
    },
    API: {
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_NONPROD,
                custom_header: customAPIHeader,
            },
        ],
    },
};

/**
 * Public API configs
 */

export const AMPLIFY_PUBLIC_CONFIG_DEV = {
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_DEV,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_API_KEY,
    aws_appsync_apiKey: 'da2-cubr3c2sbbh7bniv7rw2varnny',
    API: {
        graphql_headers: customGraphQLHeader,
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_DEV,
                custom_header: customAPIHeader,
            },
        ],
    },
};

export const AMPLIFY_PUBLIC_CONFIG_TEST = {
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_TEST,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_API_KEY,
    aws_appsync_apiKey: 'da2-76h7leap6vdo3dkjzqd6vx7yvi',
    API: {
        graphql_headers: customGraphQLHeader,
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_TEST,
                custom_header: customAPIHeader,
            },
        ],
    },
};

export const AMPLIFY_PUBLIC_CONFIG_PRETEST = {
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_PRETEST,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_API_KEY,
    aws_appsync_apiKey: 'da2-hg3ykejqaneftegrnalmx7oszi',
    API: {
        graphql_headers: customGraphQLHeader,
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_PRETEST,
                custom_header: customAPIHeader,
            },
        ],
    },
};

export const AMPLIFY_PUBLIC_CONFIG_UAT = {
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_UAT,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_API_KEY,
    aws_appsync_apiKey: 'da2-redmah7shbdktbwx2swkl6k6qa',
    API: {
        graphql_headers: customGraphQLHeader,
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_UAT,
                custom_header: customAPIHeader,
            },
        ],
    },
};

export const AMPLIFY_PUBLIC_CONFIG_NONPROD = {
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_NONPROD,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_API_KEY,
    aws_appsync_apiKey: 'da2-urzgcsehojhrplmbmpapqwmadq',
    API: {
        graphql_headers: customGraphQLHeader,
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_NONPROD,
                custom_header: customAPIHeader,
            },
        ],
    },
};

export const AMPLIFY_PUBLIC_CONFIG_PROD = {
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_PROD,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_API_KEY,
    aws_appsync_apiKey: 'da2-g2zfdd442bfo5mjm6q7epig5om',
    API: {
        graphql_headers: customGraphQLHeader,
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_PROD,
                custom_header: customAPIHeader,
            },
        ],
    },
};

let defaultConfig: any = AMPLIFY_CONFIG_DEV; // defaults to AMPLIFY_CONFIG_DEV if REACT_APP_ENV is not defined

if (process.env.REACT_APP_STAGE === Environments.DEV) {
    defaultConfig = AMPLIFY_CONFIG_DEV;
} else if (process.env.REACT_APP_STAGE === Environments.PROD) {
    defaultConfig = AMPLIFY_CONFIG_PROD;
} else if (process.env.REACT_APP_STAGE === Environments.TEST) {
    defaultConfig = AMPLIFY_CONFIG_TEST;
} else if (process.env.REACT_APP_STAGE === Environments.UAT) {
    defaultConfig = AMPLIFY_CONFIG_UAT;
} else if (process.env.REACT_APP_STAGE === Environments.NONPROD) {
    defaultConfig = AMPLIFY_CONFIG_NONPROD;
} else if (process.env.REACT_APP_STAGE === Environments.PRETEST) {
    defaultConfig = AMPLIFY_CONFIG_PRETEST;
}

export default defaultConfig;
