import { action } from 'typesafe-actions';
import { ResponseModalObject } from '../../utils/commonInterfaces';
import {
    DateRecoveryActionTypes
} from './types';

/**
 * Actions for submitting the recovery details,
 */
export const recoverDataRequestAction = (
    payload: any,
    callback?: (response: ResponseModalObject) => void
) =>
    action(DateRecoveryActionTypes.RECOVER_DATA_REQUEST, {
        payload,
        callback
    });

/**
 * Actions for getting the hours of backup available,
 */
export const getBackupPerHourAction = (
    payload: any,
    callback?: (response: ResponseModalObject) => void
) =>
    action(DateRecoveryActionTypes.GET_BACKUP_PER_HOUR_REQUEST, {
        payload,
        callback
    });