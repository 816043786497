import update from 'immutability-helper';
import { concat, get, isEqual, keyBy, merge, uniqWith, values } from 'lodash';
import { Reducer } from 'redux';
import { PAYMENTS_PAGE, DETAILS_TAB } from '../../config/tableAndPageConstants';
import { conversationTableFilterOptions } from '../../constants/common';
import {
    invoicesSortByOptions,
    invoicesStateFilterOptions,
} from '../../constants/invoicesSortAndFilters';
import {
    paymentsSortByOptions,
    paymentChangesTableFilterOptions,
    paymentStateFilterOptions,
} from '../../constants/paymentsSortAndFilters';
import { PaymentsActionTypes, PaymentsState } from './types';

// Type-safe initialState!
export const initialState: PaymentsState = {
    loading: false,
    savePaymentDataLoading: false,
    errorMessages: [],
    data: [],
    pageData: {
        pageSize: PAYMENTS_PAGE.pageSize,
        currentPage: 0,
        hasNextPage: false,
    },
    filters: {},
    ActiveState: get(paymentStateFilterOptions, `${0}.value`),
    tableFilter: undefined,
    sortBy: paymentsSortByOptions[2].value, //2 for payment date
    sortAscending: false, // Descending order by default
    bankFileFormats: [],
    activeData: {
        record: {},
        invoices: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.INVOICE_LIST_COMPLETE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {
                InvoiceNumber: '',
                Customer: '',
                CustomerCode: '',
                CreatedDateMin: '',
                CreatedDateMax: '',
                DueDateMin: '',
                DueDateMax: '',
                AmountType: '',
                AmountValue: '',
                AmountOperator: '',
            },
            invoiceState: get(invoicesStateFilterOptions, `${1}.value`),
            sortBy: invoicesSortByOptions[0].value, // 2 for Invoice number
            sortAscending: true,
        },
        conversation: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            conversationTableFilter: get(
                conversationTableFilterOptions,
                '0.value'
            ),
            sortBy: 'Created Date Time',
            sortAscending: false,
        },
        paymentChanges: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            paymentChangesTableFilter: get(
                paymentChangesTableFilterOptions,
                '0.value'
            ),
            sortBy: 'Created Date Time',
            sortAscending: false,
        },
        loading: false,
        selectedId: null,
        errorMessages: [],
    },
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<PaymentsState> = (state = initialState, action) => {
    switch (action.type) {
        case PaymentsActionTypes.GET_PAYMENTS_REQUEST: {
            return { ...state, loading: true };
        }
        case PaymentsActionTypes.GET_PAYMENTS_SUCCESS: {
            let newDataState = [];
            if (action.payload.mergeData === true) {
                newDataState = values(
                    merge(
                        keyBy(state.data, 'Id'),
                        keyBy(action.payload.data, 'Id')
                    )
                );
            } else {
                if (action.payload.pageData.currentPage === 0) {
                    newDataState = update(state.data, {
                        $set: action.payload.data,
                    });
                } else {
                    newDataState = uniqWith(
                        concat(state.data, action.payload.data),
                        isEqual
                    );
                }
            }

            return {
                ...state,
                loading: false,
                data: newDataState,
                pageData: action.payload.pageData,
                errorMessages: initialState.errorMessages,
            };
        }
        case PaymentsActionTypes.GET_PAYMENTS_ERROR: {
            return {
                ...state,
                loading: false,
                data: initialState.data,
                errorMessages: action.payload,
            };
        }
        case PaymentsActionTypes.UPDATE_PAYMENTS_FILTERS: {
            return { ...state, filters: action.payload };
        }

        case PaymentsActionTypes.UPDATE_PAYMENTS_SORT_BY_AND_STATE: {
            return {
                ...state,
                sortBy: action.payload.sortBy,
                sortAscending: action.payload.sortAscending,
                ActiveState: action.payload.ActiveState
            };
        }

        case PaymentsActionTypes.UPDATE_PAYMENTS_TABLE_FILTER_STATE: {
            return {
                ...state,
                tableFilter: action.payload,
            };
        }

        case PaymentsActionTypes.CLEAR_PAYMENTS_STATE_ALL_TABLE_FILTERS: {
            return {
                ...state,
                filters: initialState.filters,
                sortBy: initialState.sortBy,
                sortAscending: initialState.sortAscending,
                tableFilter: initialState.tableFilter,
                ActiveState: initialState.ActiveState
            };
        }

        case PaymentsActionTypes.CLEAR_PAYMENTS_STATE_DATA: {
            return {
                ...initialState,
            };
        }

        //Single Record
        case PaymentsActionTypes.SET_PAYMENT_SELECTED_ID_REQUEST: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    selectedId: initialState.activeData.selectedId,
                },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.SET_PAYMENT_SELECTED_ID_SUCCESS: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    selectedId: action.payload,
                },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.GET_PAYMENT_DATA_REQUEST: {
            const newActiveData = update(state.activeData, {
                record: { $set: initialState.activeData.record },
                loading: { $set: true },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.GET_PAYMENT_DATA_SUCCESS: {
            const newActiveData = update(state.activeData, {
                record: { $set: action.payload.record },
                loading: { $set: false },
                errorMessages: { $set: initialState.activeData.errorMessages },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.GET_PAYMENT_DATA_ERROR: {
            const newActiveData = update(state.activeData, {
                record: { $set: initialState.activeData.record },
                loading: { $set: false },
                errorMessages: { $set: action.payload },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.GET_BANK_FILE_FORMATS_REQUEST: {
            return { ...state };
        }

        case PaymentsActionTypes.GET_BANK_FILE_FORMATS_SUCCESS: {
            const newState = update(state, {
                $merge: {
                    bankFileFormats: action.payload.bankFileFormats
                }
            });

            return {
                ...newState
            };
        }

        case PaymentsActionTypes.GET_BANK_FILE_FORMATS_ERROR: {
            return {
                ...state,
                loading: false,
                errorMessages: action.payload
            };
        }

        case PaymentsActionTypes.CLEAR_PAYMENT_DATA_SUCCESS: {
            return {
                ...state,
                activeData: initialState.activeData,
            };
        }

        // For invoices list reducers
        case PaymentsActionTypes.GET_PAYMENT_INVOICES_REQUEST: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.GET_PAYMENT_INVOICES_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.invoices.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(state.activeData.invoices.data, action.payload.data),
                    isEqual
                );
            }

            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.invoices.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.GET_PAYMENT_INVOICES_ERROR: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.invoices.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.UPDATE_PAYMENT_INVOICES_FILTERS: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case PaymentsActionTypes.UPDATE_PAYMENT_INVOICES_SORT_BY_AND_STATE: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                    invoiceState: action.payload.invoiceState,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }
        case PaymentsActionTypes.UPDATE_PAYMENT_INVOICES_TABLE_FILTER_STATE: {
            const newInvoicesState = update(state.activeData.invoices, {
                $merge: {
                    invoiceState: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                invoices: { $set: newInvoicesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        // For conversation list reducers
        case PaymentsActionTypes.GET_ORGANISATION_PAYMENT_CONVERSATION_REQUEST:
        case PaymentsActionTypes.GET_PAYMENT_CONVERSATION_REQUEST: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.GET_PAYMENT_CONVERSATION_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.conversation.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeData.conversation.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.conversation.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.GET_PAYMENT_CONVERSATION_ERROR: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.conversation.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.UPDATE_PAYMENT_CONVERSATION_FILTERS: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.UPDATE_PAYMENT_CONVERSATION_TABLE_FILTER: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    conversationTableFilter: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.UPDATE_PAYMENT_CONVERSATION_SORT_BY: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        // For payment changes list reducers
        case PaymentsActionTypes.GET_ORGANISATION_PAYMENT_CHANGES_REQUEST:
        case PaymentsActionTypes.GET_PAYMENT_PAYMENT_CHANGES_REQUEST: {
            const newPaymentChangesState = update(
                state.activeData.paymentChanges,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                paymentChanges: { $set: newPaymentChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.GET_PAYMENT_PAYMENT_CHANGES_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.paymentChanges.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeData.paymentChanges.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newPaymentChangesState = update(
                state.activeData.paymentChanges,
                {
                    $merge: {
                        loading: false,
                        data: newDataState,
                        pageData: action.payload.pageData,
                        errorMessages:
                            initialState.activeData.paymentChanges
                                .errorMessages,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                paymentChanges: { $set: newPaymentChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.GET_PAYMENT_PAYMENT_CHANGES_ERROR: {
            const newPaymentChangesState = update(
                state.activeData.paymentChanges,
                {
                    $merge: {
                        loading: false,
                        data: initialState.activeData.paymentChanges.data,
                        errorMessages: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                paymentChanges: { $set: newPaymentChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.UPDATE_PAYMENT_PAYMENT_CHANGES_FILTERS: {
            const newPaymentChangesState = update(
                state.activeData.paymentChanges,
                {
                    $merge: {
                        filters: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                paymentChanges: { $set: newPaymentChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.UPDATE_PAYMENT_PAYMENT_CHANGES_TABLE_FILTER: {
            const newPaymentChangesState = update(
                state.activeData.paymentChanges,
                {
                    $merge: {
                        paymentChangesTableFilter: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                paymentChanges: { $set: newPaymentChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case PaymentsActionTypes.UPDATE_PAYMENT_PAYMENT_CHANGES_SORT_BY: {
            const newPaymentChangesState = update(
                state.activeData.paymentChanges,
                {
                    $merge: {
                        sortBy: action.payload.sortBy,
                        sortAscending: action.payload.sortAscending,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                paymentChanges: { $set: newPaymentChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as paymentsReducer };
