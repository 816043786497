export const reportHistorySortValues = {
    CREATED_DATE: 'Report created date'
};

export const reportSortByOptions = [
    { label: 'Report created date', value: reportHistorySortValues.CREATED_DATE },
];

export const preConfiguredFilters = {
    ALL_REPORTS: 'All reports',
};

export const reportsTableFilterOptions = [
    { label: 'All reports', value: preConfiguredFilters.ALL_REPORTS },
];

export const dateSelectOptions = {
    WITHIN_THIS_WEEK: 'Within this week',
    LAST_WEEK_UP_TO_NOW: 'Last week up to now',
    CUSTOM_DATE_RANGE: 'Custom date range',
    CUSTOM_DAYS_RANGE: 'Custom days range',
    TODAY: 'Today'
};

const {
    TODAY,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
    LAST_WEEK_UP_TO_NOW,
    WITHIN_THIS_WEEK
} = dateSelectOptions;

export const reportsCreatedInOptions = [
    TODAY,
    WITHIN_THIS_WEEK,
    LAST_WEEK_UP_TO_NOW,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE
];

export enum ReportStatus {
    InProgress = 'InProgress',
    Complete = 'Complete',
    Failed = 'Failed',
};

export const reportStateLabels: { [key: string]: string } = {
    [ReportStatus.InProgress]: 'In progress',
    [ReportStatus.Complete]: 'Complete',
    [ReportStatus.Failed]: 'Failed',
};

export const reportStateOptions = [
    {
        label: reportStateLabels.InProgress,
        value: 4,
    },
    {
        label: reportStateLabels.Failed,
        value: 2,
    },
    {
        label: reportStateLabels.Complete,
        value: 1,
    },
];

export enum ReportType {
    Page = 1,
    Dashboard = 2,
    CompleteReport = 4
};

export const ReportTypeString = {
    Page: 'Page',
    Dashboard: 'Dashboard',
    CompleteReport: 'CompleteReport'
}

export const ScheduledReportDateFilter = (reportType: string): {label: string, value: string, required: boolean}[] => {
    const filters = [
        {
            filterRequired: false,
            filterName: ['INVOICES', 'TOP_INVOICES'],
            filterOptions: [
                'Created Date',
                'Due Date'
            ]
        },
        {
            filterRequired: false,
            filterName: ['CREDITS', 'TOP_CREDITS'],
            filterOptions: [
                'Created Date',
                'Available Date'
            ]
        },
        {
            filterRequired: false,
            filterName:[ 'CHANGES', 'CHANGE_STATISTICS', 'RECENT_CHANGES', 'CREDIT RECONCILIATION'],
            filterOptions: [
                'Created Date Time',
            ]
        },
        {
            filterRequired: false,
            filterName: ['CUSTOMERS_SETTLEMENT'],
            filterOptions: [
                'Settlement Date',
            ]
        },
        {
            filterRequired: false,
            filterName: ['TASKS', 'TASKS_FOR_USERS', 'ACTIVE TASKS','TASK HISTORY', 'TASK_COUNT_ACTIONED_BY_USER', 'GET_TASKS_FOR_ORGANISATION'],
            filterOptions: [
                'Action Date',
            ]
        },
        {
            filterRequired: false,
            filterName: ['ACTIVE TICKETS' , 'TICKETS' , 'TICKETS_FOR_USERS', 'OPEN_SUPPORT_TICKETS'],
            filterOptions: [
                'Created Date',
                'Closed Date'
            ]
        },
        {
            filterRequired: false,
            filterName: ['OPEN_SUPPORT_TICKETS', 'PAYMENT PLANS'],
            filterOptions: [
                'Created Date'
            ]
        },
        {
            filterRequired: true,
            filterName: ['SALES', 'INVOICING_AND_SETTLEMENTS', 'NOTIFICATION_SUCCESS', 'DAILY_SALES_OUT'],
            filterOptions: [
                'Date',
            ]
        },
        {
            filterRequired: true,
            filterName: ['CASHFLOW_FORECASTING'],
            filterOptions: [
                'Due Date',
            ]
        },
        {
            filterRequired: true,
            filterName: ['COMMUNICATION_DELIVERY'],
            filterOptions: [
                'Created Date',
            ]
        },
        {
            filterRequired: false,
            filterName: ['PAYMENTS_ALLOCATION', 'REMITTANCE ADVICES', 'PAYMENTS'],
            filterOptions: [
                'Paid Date'
            ]
        }
    ];

    const matchingFilter = filters.find(filter =>
        filter.filterName.includes(reportType ? reportType.toUpperCase() : reportType));

    if (!matchingFilter) {
        return [];
    }

    const filterRequired = matchingFilter.filterRequired;

    const filter = matchingFilter.filterOptions.map(option => ({
        label: option,
        value: option,
        required: filterRequired
    }));

    return filter;
} 