import { Col, Row, Tabs, Typography } from 'antd';
import {
    capitalize, find, get, isEmpty, isUndefined, map,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QuickSearchComponent, {
    qsFilterNames,
} from '../../components/common/QuickSearchComponent';
import {
    CUSTOM_FIELD_TYPES,
} from '../../config/tableAndPageConstants';
import { ApplicationState } from '../../store';
import { getCustomFieldsFilterListByTypesForOrganisation } from '../../store/common/actions';
import {
    getCustomerUILabel,
} from '../../store/customers/sagas';
import {
    CustomersState,
} from '../../store/customers/types';
import OrganisationCustomersContent from '../../components/sales/OrganisationCustomersContent';
import { Organisation, OrganisationActionTab, OrganisationsState } from '../../store/organisations/types';
import { getCurrentUser } from '../../store/users/sagas';
import { IsOrganisationViewAttribute, OrganisationIdAttribute } from '../../constants/authUserAttributes';
import { computeOrganisationTabs, getSelectedCurrencyRate } from '../../utils/commonFunctions';
import { getTranslatedText } from '../../utils/commonFunctions';
import { clearCustomersStateAllTableFiltersAction } from '../../store/customers/actions';
import { setSelectedTabAction } from '../../store/organisations/actions';


const { Title } = Typography;
const { TabPane } = Tabs;

interface IProps {
}
const OrganisationCustomersManagementPage: React.FC<IProps> = ({ }: IProps) => {
    
    const dispatch = useDispatch();
    const customerLabel = useSelector(getCustomerUILabel);
    const customersState: CustomersState = useSelector(
        (state: ApplicationState) => state.customers
    );
    const selectedUserOrganisation: Organisation = useSelector(
        (state: ApplicationState) => state.organisations.selectedUserOrganisation
    );
    const currentUser = useSelector(getCurrentUser);
    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
    const userCompanies: OrganisationsState['userCompanies']['data'] = useSelector(
        (state: ApplicationState) => state.organisations.userCompanies.data
    );
    const [selectedTab, setSelectedTab] = useState<string>();
    const [customFieldsFilterList, setCustomFieldsFilterList] = useState<[]>([]);
    const tabs = useMemo(() => computeOrganisationTabs(userCompanies), [userCompanies]);
    const isValidSelectedUserOrganisation = !isUndefined(selectedUserOrganisation) && !isEmpty(selectedUserOrganisation);
    /**
     * Function that fetches the custom fields filter list
     */
    const getCustomFieldsFilterList = (companyIds: string[]) => {
        const organisationIdCognito = get(currentUser, OrganisationIdAttribute);
        const selectedOrgId = get(selectedUserOrganisation, 'OrganisationId');

        if (organisationIdCognito === selectedOrgId) {
            dispatch(
                getCustomFieldsFilterListByTypesForOrganisation(
                    companyIds,
                    [CUSTOM_FIELD_TYPES.CUSTOMER],
                    (response: any) => {
                        if (response.IsSuccess) {
                            setCustomFieldsFilterList(response.CustomFields);
                        }
                    }
                )
            );
        }
    };

    useEffect(() => {
        if (!tabs || isEmpty(tabs)) return;
        setSelectedTab(tabs[0].tabName);
        dispatch(setSelectedTabAction(tabs[0]));
        getCustomFieldsFilterList(tabs[0].companies.map(c => c.CompanyId));
    }, [tabs]);

    const activeTab = find(
        tabs,
        (tab: OrganisationActionTab) => tab.tabName === selectedTab
    );

    let companyIds: string[] = [];
    if (!isUndefined(activeTab)) {
        companyIds = activeTab.companies.map((company) => company.CompanyId);
    }
    const currencyCode: string = useSelector(
        (state: ApplicationState) => state.app.currencyCode
    );

    const conversionCurrency = getSelectedCurrencyRate(
        selectedUserOrganisation,
        currencyCode
    );

    const functionRefObj = useMemo<{
        applyFilters: any
    }>(() => ({
        applyFilters: undefined
    }), []);

    /**
     * Common function for applying the quick search filters
     * @param filters
     * @param fromFilterBar
     */
    const applyQuickSearchFilters = (
        filters?: any,
        fromFilterBar?: boolean
    ) => {
        functionRefObj.applyFilters(filters, fromFilterBar, true);
    };

    /**
     * Common function for populating the quick search common filter section
     */
    const populateQSCommonFilterOptions = () => {
        const filterOptions = [
            {
                label: capitalize(customerLabel),
                value: qsFilterNames.CUSTOMER,
            },
        ];

        return filterOptions;
    };

    const handleTabChange = (value: string) => {
        setSelectedTab(value);
        if (tabs) {
            const tab = tabs.find(x => x.tabName == value)
            if (tab) {
                dispatch(setSelectedTabAction(tab));
                dispatch(clearCustomersStateAllTableFiltersAction());
                getCustomFieldsFilterList(tab.companies.map(c => c.CompanyId));
            }
        }
    };

    const populateContent = () => {
        if (tabs && tabs.length === 1) {
            return <OrganisationCustomersContent
                customFieldsFilterList={customFieldsFilterList}
                functionRefObj={functionRefObj}
                region={tabs[0].region}
                companies={tabs[0].companies}
                usingCustomerWorkflow={tabs[0].usingCustomerWorkflow}
                conversationRate={conversionCurrency}
                currencies={get(selectedUserOrganisation, 'Currencies')}
            />
        }

        return <Tabs
            activeKey={selectedTab}
            onChange={handleTabChange}
        >
            {map(tabs, (tab) => (
                <TabPane
                    tab={getTranslatedText(tab.tabName)}
                    key={tab.tabName}
                >
                    {selectedTab === tab.tabName && <OrganisationCustomersContent
                        customFieldsFilterList={customFieldsFilterList}
                        functionRefObj={functionRefObj}
                        region={tab.region}
                        companies={tab.companies}
                        usingCustomerWorkflow={tab.usingCustomerWorkflow}
                        conversationRate={conversionCurrency}
                        currencies={get(selectedUserOrganisation, 'Currencies')}
                    />}
                </TabPane>
            )
            )}
        </Tabs>
    };

    return (
        <QueueAnim type={['right', 'left']} leaveReverse>
            <Row key="title-container" type="flex" justify="space-between">
                <Col>
                    <Title level={3}>{getTranslatedText(capitalize(customerLabel))}</Title>
                </Col>
                <Col className="ta-right">
                    <QuickSearchComponent
                        loading={customersState.loading}
                        customFieldsFilterList={customFieldsFilterList}
                        applyFilters={applyQuickSearchFilters}
                        filterOptions={populateQSCommonFilterOptions().map(option => ({
                            ...option,
                            label: getTranslatedText(option.label)
                        }))}
                        selectedUserCompany={undefined}
                        companyIds={companyIds}
                        conversionCurrency={conversionCurrency}
                        isOrgView={isOrgView}
                    />
                </Col>
            </Row>
            <div className="spacer-5" />
            {
                isValidSelectedUserOrganisation && populateContent()
            }
        </QueueAnim>
    );
};

export default OrganisationCustomersManagementPage;
