/**
 * File responsible for all the UI and actions for Data Recovery page - `/app/data-recovery`.
 */

 import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Typography,
    DatePicker
} from 'antd';
import {
    get,
    isEmpty,
    map
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalWithSpinner from '../../components/common/ModalWithSpinner';
import {
    confirmModalCancelText,
    confirmModalOkText
} from '../../config/config';
import { ApplicationState } from '../../store';
import {
    recoverDataRequestAction,
    getBackupPerHourAction
} from '../../store/dataRecovery/actions';
import { CompaniesState } from '../../store/companies/types';
import moment from 'moment-timezone';
import { DynamicObject } from '../../utils/commonInterfaces';
import './settings.less';
import { dateFormatYYYYMMDDTHHmmssSSSSSSDash, dateFormatYYYYMMDDDash } from '../../constants/dateFormats';

const { Title } = Typography;
const { confirm } = Modal;
const { Item: FormItem } = Form;
const { TextArea } = Input;
const { Option } = Select;
interface IProps {
    form: DynamicObject;
    history: {
        push: (path: string) => void;
    };
}

export const defaultCardPaymentClientRef = '[InvoiceList]';

const DataRecoveryManagementPage: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const companiesState: CompaniesState = useSelector(
        (state: ApplicationState) => state.companies
    );

    const [backupHours, setBackupHours] = useState<{ label: string; value: string }[]>(
        []
      );
      const [isLoading, setIsLoading] = useState<boolean>()
      const [isCheckedAUserAgreement, setIsCheckedAUserAgreement] = useState<boolean>()
      const [selectedBackupDate, setSelectedBackUpDate] = useState<string | null>(null);
      const [submitLoading,setSubmitLoading] = useState<boolean>();
    const { selectedUserCompany } = companiesState;

    const {
        getFieldDecorator,
        validateFields,
        resetFields,
    } = props.form;
    
    /**
     * Function called when `Cancel` button is clicked inside Data recovery page.
     * Pops up a confirmation modal asking to revert all changes made.
     */
    const onCancelButtonClick = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: 'Continue?',
            content: (
                <div>
                    When you click the <b>{confirmModalOkText}</b> button, all
                    the data will be reverted to the last saved values.
                </div>
            ),
            onOk() {
                resetForm(); 
            },
            onCancel() { },
            okText: confirmModalOkText,
            cancelText: confirmModalCancelText,
        });
    };

    /**
     * Function called when `Restoration` button is clicked.
     * Pops up a confirmation modal asking to restore all the data needed by the user.
     */
    const onRestorationButtonClick = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: 'Continue?',
            content: (
                <div>
                    Please confirm that a full company report has been generated before starting the restoration process. Do you confirm that the full company report has been generated and that you wish to proceed with the restoration process? 
                </div>
            ),
            onOk() {
                restorationSubmit();
            },
            onCancel() { },
            okText: confirmModalOkText,
            cancelText: confirmModalCancelText,
        });
    };

    const convertToDesiredFormat = (input: string): string => {
        const formattedString = moment(input, 'DD-MM-YYYY-HH').format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
        return formattedString;
      };

    /**
     * Function called when `Ok` button is clicked in onRestorationButton modal.
     */
    const restorationSubmit = () => {
        validateFields((err: DynamicObject, values: DynamicObject) => {
            if (!err) {
                setSubmitLoading(true);

                const restorationPayload: {
                    RestorationDateTime : string,
                    Reason : string,
                    IsRollback : boolean,
                } = {
                    RestorationDateTime : moment(get(values,'RestorationDateTime')).format(dateFormatYYYYMMDDTHHmmssSSSSSSDash),
                    Reason: get(values,'ReasonForRecovery'),
                    IsRollback: false
                }

                dispatch(
                    recoverDataRequestAction(
                        restorationPayload,
                        ({ IsSuccess }: { IsSuccess: boolean }) =>
                            handleModalSaveResponse(
                                IsSuccess
                            )
                    )
                );
            }
        });
    };
    
    /**
     * Function responsible for showing the success/error modal after saving the changes made.
     * @param IsSuccess - if the saving of data is successful
     * @param lastSavedPayload - latest saved values
     */
    const handleModalSaveResponse = (
        IsSuccess: boolean
    ) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Restoration started successfully!'
            });
            resetForm();
            
        } else {
            Modal.error({
                title: 'Error',
                content: `Failed to start restoration process.`,
            });
        }
    };

    /**
     * Function triggered when checkbox is toggled.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();

        setIsCheckedAUserAgreement(!isCheckedAUserAgreement);
    };

    const resetForm = () => {
        resetFields();
        setBackupHours([]);
        setSelectedBackUpDate(null);
        setIsCheckedAUserAgreement(false);
    }

    const fetchBackupHours = (date: any) => {
        setIsLoading(true);
        if(date){
            const payload: {
                TimeRange : string
            } = {
                TimeRange : moment(date).format(dateFormatYYYYMMDDTHHmmssSSSSSSDash),
            }

            dispatch(
                getBackupPerHourAction(
                    payload,
                    (response: any) => {
                        if (response.Messages.length > 0 && !isEmpty(response.Messages) ) {
                            const backUpsArray: { label: string; value: string }[] = [];
    
                            response.Messages.forEach(
                                (backup: any) => {
                                    backUpsArray.push({
                                        label: convertDateTimeStringToTime(backup),
                                        value: convertToDesiredFormat(backup)
                                    });
                                }
                            );
    
                            setBackupHours(backUpsArray)
                        }
                        setIsLoading(false);
                    }
                ))
        } else {
            setBackupHours([]);
            setIsLoading(false);
        }
        
    };

    const convertDateTimeStringToTime = (dateTimeStr: string): string => {
        const parts = dateTimeStr.split("-"); // Split the string by '-'
        const hour = parseInt(parts[3], 10); // Get the hour part
      
        if (isNaN(hour) || hour < 0 || hour > 23) return "Invalid time"; // Validate hour
      
        const formattedHour = hour % 12 || 12; // Convert to 12-hour format
        const period = hour < 12 ? "AM" : "PM"; // Determine AM/PM
      
        return `${formattedHour}:00 ${period}`;
    };

    const handleSelectedHours = (dateTimeStr: string) => {
        setSelectedBackUpDate(dateTimeStr);
    };

    useEffect(resetForm, [selectedUserCompany]);

    /**
     * Function that populates reason to recover section.
     */
    const populateReasonToRecoverSection = () => {
        return (
            <>
                <Row>
                    <Col className="start-flex-i">
                        <div className="mt-9 pr-8 ant-form-item-required">
                            State the reason for recovering data: &nbsp;&nbsp;
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="start-flex-i">
                        <FormItem className="f-ta-100">
                            {getFieldDecorator('ReasonForRecovery', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Reason for recovering data is required!',
                                    },
                                ],
                            })(
                                <TextArea rows={4} />
                            )}
                        </FormItem>
                    </Col>
                </Row>
            </>

        );
    };

    /**
     * Function that populates dates to recover section.
     */
    const populateDatesToRecoverSection = () => {
        return (
            <>
                <Row gutter={5}>
                    <Col span={10}>
                        <div className="mt-9 ant-form-item-required">
                            Date to be recovered: &nbsp;&nbsp;
                        </div>
                    </Col>
                    <Col span={10}>
                        <div className="mt-9 ant-form-item-required">
                            Time to be recovered: &nbsp;&nbsp;
                        </div>
                    </Col>
                </Row>

                <Row gutter={5}>
                    <Col span={10}>
                        <FormItem>
                            {getFieldDecorator('BackupDate', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Date of data to be recovered is required!',
                                    },
                                ],
                            })(
                                <DatePicker 
                                    format={dateFormatYYYYMMDDDash}
                                    onChange={fetchBackupHours}
                                />
                            )}
                        </FormItem>
                    </Col>
                    <Col span={10}>
                        <FormItem style={{ width: "100%" }}>
                            {getFieldDecorator('RestorationDateTime', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Date of data to be recovered is required!',
                                    },
                                ],
                            })(
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Select Time"
                                    loading={isLoading}
                                    disabled={isLoading}
                                    onChange={handleSelectedHours}
                                >
                                    {
                                        backupHours ? 
                                        map(
                                            backupHours,
                                            ({
                                                label,
                                                value,
                                            }: {
                                                label: string;
                                                value: string;
                                            }) => (
                                                <Option key={value} value={value}>
                                                    {label}
                                                </Option>
                                            )
                                        ) : 
                                        <Option>
                                            No backup found
                                        </Option>
                                }
                                </Select>
                            )}
                            
                        </FormItem>
                    </Col>
                </Row>
            </>

        );
    };

    /**
     * Function that populates acknowledgement section.
     */
    const populateAcknowledgementSection = () => {
        return (
            <>
                <Row>
                    <Col className="start-flex-i">
                        <FormItem >
                            {getFieldDecorator('UserAgreement', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please check the user agreement to proceed!',
                                    },
                                ],
                            })(
                                <Checkbox
                                    checked={isCheckedAUserAgreement}
                                    onClick={handleCheckboxClick}
                                >By completing this restoration task I acknowledge that any tasks performed on the platform which have been completed beyond the selected restore point will result in the loss of data for all users.</Checkbox>
                            )} 
                        </FormItem>
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <div className="h-100">
            <Col span={24}>
                <Form
                    className="form-inline-mb-0"
                    labelCol={{
                        xxl: { span: 'auto' },
                        xl: { span: 7 },
                        lg: { span: 8 },
                        md: { span: 6 },
                        sm: { span: 6 },
                        xs: { span: 6 },
                    }}
                    wrapperCol={{
                        xxl: { span: 16 },
                        xl: { span: 17 },
                        lg: { span: 16 },
                        md: { span: 18 },
                        sm: { span: 18 },
                        xs: { span: 18 },
                    }}
                >
                    <QueueAnim type={['right', 'left']} leaveReverse>
                        <Row key="title-container" type="flex" align="middle">
                            <Col span={12}>
                                <Row>
                                    <Col>
                                        <Title level={3}>Data Recovery</Title>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={12} className="ta-right">
                                <Button
                                    className="mr-10 w-150px"
                                    type="primary"
                                    onClick={onRestorationButtonClick}
                                    disabled={selectedBackupDate == null || !isCheckedAUserAgreement}
                                    loading={submitLoading}
                                >
                                    Initialize Restoration
                                </Button>
                                <Button
                                    className="buttonGrey w-100px"
                                    onClick={onCancelButtonClick}
                                    disabled={isLoading}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <h4>Reason to restore</h4>
                            </Col>
                            <Col
                                xxl={10}
                                xl={15}
                                lg={18}
                                md={18}
                                sm={18}
                                xs={18}
                            >
                                {populateReasonToRecoverSection()}
                            </Col>
                        </Row>

                        <Divider />
                        <Row>
                            <Col span={24}>
                                <h4>Date to restore</h4>
                            </Col>
                            <Col
                                xxl={10}
                                xl={15}
                                lg={18}
                                md={18}
                                sm={18}
                                xs={18}
                            >
                                {populateDatesToRecoverSection()}
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <h4>User Agreement</h4>
                            </Col>
                            <Col
                                xxl={18}
                                xl={15}
                                lg={18}
                                md={18}
                                sm={18}
                                xs={18}
                            >
                                {populateAcknowledgementSection()}
                            </Col>
                        </Row>
                    </QueueAnim>
                </Form>
                {submitLoading && (
                    <ModalWithSpinner
                        modalTitle="Starting the restoration"
                        modalVisible={submitLoading}
                        displayMessage="Please wait while starting the restoration. . ."
                    />
                )}
            </Col>
        </div>
    );
};

const CustomerPaymentsManagementPageForm = Form.create({
    name: 'customer-payments-form',
})(DataRecoveryManagementPage);
export default withRouter(CustomerPaymentsManagementPageForm);
