/**
 * File responsible for the Filter bar section.
 */

import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Icon,
    Input,
    InputNumber,
    Modal,
    Popover,
    Row,
    Select,
    Spin,
    Tag,
    Tree,
} from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import {
    capitalize,
    filter,
    find,
    findIndex,
    forEach,
    get,
    has,
    head,
    includes,
    intersection,
    isEmpty,
    isEqual,
    isObject,
    isString,
    isUndefined,
    keys,
    map,
    omitBy,
    upperFirst,
    values,
} from 'lodash';
import moment from 'moment-timezone';
import QueueAnim from 'rc-queue-anim';
import React, {
    createRef,
    lazy,
    memo,
    Suspense,
    useEffect,
    useRef,
    useState,
} from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import { TIME_DELAY_LISTENER_FILTER_UPDATES } from '../../config/config';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import {
    companyLocaleDependentFilterNames,
    companyLocaleDependentFilters,
    dateTypeFilterStateNames,
    selectActionedByUserIdFilterMapping,
    selectActionedByUserIdFilterStateNames,
    selectCompanyIdFilterMapping,
    selectCompanyIdFilterStateNames,
    selectUserFilterMapping,
    selectUserTypeFilterStateNames,
} from '../../constants/common';
import {
    dateFormatDDMMYYYYSlash,
    dateFormatYYYYMMDDDash,
} from '../../constants/dateFormats';
import { dateSelectOptions } from '../../constants/invoicesSortAndFilters';
import { ApplicationState } from '../../store';
import {
    saveAppliedFiltersViewAction,
    updatePageViewsSelectedAction,
    updateRefetchPageViewsAction,
} from '../../store/common/actions';
import { CompanyCustomFieldConfigure } from '../../store/companies/types';
import { getCustomerUILabel } from '../../store/customers/sagas';
import {
    checkIfEmailIsValid,
    checkIsValidJsonString,
    formatPercentage,
    getPopoverContainer,
    getTranslatedText
} from '../../utils/commonFunctions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import { withDateFormatHandler } from './DateFormatHandler';
import FontAwesome from './FontAwesome';
import InputAutoCompleteWithButton from './InputAutoCompleteWithButton';
import InputSelectUserSearchWithButton from './InputSelectUserSearchWithButton';
import { withNumberFormatHandler } from './NumberFormatHandler';
import ProtectedPopoverButton from './ProtectedPopoverButton';

const FilterRequiredModal = lazy(
    () => import('../../components/common/FilterRequiredModal')
);

const SaveViewModal = lazy(
    () => import('../../components/common/SaveViewModal')
);

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

const { Option } = Select;
const { TreeNode } = Tree;

interface IProps {
    readonly pageName?: string;
    readonly containerRef?: any;
    readonly loading: boolean;
    readonly applyFilters: (
        filters?: DynamicObject,
        fromFilterBar?: boolean
    ) => void;
    readonly filters: unknown[];
    readonly filterValues?: {};
    readonly closeFilterBar: () => void;
    readonly colDivision: number;
    readonly formatDate?: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly closeAllPopovers?: boolean;
    readonly appliedView?: string;
    readonly doesViewNameExist?: (name: string) => boolean;
    readonly customFieldsFilters?: CompanyCustomFieldConfigure[];
    readonly formatToParts: (amount: number) => string;
    readonly formatCurrency: (amount: number) => JSX.Element;
    readonly checkingForAssignedUser?: (
        inputValue: string | null | undefined
    ) => boolean;
    readonly tableSortState?: DynamicObject;
    readonly companyIds?: string [] | undefined;
    readonly isOrgView?: boolean | undefined;
}

export const customFieldIndicator = 'CustomField---';
export const customFieldMultipleValuesIndicator = 'CustomFieldMultipleValuesFilters---';
export const appliedFilterIndicator = '---Applied';
export const getMinMaxIndicator = '---GetMinMax';
export const treeParentChildSeparator = '-@-';
export const customerFieldIndicator = 'CustomerField---';
export const invoiceFieldIndicator = 'InvoiceField---';
export const creditFieldIndicator = 'CreditField---';
export const ticketFieldIndicator = 'TicketField---';
export const taskFieldIndicator = 'TaskField---';
export const communicationDeliveryFieldIndicator = 'CommunicationDeliveryField---';
export const paymentFieldIndicator = 'PaymentField---';

let filterTimeoutHandler: any = null;
let hasNewAppliedFilter: boolean = false;
let lastAppliedView: string | undefined = undefined;
let lastSelectedCompanyId: string | null = null;
let wasDependentFilterSet: boolean = false;

const FilterBar: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();

    const selectedCompanyId: string | null = useSelector(
        (state: ApplicationState) =>
            get(state.companies.selectedUserCompany, 'Company.CompanyId') ||
            null
    );

    const pageFilterState: any = useSelector(
        (state: ApplicationState) =>
            get(state.app.pageViewsSelected, `${props.pageName}`) || null
    );

    const customerLabel = useSelector(getCustomerUILabel);
    const okButtonRef: any = useRef(props.filters.map(() => createRef()));

    const filterBarContainerRef = props.containerRef;

    const [newlyAddedFilters, setNewlyAddedFilters] = useState<DynamicObject>(
        {}
    );

    const [saveViewBtnDisabled, setSaveViewBtnDisabled] = useState<boolean>(
        isEmpty(props.appliedView)
    );

    const [showConditions, setShowConditions] = useState<DynamicObject>({});

    const [searchFilters, setSearchFilters] = useState<DynamicObject>({});

    const [modalFilterRequired, setModalFilterRequired] = useState<string>('');

    const [saveModalConditions, setSaveModalConditions] = useState<{
        showModal: boolean;
        showSubmitLoading: boolean;
    }>({
        showModal: false,
        showSubmitLoading: false,
    });

    const [saveModalFormInitialValues, setSaveModalFormInitialValues] =
        useState<DynamicObject>({});

    /**
     * Function for initializing the filter values based on the
     * filter values saved in props.
     */
    const initializePropsFilters = () => {
        const filterListShowConditions: any = {};
        const searchFilterList: any = {};

        forEach(props.filters, ({ filterName, filterStateName }: any) => {
            const stateName = filterStateName || filterName;
            filterListShowConditions[stateName] = false;
            const propsFilterValue = get(props.filterValues, stateName);

            searchFilterList[stateName] = propsFilterValue;

            const appliedValue = get(
                props.filterValues,
                `${stateName}${appliedFilterIndicator}`
            );

            if (appliedValue) {
                searchFilterList[`${stateName}${appliedFilterIndicator}`] =
                    appliedValue;
                filterListShowConditions[`${stateName}FilterTags`] = true;
            } else {
                if (
                    typeof propsFilterValue === 'object' &&
                    React.isValidElement(propsFilterValue)
                ) {
                    searchFilterList[`${stateName}${appliedFilterIndicator}`] =
                        propsFilterValue;
                    filterListShowConditions[`${stateName}FilterTags`] =
                        !isEmpty(propsFilterValue);
                }
            }
        });
        forEach(
            props.customFieldsFilters,
            ({ Type, FieldName }: CompanyCustomFieldConfigure) => {
                const stateName = `${customFieldIndicator}${Type}--${FieldName}`;
                filterListShowConditions[stateName] = false;
                const propsFilterValue = get(props.filterValues, stateName);

                searchFilterList[stateName] = propsFilterValue;

                const appliedValue = get(
                    props.filterValues,
                    `${stateName}${appliedFilterIndicator}`
                );

                if (appliedValue) {
                    searchFilterList[`${stateName}${appliedFilterIndicator}`] =
                        appliedValue;
                    filterListShowConditions[`${stateName}FilterTags`] = true;
                } else {
                    if (
                        typeof propsFilterValue === 'object' &&
                        React.isValidElement(propsFilterValue)
                    ) {
                        searchFilterList[
                            `${stateName}${appliedFilterIndicator}`
                        ] = propsFilterValue;
                        filterListShowConditions[`${stateName}FilterTags`] =
                            !isEmpty(propsFilterValue);
                    }
                }
            }
        );

        setShowConditions({
            ...filterListShowConditions,
        });
        setSearchFilters({
            ...searchFilterList,
        });
    };

    useEffect(initializePropsFilters, [props.filterValues]);

    const listenForSaveViewStatus = () => {
        let btnDisabled = true;

        if (
            isEmpty(props.appliedView) ||
            (pageFilterState &&
                props.tableSortState &&
                !isEqual(
                    get(pageFilterState, 'tableSortState'),
                    props.tableSortState
                ))
        ) {
            btnDisabled = false;
        }

        if (btnDisabled !== saveViewBtnDisabled) {
            setSaveViewBtnDisabled(btnDisabled);
        }
    };

    useEffect(listenForSaveViewStatus, [
        props.appliedView,
        props.tableSortState,
    ]);

    const processAppliedFiltersBasedOnCompany = () => {
        if (!lastSelectedCompanyId) {
            lastSelectedCompanyId = selectedCompanyId;
            return;
        }
        const filterKeys = map(props.filters, (f: any) =>
            get(f, 'filterStateName')
        );

        const notSameCompany =
            lastSelectedCompanyId &&
            lastSelectedCompanyId !== selectedCompanyId;

        if (notSameCompany) {
            wasDependentFilterSet = false;
            lastSelectedCompanyId = selectedCompanyId;
            return;
        }

        if (
            lastSelectedCompanyId === selectedCompanyId &&
            !wasDependentFilterSet
        ) {
            const filterKeysApplied = keys(omitBy(searchFilters, isEmpty));
            const sameKeys = intersection(
                filterKeysApplied,
                companyLocaleDependentFilters
            );

            const availableKeys = intersection(filterKeys, sameKeys);

            if (availableKeys && !isEmpty(availableKeys)) {
                wasDependentFilterSet = true;
                forEach(sameKeys, (keyValue: string) => {
                    let filterTypeUsed = '';
                    if (keyValue === companyLocaleDependentFilterNames.AMOUNT) {
                        filterTypeUsed = 'amount-filter';
                    }
                    if (
                        !isEmpty(
                            filter(props.filters, ['filterStateName', keyValue])
                        )
                    ) {
                        updateFiltersSelect(
                            filterTypeUsed,
                            keyValue,
                            get(searchFilters, keyValue)
                        );
                    }
                });
            }
        }
    };

    useEffect(processAppliedFiltersBasedOnCompany, [
        selectedCompanyId,
        props.filters,
    ]);

    /**
     * On unmount
     */
    useEffect(() => {
        return () => {
            hasNewAppliedFilter = false;
            lastAppliedView = undefined;
            lastSelectedCompanyId = null;
            wasDependentFilterSet = false;
        };
    }, []);

    /**
     * Function that controls wether the filter popover will be hidden or shown.
     * @param visible - boolean indicator
     * @param filterName - name of the filter item
     */
    const changeConditionVisibility = (
        visible: boolean,
        filterName: string
    ) => {
        setShowConditions({
            ...showConditions,
            [filterName]: visible,
        });
    };

    /**
     * Function that sets the filter values.
     * @param filterName - name of filter item
     */
    const updateModalFilterRequired = (filterName: string) => {
        setModalFilterRequired(filterName);
    };

    /**
     * Function that updates the search input filters.
     * @param filterName - name of filter item
     * @param value - string value entered
     */
    const changeSearchFilter = (filterName: string, value: any) => {
        updateSearchFiltersObject({
            [filterName]: value,
        });
    };

    /**
     * Common function for updating the serachFilters object from state.
     * @param searchFiltersObject
     */
    const updateSearchFiltersObject = (searchFiltersObject: DynamicObject) => {
        setSearchFilters({
            ...searchFilters,
            ...searchFiltersObject,
        });
    };

    /**
     * Common function for updating the showConditions object from state.
     * @param showConditionsObject
     */
    const updateShowConditionsObject = (
        showConditionsObject: DynamicObject
    ) => {
        setShowConditions({
            ...showConditions,
            ...showConditionsObject,
        });
    };

    /**
     * Common function for updating the saveModalConditions object from state.
     * @param saveModalConditionsObject
     */
    const updateSaveModalConditionsObject = (
        saveModalConditionsObject: DynamicObject
    ) => {
        setSaveModalConditions({
            ...saveModalConditions,
            ...saveModalConditionsObject,
        });
    };

    /**
     * Function that updates the filter
     * sets a prop in redux with suffix --Applied
     * as indicator for the original value saved from action.
     * @param filterName - name of filter
     * @param defaultValue - value to be set (optional) - if not given, the one in state will
     * be used
     */
    const updateFilters = (filterName: string, defaultValue?: any) => {
        hasNewAppliedFilter = true;
        const applyFiltersShowStatus = checkShowApplyFiltersButtonGroup();

        let conditionForReduxCheck = false;
        const reduxFilterStateValue = get(
            props.filterValues,
            `${filterName}${appliedFilterIndicator}`
        );
        if (reduxFilterStateValue) {
            conditionForReduxCheck = !isEmpty(
                get(searchFilters, `${filterName}${appliedFilterIndicator}`)
            );
        }
        updateShowConditionsObject({
            [filterName]: false,
            [`${filterName}FilterTags`]:
                !isEmpty(get(searchFilters, filterName, defaultValue)) ||
                conditionForReduxCheck,
            applyFiltersButtonGroup: applyFiltersShowStatus,
        });

        setSearchFilters((prevState: DynamicObject) => {
            let usedFilterValue = get(prevState, filterName, defaultValue);
            if (
                includes(selectUserTypeFilterStateNames, filterName) ||
                includes(selectActionedByUserIdFilterStateNames, filterName) ||
                includes(selectCompanyIdFilterStateNames, filterName)
            ) {
                usedFilterValue = !isUndefined(defaultValue)
                    ? defaultValue
                    : get(prevState, filterName);
            }
            return {
                ...prevState,
                [filterName]: usedFilterValue,
                [`${filterName}${appliedFilterIndicator}`]: usedFilterValue,
            };
        });
    };

    /**
     * Function that gets the filter string prefix.
     * To populate the string when a filter is set.
     * For e.g. (Read now) - Ready is the prefix for the ActionDate filter.
     * @param filterName
     */
    const getFilterStringPrefix = (filterName: string) => {
        let prefix = '';
        if (filterName === 'ActionDate') {
            const filterIndex = findIndex(props.filters, [
                'filterStateName',
                filterName,
            ]);
            prefix = 'Ready';

            if (filterIndex > -1) {
                prefix = get(
                    props.filters,
                    `${filterIndex}.filterPrefixString`,
                    ''
                );
            }
        }

        return prefix;
    };

    /**
     * Function that finalizes the filter string, corrects the filter string case
     * and adds prefix if the certain filter has it.
     * @param filterName - name of filter
     * @param filterString - value of the selected/entered filter
     */
    const finalizeFilterString = (filterName: string, filterString: string) => {
        const prefix = getFilterStringPrefix(filterName);

        if (!isEmpty(prefix)) {
            filterString = prefix + ' ' + filterString.toLowerCase();
        }

        return filterString;
    };

    /**
     * Function for updating the `Select` filters.
     * @param customType - the type of filter
     * @param filterName - name of filter
     * @param defaultValue - default value to be set in redux;
     * if not given, the value from state will be used
     */
    const updateFiltersSelect = (
        customType: string,
        filterName: string,
        defaultValue?: any
    ) => {
        hasNewAppliedFilter = true;
        let showFilterTag = !isEmpty(
            get(searchFilters, filterName, defaultValue)
        );
        if (filterName === 'Contact') {
            if (get(searchFilters, filterName) === 0) {
                showFilterTag = !isEmpty(get(defaultValue, filterName));
            }
        }

        updateShowConditionsObject({
            [filterName]: false,
            [`${filterName}FilterTags`]: showFilterTag,
            applyFiltersButtonGroup: checkShowApplyFiltersButtonGroup(),
        });

        let filterText: any;
        if (customType === 'date-range') {
            let formattedFromDate: string = '';
            let formattedToDate: string = '';
            if (props.formatDate) {
                formattedFromDate = props.formatDate(
                    get(
                        searchFilters,
                        `${filterName}.From`,
                        get(defaultValue, 'From', null)
                    ),
                    null,
                    dateFormatDDMMYYYYSlash
                );

                formattedToDate = props.formatDate(
                    get(
                        searchFilters,
                        `${filterName}.To`,
                        get(defaultValue, 'To', null)
                    ),
                    null,
                    dateFormatDDMMYYYYSlash
                );
            }

            let filterString = '';
            const isFromDateValid = formattedFromDate !== 'Invalid date';
            const isToDateValid = formattedToDate !== 'Invalid date';

            if (isFromDateValid && isToDateValid) {
                filterString = `Between ${formattedFromDate} and ${formattedToDate}`;
            } else if (isFromDateValid && !isToDateValid) {
                filterString = `From ${formattedFromDate}`;
            } else if (!isFromDateValid && isToDateValid) {
                filterString = `Until ${formattedToDate}`;
            }
            filterString = finalizeFilterString(filterName, filterString);

            filterText = (
                <span>
                    <b>{filterString}</b>
                </span>
            );
        } else if (customType === 'last-days-range') {
            const daysValue = searchFilters[filterName].Last;
            filterText = (
                <b>
                    Last {daysValue} day
                    {daysValue > 1 ? 's' : ''}
                </b>
            );
        } else if (customType === 'all-days-range') {
            const rangeType = has(searchFilters, `${filterName}.Last`)
                ? "Last"
                : "Next";
            const daysValue = searchFilters[filterName][rangeType];
            filterText = (
                <b>{`${rangeType} ${daysValue} day${daysValue > 1 ? 's' : ''
                    }`}</b>
            );
        } else if (customType === 'all-days-range-from-to') {
            let filterDaysRangeText = '';
            forEach(['From', 'To'], (daysType: 'From' | 'To') => {
                const rangeType = has(
                    searchFilters,
                    `${filterName}.${daysType}.Last`
                )
                    ? "Last"
                    : "Next";
                const daysValue =
                    searchFilters[filterName][daysType][rangeType];
                if (!isEmpty(filterDaysRangeText)) {
                    filterDaysRangeText += ' - ';
                }

                filterDaysRangeText += `${rangeType} ${daysValue} day${daysValue > 1 ? 's' : ''
                    }`;
            });

            let filterString = filterDaysRangeText;
            filterString = finalizeFilterString(filterName, filterString);

            filterText = <b>{filterString}</b>;
        } else if (customType === 'all-days-range-from-to-last') {
            let filterDaysRangeText = '';
            forEach(['From', 'To'], (daysType: 'From' | 'To') => {
                const rangeType = 'Last';
                const daysValue =
                    searchFilters[filterName][daysType][rangeType];
                if (!isEmpty(filterDaysRangeText)) {
                    filterDaysRangeText += ' - ';
                }

                filterDaysRangeText += `${rangeType} ${daysValue} day${daysValue > 1 ? 's' : ''
                    }`;
            });

            let filterString = filterDaysRangeText;
            filterString = finalizeFilterString(filterName, filterString);

            filterText = <b>{filterString}</b>;
        } else if (customType === 'amount-filter') {
            const filterValues = defaultValue || get(searchFilters, filterName);

            const filterObject = head(
                filter(props.filters, {
                    filterName,
                })
            );
            const filterAmountTypeOptions = get(
                filterObject,
                'filterOptions.0.AmountType'
            );
            const filterAmountOperatorOptions = get(
                filterObject,
                'filterOptions.1.AmountOperator'
            );

            const filterTypeLabel = get(
                filter(
                    filterAmountTypeOptions,
                    (option: any) =>
                        option.value === get(filterValues, 'AmountType')
                ),
                `${0}.label`
            );

            const filterOperatorLabel = get(
                filter(
                    filterAmountOperatorOptions,
                    (option: any) =>
                        option.value === get(filterValues, 'AmountOperator')
                ),
                `${0}.label`
            );

            const amountValue = get(filterValues, 'AmountValue');
            const amountValueDisplay = filterTypeLabel === 'Percentage' ? formatPercentage(amountValue) :
                (amountValue < 0
                    ? `-${props.formatCurrency(amountValue * -1)}`
                    : `${props.formatCurrency(amountValue)}`);

            filterText = (
                <b>
                    {capitalize(
                        `${filterTypeLabel} ${filterOperatorLabel} ${amountValueDisplay}`
                    )}
                </b>
            );
        } else if (customType === 'input-checkbox-group') {
            const filterObject = filter(
                props.filters,
                (filter: any) =>
                    filter.filterStateName === filterName ||
                    filter.filterName === filterName
            );

            const inputName = get(filterObject, '0.filterOptions.input.name');

            const checkboxName = get(
                filterObject,
                '0.filterOptions.checkboxGroup.name'
            );

            const checkboxOptions = get(
                filterObject,
                '0.filterOptions.checkboxGroup.options'
            );

            const checkboxLabels: any = {};
            forEach(checkboxOptions, ({ label, value }: any) => {
                checkboxLabels[value] = label;
            });

            const filterValues = get(searchFilters, filterName);

            const filterUserText = get(filterValues, inputName, '');
            let filterString = filterUserText
                ? `Contains ${filterUserText}`
                : '';

            forEach(get(filterValues, checkboxName), (checkedValue: string) => {
                if (!isEmpty(filterString)) {
                    filterString += ' & ';
                }
                filterString += checkboxLabels[checkedValue];
            });

            filterText = <b>{filterString}</b>;
        } else if (customType === 'select-checkbox-group') {
            const filterValues = get(searchFilters, filterName);
            const filterObject = filter(
                props.filters,
                (filter: any) => filter.filterName === filterName
            );

            const selectOptions = get(
                filterObject,
                '0.filterOptions.select.options'
            );

            const selectName = get(filterObject, '0.filterOptions.select.name');

            const selectLabels: any = {};

            forEach(selectOptions, ({ label, value }: any) => {
                selectLabels[value] = label;
            });

            const checkboxOptions = get(
                filterObject,
                '0.filterOptions.checkboxGroup.options'
            );

            const checkboxName = get(
                filterObject,
                '0.filterOptions.checkboxGroup.name'
            );

            const checkboxLabels: any = {};
            forEach(checkboxOptions, ({ label, value }: any) => {
                checkboxLabels[value] = label;
            });

            const filterUserText = get(
                filterValues,
                selectName,
                defaultValue[selectName]
            );
            let filterString = filterUserText
                ? `${selectLabels[filterUserText]}`
                : '';
            if (!isEmpty(filterString)) {
                filterString += ' ';
            }
            const checkedValues = get(
                filterValues,
                checkboxName,
                get(defaultValue, checkboxName)
            );

            forEach(checkedValues, (checkedValue: string, index: number) => {
                filterString += checkboxLabels[checkedValue];
                if (index !== checkedValues.length - 1) {
                    filterString += ', ';
                }
            });

            filterText = <b>{filterString}</b>;
        } else {
            let filterString = get(
                searchFilters[filterName],
                'value',
                defaultValue.value
            );

            filterString = finalizeFilterString(filterName, filterString);
            const filterStringFormatted = upperFirst(filterString);
            filterText = <b>{filterStringFormatted}</b>;
        }

        setSearchFilters((prevState: DynamicObject) => {
            return {
                ...prevState,
                [filterName]: defaultValue,
                [`${filterName}${appliedFilterIndicator}`]: filterText,
            };
        });
    };

    /**
     * Function that controls whether to hide/show the
     * `Apply filter` and `Cancel` section.
     */
    const checkShowApplyFiltersButtonGroup = () => {
        let showApplyFiltersButtonGroup = false;
        forEach(searchFilters, (filterValue: any, filterName: string) => {
            const reduxFilterValue = get(props.filterValues, filterName);
            if (
                includes(filterName, appliedFilterIndicator) &&
                reduxFilterValue !== undefined &&
                filterValue !== reduxFilterValue
            ) {
                showApplyFiltersButtonGroup = true;
            }
        });

        return showApplyFiltersButtonGroup;
    };

    /**
     * Function called when clicking on the close button for Tag on applied input filters.
     * @param filterName - name of filter
     * @param defaultValue - value of filter after closing the tag
     */
    const removeSearchFilter = (
        filterName: string,
        defaultValue: any = undefined
    ) => {
        hasNewAppliedFilter = true;

        setSearchFilters({
            ...searchFilters,
            [filterName]: defaultValue,
            [`${filterName}${appliedFilterIndicator}`]: defaultValue,
        });

        updateShowConditionsObject({
            [`${filterName}FilterTags`]: false,
            applyFiltersButtonGroup: true,
        });
    };

    /**
     * Function called when clicking on the close button for Tag on applied checkbox filters.
     * @param value - value of filter (usually id for checkbox or some other unique identifier)
     * @param filterName - name of filter
     * @param defaultValue - value to default to if initial value is not defined in state
     */
    const removeCheckboxFilter = (
        value: string,
        filterName: string,
        defaultValue?: any
    ) => {
        hasNewAppliedFilter = true;

        const checkboxFilters = searchFilters[filterName]
            ? [...searchFilters[filterName]]
            : [...defaultValue];

        const newCheckboxFilters = filter(
            checkboxFilters,
            (id: string) => id !== value
        );

        const filterValue = isEmpty(newCheckboxFilters)
            ? undefined
            : newCheckboxFilters;
        setSearchFilters({
            ...searchFilters,
            [filterName]: filterValue,
            [`${filterName}${appliedFilterIndicator}`]: filterValue,
        });

        updateShowConditionsObject({
            [`${filterName}FilterTags`]: !isEmpty(newCheckboxFilters),
            applyFiltersButtonGroup: true,
        });
    };

    /**
     * Function called when clicking on the close button for Tag on applied checkbox filters.
     * @param value - value of filter (usually id for checkbox or some other unique identifier)
     * @param filterName - name of filter
     * @param defaultValue - value to default to if initial value is not defined in state
     */
    const removeTreeCheckboxFilter = (
        value: string,
        filterName: string,
        defaultValue?: any
    ) => {
        hasNewAppliedFilter = true;

        const checkboxFilters = searchFilters[filterName]
            ? [...searchFilters[filterName]]
            : [...defaultValue];

        const cleanedCheckboxFilters = filter(
            checkboxFilters,
            (id: string) => id !== value
        );

        const newCheckboxFilters: string[] = [];
        forEach(cleanedCheckboxFilters, (ccf) => {
            if (includes(ccf, treeParentChildSeparator)) {
                newCheckboxFilters.push(ccf);
            }
        });

        const filterValue = isEmpty(newCheckboxFilters)
            ? undefined
            : newCheckboxFilters;
        setSearchFilters({
            ...searchFilters,
            [filterName]: filterValue,
            [`${filterName}${appliedFilterIndicator}`]: filterValue,
        });

        updateShowConditionsObject({
            [`${filterName}FilterTags`]: !isEmpty(newCheckboxFilters),
            applyFiltersButtonGroup: true,
        });
    };

    /**
     * Function that creates the popover for input filters.
     * @param filterStateName - name of filter to be stored in state
     * @param filterName - name of filter shown in UI
     */
    const createFilterPopoverTextWithTag = (
        filterStateName: string | undefined,
        filterName: string,
        filterType: string
    ) => {
        const stateName = filterStateName || filterName;
        const updateFiltersFunction = () => updateFilters(stateName);
        return (
            <>
                <ProtectedPopoverButton
                    containerRef={filterBarContainerRef}
                    popoverVisible={showConditions[stateName]}
                    popoverOnVisibleChange={(visible: boolean) => {
                        if (visible) {
                            changeConditionVisibility(visible, stateName);
                        }
                    }}
                    popoverPlacement="bottomLeft"
                    popoverContent={
                        <div className="pop-action-content">
                            <div>
                                <Input
                                    placeholder={getTranslatedText("Search")}
                                    onChange={(event: {
                                        target: {
                                            value: string;
                                        };
                                    }) =>
                                        changeSearchFilter(
                                            stateName,
                                            event.target.value
                                        )
                                    }
                                    value={searchFilters[stateName]}
                                    prefix={<Icon type="search" />}
                                    allowClear
                                    type={filterType}
                                    onPressEnter={updateFiltersFunction}
                                />
                            </div>
                            <div className="ok-button-container">
                                <Button
                                    type="primary"
                                    onClick={updateFiltersFunction}
                                >
                                    {getTranslatedText("Ok")}
                                </Button>
                            </div>
                        </div>
                    }
                    popoverTrigger="click"
                    buttonType="link"
                    buttonRestProps={{
                        onClick: () => {
                            if (showConditions[stateName]) {
                                changeConditionVisibility(false, stateName);
                            }
                        },
                    }}
                    buttonContent={
                        <div>
                            <span>{getTranslatedText(filterName)}</span>
                            <FontAwesome icon={['fas', 'sort-down']} />
                        </div>
                    }
                />
                {showConditions[`${stateName}FilterTags`] && (
                    <>
                        <br />
                        <Tag
                            className="tag-ws"
                            color={
                                isUndefined(
                                    newlyAddedFilters[
                                    `${stateName}${appliedFilterIndicator}`
                                    ]
                                )
                                    ? 'grey'
                                    : ''
                            }
                            closable
                            onClose={() => removeSearchFilter(stateName)}
                        >
                            <span className="wb-bw">
                                Contains{' '}
                                <b>
                                    {
                                        searchFilters[
                                        `${stateName}${appliedFilterIndicator}`
                                        ]
                                    }
                                </b>
                            </span>
                        </Tag>
                    </>
                )}
            </>
        );
    };

    /**
     * Function that creates the popover for input multiple tag filters.
     * @param filterStateName - name of filter to be stored in state
     * @param filterName - name of filter shown in UI
     */
    const createFilterPopoverMultipleInputTextWithTag = (
        filterStateName: string | undefined,
        filterName: string,
        filterType: string
    ) => {
        const stateName = filterStateName || filterName;
        const updateFiltersFunction = () => updateFilters(stateName);
        return (
            <>
                <ProtectedPopoverButton
                    containerRef={filterBarContainerRef}
                    popoverVisible={showConditions[stateName]}
                    popoverOnVisibleChange={(visible: boolean) => {
                        if (visible) {
                            changeConditionVisibility(visible, stateName);
                        }
                    }}
                    popoverPlacement="bottomLeft"
                    popoverContent={
                        <div className="pop-action-content">
                            <div>
                                <Select 
                                    mode="tags" 
                                    placeholder={getTranslatedText("Search")}
                                    value={searchFilters[stateName]}
                                    onChange={(value: any) => changeSearchFilter( stateName, value )} 
                                    tokenSeparators={[',']}
                                    notFoundContent={getTranslatedText("No Data")}
                                />
                            </div>
                            <div className="ok-button-container">
                                <Button
                                    type="primary"
                                    onClick={updateFiltersFunction}
                                >
                                    {getTranslatedText("Ok")}
                                </Button>
                            </div>
                        </div>
                    }
                    popoverTrigger="click"
                    buttonType="link"
                    buttonRestProps={{
                        onClick: () => {
                            if (showConditions[stateName]) {
                                changeConditionVisibility(false, stateName);
                            }
                        },
                    }}
                    buttonContent={
                        <div>
                            <span>{getTranslatedText(filterName)}</span>
                            <FontAwesome icon={['fas', 'sort-down']} />
                        </div>
                    }
                />
                {showConditions[`${stateName}FilterTags`] && (
                    <>
                        <br />
                        {
                            map(
                                searchFilters[`${stateName}${appliedFilterIndicator}`],
                                (value: any, index: number) => {
                                    return (
                                        <Tag
                                            key={index}
                                            className="tag-ws"
                                            color={
                                                isUndefined(
                                                    newlyAddedFilters[
                                                    `${stateName}${appliedFilterIndicator}`
                                                    ]
                                                )
                                                    ? 'grey'
                                                    : ''
                                            }
                                            closable
                                            onClose={() => removeSearchFilter(stateName)}
                                        >
                                            <span className="wb-bw">
                                                <b>
                                                    { value }
                                                </b>
                                            </span>
                                        </Tag>
                                    );
                                }
                            )
                        }
                    </>
                )}
            </>
        );
    };

    /**
     * Function that creates the popover for input with select search filters.
     * @param filterStateName - name of filter to be stored in state
     * @param filterName - name of filter shown in UI
     * @param filterQuery - name of the query for autocomplete
     * @param filterNameQuery - filter name in query
     * @param filterSort - sort field for the query to be used
     * @param filterResponse - response name of the query
     * @param filterLabelField - name of the field to be used as option label
     * @param filterNameQuerySub - name of substitute filter name in query
     * @param filterSubChecking - function for checking if filter name sub will be used or not
     * @param filterJSONValueFieldSub - name of json state value for sub
     * @param filterMappingUsed - mapping used for value
     * @param filterCustomError - filter custom error message
     */
    const createFilterPopoverSelectSearchWithTag = (
        filterStateName: string | undefined,
        filterName: string,
        filterQuery: string,
        filterNameQuery: string,
        filterSort: string,
        filterResponse: string,
        filterLabelField: string | string[],
        filterNameQuerySub?: string,
        filterSubChecking?: (inputVal: string | undefined | null) => boolean,
        filterJSONValueFieldSub?: string,
        tagClass?: string,
        filterMappingUsed?: DynamicObject,
        filterCustomError?: string,
        extraQueryVariables?: any
    ) => {
        const stateName = filterStateName || filterName;

        let tagDisplay = '';
        const appliedVal =
            searchFilters[`${stateName}${appliedFilterIndicator}`];
        let displayValue = '';
        if (appliedVal) {
            if (
                props.checkingForAssignedUser &&
                props.checkingForAssignedUser(appliedVal) &&
                !checkIsValidJsonString(appliedVal)
            ) {
                tagDisplay = appliedVal;
            } else if (
                checkIfEmailIsValid(appliedVal) &&
                !checkIsValidJsonString(appliedVal)
            ) {
                tagDisplay = appliedVal;
            } else {
                try {
                    const parsedData = JSON.parse(appliedVal);
                    forEach(filterLabelField, (lf: string) => {
                        if (tagDisplay !== '') tagDisplay += ' ';
                        tagDisplay += get(parsedData, lf) || '';

                        if (
                            filterSubChecking &&
                            filterNameQuerySub &&
                            filterJSONValueFieldSub
                        ) {
                            displayValue = get(
                                parsedData,
                                filterJSONValueFieldSub
                            );
                        }
                    });
                } catch (e) {
                    // return {};
                }
            }
        }

        if (isEmpty(displayValue)) displayValue = tagDisplay;

        const updateFiltersFunction = (defaultValue?: any) =>
            updateFilters(stateName, defaultValue);

        return (
            <>
                <ProtectedPopoverButton
                    containerRef={filterBarContainerRef}
                    popoverVisible={showConditions[stateName]}
                    popoverOnVisibleChange={(visible: boolean) => {
                        if (visible) {
                            changeConditionVisibility(visible, stateName);
                        }
                    }}
                    popoverPlacement="bottomLeft"
                    popoverContent={
                        <InputSelectUserSearchWithButton
                            updateField={(value: string) => {
                                changeSearchFilter(stateName, value);
                            }}
                            stateValue={displayValue}
                            appliedValue={appliedVal}
                            queryName={filterQuery}
                            filterField={filterStateName}
                            sortField={filterSort}
                            queryFilterName={filterNameQuery}
                            responseName={filterResponse}
                            labelField={filterLabelField}
                            updateFiltersFunction={updateFiltersFunction}
                            queryFilterNameSub={filterNameQuerySub}
                            filterSubChecking={filterSubChecking}
                            filterMappingUsed={filterMappingUsed}
                            customError={filterCustomError}
                            extraQueryVariables={extraQueryVariables}
                        />
                    }
                    popoverTrigger="click"
                    buttonType="link"
                    buttonRestProps={{
                        onClick: () => {
                            if (showConditions[stateName]) {
                                changeConditionVisibility(false, stateName);
                            }
                        },
                    }}
                    buttonContent={
                        <div>
                            <span>{getTranslatedText(filterName)}</span>
                            <FontAwesome icon={['fas', 'sort-down']} />
                        </div>
                    }
                />
                {showConditions[`${stateName}FilterTags`] && tagDisplay && (
                    <>
                        <br />
                        <Tag
                            className="tag-ws"
                            color={
                                isUndefined(
                                    newlyAddedFilters[
                                    `${stateName}${appliedFilterIndicator}`
                                    ]
                                )
                                    ? 'grey'
                                    : ''
                            }
                            closable
                            onClose={() => removeSearchFilter(stateName)}
                        >
                            <span>
                                <b className={tagClass}>{tagDisplay}</b>
                            </span>
                        </Tag>
                    </>
                )}
            </>
        );
    };

    /**
     * Function that creates the popover for input with autocomplete filters.
     * @param filterStateName - name of filter to be stored in state
     * @param filterName - name of filter shown in UI
     * @param filterQuery - name of the query for autocomplete
     * @param filterNameQuery - filter name in query
     * @param filterSort - sort field for the query to be used
     * @param filterResponse - response name of the query
     * @param filterLabelField - name of the field to be used as option label
     */
    const createFilterPopoverAutoCompleteWithTag = (
        filterStateName: string | undefined,
        filterName: string,
        filterQuery: string,
        filterNameQuery: string,
        filterSort: string,
        filterResponse: string,
        filterLabelField: string,
        extraQueryVariables: any = {}
    ) => {
        const stateName = filterStateName || filterName;
        const updateFiltersFunction = () => updateFilters(stateName);
        const filterNameUsed =
            filterStateName === 'Customer' &&
                filterQuery === 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER'
                ? capitalize(customerLabel)
                : filterName;
        return (
            <>
                <ProtectedPopoverButton
                    containerRef={filterBarContainerRef}
                    popoverVisible={showConditions[stateName]}
                    popoverOnVisibleChange={(visible: boolean) => {
                        if (visible) {
                            changeConditionVisibility(visible, stateName);
                        }
                    }}
                    popoverPlacement="bottomLeft"
                    popoverContent={
                        <InputAutoCompleteWithButton
                            updateField={(value: string) => {
                                changeSearchFilter(stateName, value);
                            }}
                            stateValue={searchFilters[stateName]}
                            queryName={filterQuery}
                            queryFilterName={filterNameQuery}
                            filterField={filterStateName}
                            sortField={filterSort}
                            responseName={filterResponse}
                            labelField={filterLabelField}
                            updateFiltersFunction={updateFiltersFunction}
                            onPressEnter={updateFiltersFunction}
                            extraQueryVariables={extraQueryVariables}
                        />
                    }
                    popoverTrigger="click"
                    buttonType="link"
                    buttonRestProps={{
                        onClick: () => {
                            if (showConditions[stateName]) {
                                changeConditionVisibility(false, stateName);
                            }
                        },
                    }}
                    buttonContent={
                        <div>
                            <span>{filterStateName && filterStateName.includes(customFieldIndicator) ? filterNameUsed : getTranslatedText(filterNameUsed)}</span>
                            <FontAwesome icon={['fas', 'sort-down']} />
                        </div>
                    }
                />
                {showConditions[`${stateName}FilterTags`] &&
                    searchFilters[`${stateName}${appliedFilterIndicator}`] && (
                        <>
                            <br />
                            <Tag
                                className="tag-ws"
                                color={
                                    isUndefined(
                                        newlyAddedFilters[
                                        `${stateName}${appliedFilterIndicator}`
                                        ]
                                    )
                                        ? 'grey'
                                        : ''
                                }
                                closable
                                onClose={() => removeSearchFilter(stateName)}
                            >
                                <span className="wb-bw">
                                    Contains{' '}
                                    <b>
                                        {
                                            searchFilters[
                                            `${stateName}${appliedFilterIndicator}`
                                            ]
                                        }
                                    </b>
                                </span>
                            </Tag>
                        </>
                    )}
            </>
        );
    };

    /**
     * Function that creates the popover for checkbox with input filters.
     * @param filterStateName - name of filter to be stored in state
     * @param filterName - name of filter shown in UI
     * @param options - checkbox options
     */
    const createFilterPopoverTextCheckboxGroupWithTag = (
        filterStateName: string | undefined,
        filterName: string,
        options: any
    ) => {
        const stateName = filterStateName || filterName;

        const searchFilterValues = get(searchFilters, stateName);

        const inputName = get(options, 'input.name');
        const inputValue = get(searchFilterValues, inputName);

        const defaultCheckValuesValue: any = [];

        const checkedValues = get(
            searchFilterValues,
            get(options, 'checkboxGroup.name'),
            defaultCheckValuesValue
        );

        return (
            <>
                <ProtectedPopoverButton
                    containerRef={filterBarContainerRef}
                    popoverVisible={showConditions[stateName]}
                    popoverOnVisibleChange={(visible: boolean) => {
                        if (visible) {
                            changeConditionVisibility(visible, stateName);
                        }
                    }}
                    popoverPlacement="bottomLeft"
                    popoverContent={
                        <div className="pop-action-content">
                            <div>
                                <Input
                                    placeholder={getTranslatedText("Search")}
                                    onChange={(event: {
                                        target: {
                                            value: string;
                                        };
                                    }) =>
                                        changeSearchFilter(stateName, {
                                            [options.input.name]:
                                                event.target.value,
                                        })
                                    }
                                    value={inputValue}
                                    prefix={<Icon type="search" />}
                                    allowClear
                                    onPressEnter={() => {
                                        changeSearchFilter(stateName, {
                                            ...searchFilterValues,
                                            [inputName]: inputValue,
                                        });
                                    }}
                                />
                            </div>
                            <div
                                className="mb-10"
                                style={{
                                    height: 10,
                                    borderBottom: '1px solid #e8e8e8',
                                }}
                            />
                            <div>
                                <Checkbox.Group
                                    className="checkbox-group-vertical"
                                    options={options.checkboxGroup.options}
                                    onChange={(
                                        checkedValues: CheckboxValueType[]
                                    ) => {
                                        changeSearchFilter(stateName, {
                                            ...searchFilterValues,
                                            [options.checkboxGroup.name]:
                                                checkedValues,
                                        });
                                    }}
                                    value={checkedValues}
                                />
                            </div>
                            <div className="ok-button-container">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        updateFiltersSelect(
                                            'input-checkbox-group',
                                            stateName,
                                            {
                                                ...searchFilterValues,
                                                [inputName]: inputValue,
                                                [options.checkboxGroup.name]:
                                                    checkedValues,
                                            }
                                        );
                                    }}
                                >
                                    {getTranslatedText("Ok")}
                                </Button>
                            </div>
                        </div>
                    }
                    popoverTrigger="click"
                    buttonType="link"
                    buttonRestProps={{
                        onClick: () => {
                            if (showConditions[stateName]) {
                                changeConditionVisibility(false, stateName);
                            }
                        },
                    }}
                    buttonContent={
                        <div>
                            <span>{getTranslatedText(filterName)}</span>
                            <FontAwesome icon={['fas', 'sort-down']} />
                        </div>
                    }
                />
                {showConditions[`${stateName}FilterTags`] && (
                    <>
                        <br />
                        <Tag
                            className="tag-ws"
                            color={
                                isUndefined(
                                    newlyAddedFilters[
                                    `${stateName}${appliedFilterIndicator}`
                                    ]
                                )
                                    ? 'grey'
                                    : ''
                            }
                            closable
                            onClose={() => removeSearchFilter(stateName)}
                        >
                            {
                                searchFilters[
                                `${stateName}${appliedFilterIndicator}`
                                ]
                            }
                        </Tag>
                    </>
                )}
            </>
        );
    };

    /**
     * Function that creates the popover for checkbox with dropdown filters.
     * @param filterStateName - name of filter to be stored in state
     * @param filterName - name of filter shown in UI
     * @param options - checkbox options
     */
    const createFilterPopoverSelectCheckboxGroupWithTag = (
        filterStateName: string | undefined,
        filterName: string,
        options: any
    ) => {
        const stateName = filterStateName || filterName;

        const searchFilterValues = get(searchFilters, stateName);
        const selectName = get(options, 'select.name');
        const defaultValue = get(options.select.options, '0.value');
        const filterSelectValue = get(
            searchFilterValues,
            selectName,
            defaultValue
        );

        const defaultEmptyValue: any = undefined;
        const defaultCheckedValues: any = map(
            options.checkboxGroup.options,
            'value'
        );

        const defaultCheckValuesValue = defaultCheckedValues;
        const checkedValues = get(
            searchFilterValues,
            get(options, 'checkboxGroup.name'),
            defaultCheckValuesValue
        );

        return (
            <>
                <ProtectedPopoverButton
                    containerRef={filterBarContainerRef}
                    popoverVisible={showConditions[stateName]}
                    popoverOnVisibleChange={(visible: boolean) => {
                        if (visible) {
                            changeConditionVisibility(visible, stateName);
                        }
                    }}
                    popoverPlacement="bottomLeft"
                    popoverContent={
                        <div className="pop-action-content">
                            <div>
                                <Select
                                    onChange={(value: any) => {
                                        changeSearchFilter(stateName, {
                                            [options.select.name]: value,
                                        });
                                    }}
                                    value={filterSelectValue}
                                    placeholder={getTranslatedText("Type")}
                                    style={{
                                        width: 200,
                                    }}
                                    getPopupContainer={populatePopupContainer()}
                                >
                                    {map(
                                        options.select.options,
                                        ({ label, value }: any) => (
                                            <Option key={value} value={value}>
                                                {label}
                                            </Option>
                                        )
                                    )}
                                </Select>
                            </div>
                            <div
                                className="mb-10"
                                style={{
                                    height: 10,
                                    borderBottom: '1px solid #e8e8e8',
                                }}
                            />
                            <div>
                                <Checkbox.Group
                                    className="checkbox-group-vertical"
                                    options={options.checkboxGroup.options}
                                    onChange={(
                                        checkedValues: CheckboxValueType[]
                                    ) => {
                                        changeSearchFilter(stateName, {
                                            ...searchFilterValues,
                                            [options.checkboxGroup.name]:
                                                checkedValues,
                                        });
                                    }}
                                    value={checkedValues}
                                />
                            </div>
                            <div className="ok-button-container">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        if (isEmpty(checkedValues)) {
                                            updateModalFilterRequired(
                                                filterName
                                            );
                                        } else {
                                            updateFiltersSelect(
                                                'select-checkbox-group',
                                                stateName,
                                                {
                                                    ...searchFilterValues,
                                                    [options.select.name]:
                                                        filterSelectValue,
                                                    [options.checkboxGroup
                                                        .name]: checkedValues,
                                                }
                                            );
                                        }
                                    }}
                                >
                                    {getTranslatedText("Ok")}
                                </Button>
                            </div>
                        </div>
                    }
                    popoverTrigger="click"
                    buttonType="link"
                    buttonRestProps={{
                        onClick: () => {
                            if (showConditions[stateName]) {
                                changeConditionVisibility(false, stateName);
                            }
                        },
                    }}
                    buttonContent={
                        <div>
                            <span>{getTranslatedText(filterName)}</span>
                            <FontAwesome icon={['fas', 'sort-down']} />
                        </div>
                    }
                />
                {showConditions[`${stateName}FilterTags`] && (
                    <>
                        <br />
                        <Tag
                            className="tag-ws"
                            color={
                                isUndefined(
                                    newlyAddedFilters[
                                    `${stateName}${appliedFilterIndicator}`
                                    ]
                                )
                                    ? 'grey'
                                    : ''
                            }
                            closable
                            onClose={() =>
                                removeSearchFilter(stateName, defaultEmptyValue)
                            }
                        >
                            {
                                searchFilters[
                                `${stateName}${appliedFilterIndicator}`
                                ]
                            }
                        </Tag>
                    </>
                )}
            </>
        );
    };

    /**
     * Function that creates the popover for checkbox groups.
     * @param filterStateName - name of filter to be stored in state
     * @param filterName - name of filter shown in UI
     * @param options - checkbox options
     * @param tagType - type of tag, wether in a form of sentence or comma separated
     * @param filterLoading - boolean indicator while options are fetched from an API
     */
    const createFilterPopoverCheckboxGroupWithTag = (
        filterStateName: string | undefined,
        filterName: string,
        options: any,
        tagType: string,
        filterLoading: boolean = false,
        checkedByDefault: boolean | undefined = true 
    ) => {
        const stateName = filterStateName || filterName;

        const optionsObject: any = {};
        forEach(options, (option: { label: string; value: string }) => {
            if (!optionsObject[option.value]) {
                optionsObject[option.value] = option.label;
            }
        });

        const defaultCheckValuesValue = checkedByDefault ? map(options, 'value') : [];

        let checkedValues = get(
            searchFilters,
            stateName,
            defaultCheckValuesValue
        );

        if (isString(checkedValues)) {
            const usedValue = /^\d+$/.test(checkedValues)
                ? parseInt(checkedValues)
                : checkedValues;
            checkedValues = [usedValue];
        }

        let tagDisplay;
        const optionsVal = map(options, 'value');
        checkedValues = filter(checkedValues, (cv: any) =>
            includes(optionsVal, cv)
        );

        if (tagType === 'sentence') {
            let tagSentence = '';
            forEach(
                searchFilters[`${stateName}${appliedFilterIndicator}`],
                (value: string, idx: number) => {
                    if (!includes(checkedValues, value)) return;

                    let prefix = ', ';
                    if (
                        idx ===
                        searchFilters[`${stateName}${appliedFilterIndicator}`]
                            .length -
                        1
                    ) {
                        prefix = ', and ';
                    }

                    if (!isEmpty(tagSentence)) {
                        tagSentence += prefix;
                    }

                    tagSentence += optionsObject[value];
                }
            );

            tagDisplay = (
                <Tag
                    className="tag-ws"
                    color={
                        isUndefined(
                            newlyAddedFilters[
                            `${stateName}${appliedFilterIndicator}`
                            ]
                        )
                            ? 'grey'
                            : ''
                    }
                    closable
                    onClose={() => removeSearchFilter(stateName, undefined)}
                >
                    <b>{`Has ${tagSentence.toLowerCase()}`}</b>
                </Tag>
            );
        } else {
            tagDisplay = map(
                searchFilters[`${stateName}${appliedFilterIndicator}`],
                (value: any) => {
                    if (!includes(checkedValues, value)) return undefined;

                    let tagColor = '';

                    if (
                        isUndefined(
                            newlyAddedFilters[
                            `${stateName}${appliedFilterIndicator}`
                            ]
                        ) ||
                        includes(get(props.filterValues, stateName, []), value)
                    ) {
                        tagColor = 'grey';
                    }
                    return (
                        <Tag
                            className="tag-ws"
                            color={tagColor}
                            key={value}
                            closable
                            onClose={() =>
                                removeCheckboxFilter(
                                    value,
                                    stateName,
                                    checkedValues
                                )
                            }
                        >
                            <b>{optionsObject[value]}</b>
                        </Tag>
                    );
                }
            );
        }

        return (
            <>
                <ProtectedPopoverButton
                    containerRef={filterBarContainerRef}
                    popoverVisible={showConditions[stateName]}
                    popoverOverlayStyle={{ maxWidth: 280 }}
                    popoverOnVisibleChange={(visible: boolean) => {
                        if (visible) {
                            changeConditionVisibility(visible, stateName);
                        }
                    }}
                    popoverPlacement="bottomLeft"
                    popoverContent={
                        <div className="pop-action-content">
                            <div className="custom-tree-cont">
                                <Spin spinning={filterLoading}>
                                    <div>
                                        {isEmpty(options) ? (
                                            <div>{getTranslatedText("No options found")}</div>
                                        ) : (
                                            <Checkbox.Group
                                                className="checkbox-group-vertical"
                                                options={options}
                                                onChange={(
                                                    checkedValues: CheckboxValueType[]
                                                ) =>
                                                    changeSearchFilter(
                                                        stateName,
                                                        checkedValues
                                                    )
                                                }
                                                value={checkedValues}
                                            />
                                        )}
                                    </div>
                                </Spin>
                            </div>
                            <div className="ok-button-container">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        updateFilters(stateName, checkedValues);
                                    }}
                                >
                                    {getTranslatedText("Ok")}
                                </Button>
                            </div>
                        </div>
                    }
                    popoverTrigger="click"
                    buttonType="link"
                    buttonRestProps={{
                        onClick: () => {
                            if (showConditions[stateName]) {
                                changeConditionVisibility(false, stateName);
                            }
                        },
                    }}
                    buttonContent={
                        <div>
                            <span>{filterStateName && filterStateName.includes(customFieldIndicator) ? filterName : getTranslatedText(filterName)}</span>
                            <FontAwesome icon={['fas', 'sort-down']} />
                        </div>
                    }
                />
                {showConditions[`${stateName}FilterTags`] && (
                    <>
                        <br />
                        <div>{tagDisplay}</div>
                    </>
                )}
            </>
        );
    };

    /**
     * Function for populating the tree node
     * @param data - item which the tree node will be based on
     * @param parent - parent of data
     */
    const renderTreeNodes = (data: any, parent?: DynamicObject) =>
        map(data, (item: DynamicObject) => {
            if (item.children) {
                return (
                    <TreeNode
                        title={item.label}
                        key={item.value}
                        dataRef={item}
                        selectable={false}
                    >
                        {renderTreeNodes(item.children, item)}
                    </TreeNode>
                );
            }

            return (
                <TreeNode
                    key={`${get(
                        parent,
                        'value'
                    )}${treeParentChildSeparator}${get(item, 'value')}`}
                    title={get(item, 'label')}
                    selectable={false}
                />
            );
        });

    /**
     * Function for getting the default checked values for tree filter
     */
    const getTreeDefaultCheckedValues = (
        options: DynamicObject[],
        list: string[],
        parent?: DynamicObject
    ) => {
        forEach(options, (opt) => {
            if (opt.children) {
                list.push(opt.value);
                return getTreeDefaultCheckedValues(opt.children, list, opt);
            }

            return list.push(
                `${get(parent, 'value')}${treeParentChildSeparator}${get(
                    opt,
                    'value'
                )}`
            );
        });
    };

    /**
     * Function that creates the popover for checkbox groups.
     * @param filterStateName - name of filter to be stored in state
     * @param filterName - name of filter shown in UI
     * @param options - checkbox options
     * @param filterLoading - boolean indicator while options are fetched from an API
     */
    const createFilterPopoverCheckboxGroupTreeWithTag = (
        filterStateName: string | undefined,
        filterName: string,
        options: any,
        filterLoading: boolean = false
    ) => {
        const stateName = filterStateName || filterName;

        const optionsObject: any = {};
        forEach(options, (option: { label: string; value: string }) => {
            if (!optionsObject[option.value]) {
                optionsObject[option.value] = option.label;
            }
        });

        const defaultCheckValuesValue: string[] = [];
        getTreeDefaultCheckedValues(options, defaultCheckValuesValue);

        let checkedValues = get(
            searchFilters,
            stateName,
            defaultCheckValuesValue
        );

        const tagDisplayobject: DynamicObject = {};
        forEach(
            searchFilters[`${stateName}${appliedFilterIndicator}`],
            (value: string) => {
                value = value.toString();
                let tagColor = '';

                if (
                    isUndefined(
                        newlyAddedFilters[
                        `${stateName}${appliedFilterIndicator}`
                        ]
                    ) ||
                    includes(get(props.filterValues, stateName, []), value)
                ) {
                    tagColor = 'grey';
                }
                const valueArr = value.split(treeParentChildSeparator);
                const parentValue = get(valueArr, 0);
                const childName = get(valueArr, 1);
                if (!(parentValue in tagDisplayobject)) {
                    tagDisplayobject[parentValue] = [];
                }

                if (childName) {
                    tagDisplayobject[parentValue].push(
                        <Tag
                            className="tag-ws"
                            color={tagColor}
                            key={value}
                            closable
                            onClose={() =>
                                removeTreeCheckboxFilter(
                                    value,
                                    stateName,
                                    checkedValues
                                )
                            }
                        >
                            <b>{childName}</b>
                        </Tag>
                    );
                }
            }
        );

        const tagDisplay = map(options, (opt: any) => {
            const idx = opt.value;
            const tdo = get(tagDisplayobject, idx);
            if (!tdo) return;
            const parentName = get(optionsObject, `${idx}`);
            const childrentTagFilters = map(tdo, (tdoi, tdoidx) => (
                <Row key={tdoidx} style={{ paddingBottom: 2 }}>
                    <Col>{tdoi}</Col>
                </Row>
            ));
            return (
                <Tag key={idx} className="tag-tree-parent mxw-100">
                    <Row>
                        <Col span={24} className="mxw-100 ws-nm">
                            {parentName}
                        </Col>
                        <Col span={24}>
                            {tdo.length > 2 ? (
                                <Popover
                                    content={<div>{childrentTagFilters}</div>}
                                    title={parentName}
                                    placement="right"
                                    getPopupContainer={populatePopupContainer()}
                                >
                                    <Button type="primary" size="small">
                                        {getTranslatedText("Various...")}
                                    </Button>
                                </Popover>
                            ) : (
                                childrentTagFilters
                            )}
                        </Col>
                    </Row>
                </Tag>
            );
        });

        const defaultExpandedKeys = map(options, 'value');
        return (
            <>
                <ProtectedPopoverButton
                    containerRef={filterBarContainerRef}
                    popoverVisible={showConditions[stateName]}
                    popoverOverlayStyle={{ maxWidth: 280 }}
                    popoverOnVisibleChange={(visible: boolean) => {
                        if (visible) {
                            changeConditionVisibility(visible, stateName);
                        }
                    }}
                    popoverPlacement="bottomLeft"
                    popoverContent={
                        <div className="pop-action-content">
                            <div className="custom-tree-cont">
                                <Spin spinning={filterLoading}>
                                    <div>
                                        {isEmpty(options) ? (
                                            <div>{getTranslatedText("No options found")}</div>
                                        ) : (
                                            <Tree
                                                checkable
                                                defaultExpandedKeys={
                                                    defaultExpandedKeys
                                                }
                                                onCheck={(
                                                    checkedValues: DynamicObject
                                                ) =>
                                                    changeSearchFilter(
                                                        stateName,
                                                        checkedValues
                                                    )
                                                }
                                                checkedKeys={checkedValues}
                                            >
                                                {renderTreeNodes(options)}
                                            </Tree>
                                        )}
                                    </div>
                                </Spin>
                            </div>
                            <div className="ok-button-container">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        updateFilters(stateName, checkedValues);
                                    }}
                                >
                                    {getTranslatedText("Ok")}
                                </Button>
                            </div>
                        </div>
                    }
                    popoverTrigger="click"
                    buttonType="link"
                    buttonRestProps={{
                        onClick: () => {
                            if (showConditions[stateName]) {
                                changeConditionVisibility(false, stateName);
                            }
                        },
                    }}
                    buttonContent={
                        <div>
                            <span>{getTranslatedText(filterName)}</span>
                            <FontAwesome icon={['fas', 'sort-down']} />
                        </div>
                    }
                />
                {showConditions[`${stateName}FilterTags`] && (
                    <>
                        <br />
                        <div>{tagDisplay}</div>
                    </>
                )}
            </>
        );
    };

    /**
     * Function that will be triggered when selecting a date range.
     * @param rangeType  - From and To - based on UI mockup
     * @param filterName - name of filter
     */
    const selectDateRange = (rangeType: 'From' | 'To', filterName: string) => {
        return (dateValue: any) => {
            const searchFilterParent = get(searchFilters, filterName);
            const newSearchFilterParent = {
                ...searchFilterParent,
                [rangeType]: dateValue,
            };
            updateSearchFiltersObject({
                [filterName]: newSearchFilterParent,
            });
        };
    };

    /**
     * Function responsible for setting the days range filter values.
     * @param rangeType - Last, Next
     * @param filterName - name of filter
     * @param daysType  - From, To, or not defined
     */
    const selectDaysRange = (
        rangeType: 'Last' | 'Next',
        filterName: string,
        daysType: 'From' | 'To' | undefined = undefined
    ) => {
        return (daysValue: number | undefined) => {
            if (daysType) {
                const searchFilterParent = get(searchFilters, filterName);
                if (get(searchFilterParent, daysType)) {
                    if (rangeType === 'Last') {
                        delete searchFilterParent[daysType].Next;
                    } else {
                        delete searchFilterParent[daysType].Last;
                    }
                }
                const newSearchFilterParent = {
                    ...searchFilterParent,
                    [daysType]: {
                        [rangeType]: daysValue,
                    },
                };

                updateSearchFiltersObject({
                    [filterName]: newSearchFilterParent,
                });
            } else {
                const searchFilterParent = get(searchFilters, filterName);
                if (rangeType === 'Last') {
                    delete searchFilterParent.Next;
                } else {
                    delete searchFilterParent.Last;
                }
                const newSearchFilterParent = {
                    ...searchFilterParent,
                    [rangeType]: daysValue,
                };

                updateSearchFiltersObject({
                    [filterName]: newSearchFilterParent,
                });
            }
        };
    };

    /**
     * Function for populating the popup container wrapper
     * to make sure that the filter will not be overlayed
     * by some other component.
     */
    const populatePopupContainer = () => {
        return filterBarContainerRef
            ? () => getPopoverContainer(filterBarContainerRef)
            : undefined;
    };

    /**
     * Function to populate the select/dropdown filter.
     * @param customType - nype of filter, date-range or etc
     * @param filterName - name of filter
     * @param filterMaxDate - optional max date for filter, can be a moment object or string
     */
    const populateSelectCustomType = (
        customType: string,
        filterName: string,
        filterMaxDate?: any
    ) => {
        if (customType === 'date-range') {
            const currentDate = moment();
            let disabledDate;
            if (filterMaxDate) {
                disabledDate = (dateValue: any) => {
                    return moment(
                        moment(dateValue).format(dateFormatYYYYMMDDDash)
                    ).isAfter(
                        moment(
                            moment(filterMaxDate).format(dateFormatYYYYMMDDDash)
                        )
                    );
                };
            }
            return (
                <div>
                    <Divider />
                    <div className="center-flex-sb mb-10">
                        <div>{getTranslatedText("From")}</div>
                        &nbsp;
                        <div>
                            <DatePicker
                                format={dateFormatDDMMYYYYSlash}
                                value={get(
                                    searchFilters,
                                    `${filterName}.From`,
                                    currentDate
                                )}
                                disabledDate={disabledDate}
                                placeholder={getTranslatedText("Start Date")}
                                onChange={selectDateRange('From', filterName)}
                                getCalendarContainer={
                                    filterBarContainerRef
                                        ? () =>
                                            getPopoverContainer(
                                                filterBarContainerRef
                                            )
                                        : undefined
                                }
                            />
                        </div>
                    </div>
                    {filterName !== 'LastChatDate' ? 
                        <div className="center-flex-sb">
                            <div>{getTranslatedText("To")}</div>
                            &nbsp;
                            <div>
                                <DatePicker
                                    format={dateFormatDDMMYYYYSlash}
                                    value={get(
                                        searchFilters,
                                        `${filterName}.To`,
                                        currentDate
                                    )}
                                    disabledDate={disabledDate}
                                    placeholder={getTranslatedText("End Date")}
                                    onChange={selectDateRange('To', filterName)}
                                    getCalendarContainer={
                                        filterBarContainerRef
                                            ? () =>
                                                getPopoverContainer(
                                                    filterBarContainerRef
                                                )
                                            : undefined
                                    }
                                />
                            </div>
                        </div> : ''
                    }                    
                </div>
            );
        } else if (customType === 'last-days-range') {
            const daysValue = get(searchFilters, `${filterName}.Last`);
            return (
                <div>
                    <Divider />
                    <div>
                        <span>{getTranslatedText("Last")}</span>
                        &nbsp;
                        <span>
                            <InputNumber
                                value={daysValue}
                                onChange={selectDaysRange('Last', filterName)}
                                placeholder={getTranslatedText("Count")}
                                min={1}
                            />
                        </span>
                        &nbsp;
                        <span>
                            {getTranslatedText(`day${ daysValue > 1 ? 's' : ''}`)}
                        </span>
                    </div>
                </div>
            );
        } else if (customType === 'all-days-range') {
            const daysRangeType = ['Last', 'Next'];
            const rangeTypeSelected = has(searchFilters, `${filterName}.Last`)
                ? 'Last'
                : 'Next';

            const daysValue = get(
                searchFilters,
                `${filterName}.${rangeTypeSelected}`
            );
            return (
                <div>
                    <Divider />
                    <div>
                        <span>
                            <Select
                                onChange={(rangeType: 'Last' | 'Next') => {
                                    selectDaysRange(rangeType, filterName)(1);
                                }}
                                value={rangeTypeSelected}
                                placeholder={getTranslatedText("Type")}
                                style={{
                                    width: 100,
                                }}
                                getPopupContainer={populatePopupContainer()}
                            >
                                {map(daysRangeType, (rangeType: string) => (
                                    <Option key={rangeType} value={rangeType}>
                                        {rangeType}
                                    </Option>
                                ))}
                            </Select>
                        </span>
                        &nbsp;
                        <span>
                            <InputNumber
                                value={daysValue}
                                placeholder={getTranslatedText("Count")}
                                onChange={selectDaysRange(
                                    rangeTypeSelected,
                                    filterName
                                )}
                                min={1}
                            />
                        </span>
                        &nbsp;
                        <span>
                            {getTranslatedText(`day${daysValue > 1 ? 's' : ''}`)}
                        </span>
                    </div>
                </div>
            );
        } else if (customType === 'all-days-range-from-to') {
            const daysRangeType = ['Last', 'Next'];
            const rangeTypeSelectedFrom = has(
                searchFilters,
                `${filterName}.From.Last`
            )
                ? 'Last'
                : 'Next';

            const rangeTypeSelectedTo = has(
                searchFilters,
                `${filterName}.To.Last`
            )
                ? 'Last'
                : 'Next';

            const daysValueFrom = get(
                searchFilters,
                `${filterName}.From.${rangeTypeSelectedFrom}`
            );
            const daysValueTo = get(
                searchFilters,
                `${filterName}.To.${rangeTypeSelectedTo}`
            );

            return (
                <div>
                    <Divider className="mb-12" />
                    {map(['From', 'To'], (daysType: 'From' | 'To') => {
                        let typeLabel = 'Selected from';
                        let rangeTypeSelected: 'Last' | 'Next' =
                            rangeTypeSelectedFrom;
                        let daysValue = daysValueFrom;

                        if (daysType === 'To') {
                            typeLabel = 'Go to';
                            rangeTypeSelected = rangeTypeSelectedTo;
                            daysValue = daysValueTo;
                        }

                        return (
                            <div key={daysType} className="mb-10">
                                <div>{typeLabel}</div>
                                <div>
                                    <span>

                                        <Select
                                            onChange={(
                                                rangeType: 'Last' | 'Next'
                                            ) => {
                                                selectDaysRange(
                                                    rangeType,
                                                    filterName,
                                                    daysType
                                                )(1);
                                            }}
                                            value={rangeTypeSelected}
                                            placeholder={getTranslatedText("Type")}
                                            style={{
                                                width: 100,
                                            }}
                                            getPopupContainer={populatePopupContainer()}
                                        >
                                            {map(
                                                daysRangeType,
                                                (rangeType: string) => (
                                                    <Option
                                                        key={rangeType}
                                                        value={rangeType}
                                                    >
                                                        {rangeType}
                                                    </Option>
                                                )
                                            )}
                                        </Select>
                                    </span>
                                    &nbsp;
                                    <span>
                                        <InputNumber
                                            value={daysValue}
                                            placeholder={getTranslatedText("Count")}
                                            onChange={selectDaysRange(
                                                rangeTypeSelected,
                                                filterName,
                                                daysType
                                            )}
                                            min={1}
                                        />
                                    </span>
                                    &nbsp;
                                    <span>
                                        {getTranslatedText(`day${daysValue > 1 ? 's' : ''}`)}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        } else if (customType === 'all-days-range-from-to-last') {
            const daysRangeType = ['Last'];
            const rangeTypeSelectedFrom = 'Last';
            const rangeTypeSelectedTo = 'Last';

            const daysValueFrom = get(
                searchFilters,
                `${filterName}.From.${rangeTypeSelectedFrom}`
            );
            const daysValueTo = get(
                searchFilters,
                `${filterName}.To.${rangeTypeSelectedTo}`
            );

            return (
                <div>
                    <Divider className="mb-12" />
                    {map(['From', 'To'], (daysType: 'From' | 'To') => {
                        let typeLabel = 'Selected from';
                        let rangeTypeSelected: 'Last' = rangeTypeSelectedFrom;
                        let daysValue = daysValueFrom;

                        if (daysType === 'To') {
                            typeLabel = 'Go to';
                            rangeTypeSelected = rangeTypeSelectedTo;
                            daysValue = daysValueTo;
                        }

                        if (filterName !== 'LastChatDate') {
                            return (
                                <div key={daysType} className="mb-10">
                                    <div>{typeLabel}</div>
                                    <div>
                                        <span>
                                            <Select
                                                onChange={(rangeType: 'Last') => {
                                                    selectDaysRange(
                                                        rangeType,
                                                        filterName,
                                                        daysType
                                                    )(1);
                                                }}
                                                value={rangeTypeSelected}
                                                placeholder={getTranslatedText("Type")}
                                                style={{
                                                    width: 100,
                                                }}
                                                getPopupContainer={populatePopupContainer()}
                                            >
                                                {map(
                                                    daysRangeType,
                                                    (rangeType: string) => (
                                                        <Option
                                                            key={rangeType}
                                                            value={rangeType}
                                                        >
                                                            {rangeType}
                                                        </Option>
                                                    )
                                                )}
                                            </Select>
                                        </span>
                                        &nbsp;
                                        <span>
                                            <InputNumber
                                                value={daysValue}
                                                placeholder={getTranslatedText("Count")}
                                                onChange={selectDaysRange(
                                                    rangeTypeSelected,
                                                    filterName,
                                                    daysType
                                                )}
                                                min={1}
                                            />
                                        </span>
                                        &nbsp;
                                        <span>
                                            {getTranslatedText(`day${daysValue > 1 ? 's' : ''}`)}
                                        </span>
                                    </div>
                                </div>
                            );
                        } else {
                            if (daysType !== 'To') {
                                return (
                                    <div key={daysType} className="mb-10">
                                        <div>{typeLabel}</div>
                                        <div>
                                            <span>
                                                <Select
                                                    onChange={(rangeType: 'Last') => {
                                                        selectDaysRange(
                                                            rangeType,
                                                            filterName,
                                                            daysType
                                                        )(1);
                                                    }}
                                                    value={rangeTypeSelected}
                                                    placeholder={getTranslatedText("Type")}
                                                    style={{
                                                        width: 100,
                                                    }}
                                                    getPopupContainer={populatePopupContainer()}
                                                >
                                                    {map(
                                                        daysRangeType,
                                                        (rangeType: string) => (
                                                            <Option
                                                                key={rangeType}
                                                                value={rangeType}
                                                            >
                                                                {rangeType}
                                                            </Option>
                                                        )
                                                    )}
                                                </Select>
                                            </span>
                                            &nbsp;
                                            <span>
                                                <InputNumber
                                                    value={daysValue}
                                                    placeholder={getTranslatedText("Count")}
                                                    onChange={selectDaysRange(
                                                        rangeTypeSelected,
                                                        filterName,
                                                        daysType
                                                    )}
                                                    min={1}
                                                />
                                            </span>
                                            &nbsp;
                                            <span>
                                                {getTranslatedText(`day${daysValue > 1 ? 's' : ''}`)}
                                            </span>
                                        </div>
                                    </div>
                                );
                            }
                        }
                    })}
                </div>
            );
        }
    };

    /**
     * Function for populating the dropdown filters.
     * @param filterStateName - name of state for filter
     * @param filterName - name of filter
     * @param options - options for the dropdown component
     * @param optionsCustomTypes - types of options, defined from the parent component
     * @param filterMaxDate - can be moment object, string, or any other that can be converted by moment
     */
    const createFilterPopoverSelectWithTag = (
        filterStateName: string | undefined,
        filterName: string,
        options: any,
        optionsCustomTypes: any,
        filterMaxDate?: any
    ) => {
        const stateName = filterStateName || filterName;
        const optionsObject: any = {};
        forEach(options, (option: string) => {
            if (!optionsObject[option]) {
                optionsObject[option] = option;
            }
        });
        const defaultValue = get(options, 0);
        const filterSelectValue = get(
            searchFilters[stateName],
            'value',
            defaultValue
        );

        const selectCustomType =
            optionsCustomTypes[get(searchFilters[stateName], 'value')];

        const filterIdx = findIndex(
            props.filters,
            ({ filterStateName }) => filterStateName === stateName
        );

        return (
            <>
                <ProtectedPopoverButton
                    containerRef={filterBarContainerRef}
                    popoverVisible={showConditions[stateName]}
                    popoverOnVisibleChange={(visible: boolean) => {
                        if (visible) {
                            changeConditionVisibility(visible, stateName);
                        }
                    }}
                    popoverPlacement="bottomLeft"
                    popoverContent={
                        <div className="pop-action-content">
                            <div>
                                <Select
                                    className="date-picker-select"
                                    onChange={(selectValue: any) => {
                                        const newFilterValue: any = {
                                            value: selectValue,
                                        };
                                        if (
                                            selectValue ===
                                            dateSelectOptions.CUSTOM_DAYS_RANGE
                                        ) {
                                            const customType =
                                                optionsCustomTypes[selectValue];

                                            if (
                                                customType ===
                                                'all-days-range-from-to' ||
                                                customType ===
                                                'all-days-range-from-to-last'
                                            ) {
                                                newFilterValue.From = {
                                                    Last: 1,
                                                };
                                                newFilterValue.To = {
                                                    Last: 1,
                                                };
                                            } else {
                                                newFilterValue.Last = 1;
                                            }
                                        }

                                        changeSearchFilter(
                                            stateName,
                                            newFilterValue
                                        );
                                    }}
                                    value={filterSelectValue}
                                    getPopupContainer={populatePopupContainer()}
                                >
                                    {map(optionsObject, (option: any) => (
                                        <Option key={option} value={option}>
                                            {option}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            {selectCustomType &&
                                populateSelectCustomType(
                                    selectCustomType,
                                    stateName,
                                    filterMaxDate
                                )}
                            <div className="ok-button-container">
                                <Button
                                    type="primary"
                                    ref={okButtonRef.current[filterIdx]}
                                    onClick={() => {
                                        const {
                                            CUSTOM_DATE_RANGE,
                                            CUSTOM_DAYS_RANGE,
                                        } = dateSelectOptions;
                                        if (
                                            filterSelectValue ===
                                            CUSTOM_DATE_RANGE
                                        ) {
                                            const currentDate = moment();

                                            const fromDate =
                                                get(
                                                    searchFilters[stateName],
                                                    'From'
                                                ) === undefined
                                                    ? currentDate
                                                    : get(
                                                        searchFilters[
                                                        stateName
                                                        ],
                                                        'From'
                                                    );

                                            const toDate =
                                                get(
                                                    searchFilters[stateName],
                                                    'To'
                                                ) === undefined
                                                    ? currentDate
                                                    : get(
                                                        searchFilters[
                                                        stateName
                                                        ],
                                                        'To'
                                                    );

                                            if (fromDate && toDate) {
                                                const isIncorrectRange =
                                                    checkDateRangeIfCorrect(
                                                        fromDate,
                                                        toDate
                                                    );

                                                if (isIncorrectRange) {
                                                    return showModalError(
                                                        <div>{getTranslatedText("From date should be before or equal To date").split(/(<b>[^<]+<\/b>)/g) // Split on <b>...</b> tags
                                                            .map((part, index) => {
                                                                if (part.startsWith('<b>') && part.endsWith('</b>')) {
                                                                    // Remove <b> and </b> tags, and wrap the content in JSX <b>
                                                                    return (
                                                                        <b key={index}>
                                                                            {part.replace(/<\/?b>/g, '')} {/* Strip the <b> tags */}
                                                                        </b>
                                                                    );
                                                                }
                                                                return part; // Return normal text
                                                            })}</div>,
                                                        filterIdx
                                                    );
                                                }
                                            } else {
                                                if (
                                                    fromDate === null &&
                                                    toDate === null
                                                ) {
                                                    return showModalError(
                                                        <div>{getTranslatedText("Please select From or To date").split(/(<b>[^<]+<\/b>)/g) // Split on <b>...</b> tags
                                                            .map((part, index) => {
                                                                if (part.startsWith('<b>') && part.endsWith('</b>')) {
                                                                    // Remove <b> and </b> tags, and wrap the content in JSX <b>
                                                                    return (
                                                                        <b key={index}>
                                                                            {part.replace(/<\/?b>/g, '')} {/* Strip the <b> tags */}
                                                                        </b>
                                                                    );
                                                                }
                                                                return part; // Return normal text
                                                            })}</div>,
                                                        filterIdx
                                                    );
                                                }
                                            }

                                            if (
                                                !(
                                                    get(
                                                        searchFilters[
                                                        stateName
                                                        ],
                                                        'From'
                                                    ) &&
                                                    get(
                                                        searchFilters[
                                                        stateName
                                                        ],
                                                        'To'
                                                    )
                                                )
                                            ) {
                                                return updateFiltersSelect(
                                                    selectCustomType,
                                                    stateName,
                                                    {
                                                        ...searchFilters[
                                                        stateName
                                                        ],
                                                        value: filterSelectValue,
                                                        From: get(
                                                            searchFilters[
                                                            stateName
                                                            ],
                                                            'From',
                                                            currentDate
                                                        ),
                                                        // To: get(
                                                        //     searchFilters[
                                                        //         stateName
                                                        //     ],
                                                        //     'To',
                                                        //     currentDate
                                                        // ),
                                                    }
                                                );
                                            }
                                        } else if (
                                            filterSelectValue ===
                                            CUSTOM_DAYS_RANGE
                                        ) {
                                            const customType =
                                                optionsCustomTypes[
                                                filterSelectValue
                                                ];

                                            if (
                                                customType ===
                                                'all-days-range-from-to'
                                            ) {
                                                if (
                                                    !(
                                                        (get(
                                                            searchFilters[
                                                            stateName
                                                            ],
                                                            'From.Last'
                                                        ) ||
                                                            get(
                                                                searchFilters[
                                                                stateName
                                                                ],
                                                                'From.Next'
                                                            )) &&
                                                        (get(
                                                            searchFilters[
                                                            stateName
                                                            ],
                                                            'To.Last'
                                                        ) ||
                                                            get(
                                                                searchFilters[
                                                                stateName
                                                                ],
                                                                'To.Next'
                                                            ))
                                                    )
                                                ) {
                                                    return updateModalFilterRequired(
                                                        filterName
                                                    );
                                                }
                                            } else if (
                                                customType ===
                                                'all-days-range-from-to-last'
                                            ) {
                                                if (
                                                    !(
                                                        get(
                                                            searchFilters[
                                                            stateName
                                                            ],
                                                            'From.Last'
                                                        ) &&
                                                        get(
                                                            searchFilters[
                                                            stateName
                                                            ],
                                                            'To.Last'
                                                        )
                                                    )
                                                ) {
                                                    return updateModalFilterRequired(
                                                        filterName
                                                    );
                                                }
                                            } else {
                                                if (
                                                    !(
                                                        get(
                                                            searchFilters[
                                                            stateName
                                                            ],
                                                            'Last'
                                                        ) ||
                                                        get(
                                                            searchFilters[
                                                            stateName
                                                            ],
                                                            'Next'
                                                        )
                                                    )
                                                ) {
                                                    return updateModalFilterRequired(
                                                        filterName
                                                    );
                                                }
                                            }
                                        }

                                        updateFiltersSelect(
                                            selectCustomType,
                                            stateName,
                                            {
                                                ...searchFilters[stateName],
                                                value: filterSelectValue,
                                            }
                                        );
                                    }}
                                >
                                    {getTranslatedText("Ok")}
                                </Button>
                            </div>
                        </div>
                    }
                    popoverTrigger="click"
                    buttonType="link"
                    buttonRestProps={{
                        onClick: () => {
                            if (showConditions[stateName]) {
                                changeConditionVisibility(false, stateName);
                            }
                        },
                    }}
                    buttonContent={
                        <div>
                            <span>{getTranslatedText(filterName)}</span>
                            <FontAwesome icon={['fas', 'sort-down']} />
                        </div>
                    }
                />
                {showConditions[`${stateName}FilterTags`] && (
                    <>
                        <br />
                        <Tag
                            className="tag-ws"
                            color={
                                isUndefined(
                                    newlyAddedFilters[
                                    `${stateName}${appliedFilterIndicator}`
                                    ]
                                )
                                    ? 'grey'
                                    : ''
                            }
                            closable
                            onClose={() => removeSearchFilter(stateName)}
                        >
                            {
                                searchFilters[
                                `${stateName}${appliedFilterIndicator}`
                                ]
                            }
                        </Tag>
                    </>
                )}
            </>
        );
    };

    /**
     * Function for populating the radio group filter.
     * @param filterStateName - name of state for filter
     * @param filterName - name of filter
     * @param filterPlaceholder - name of placeholder for filter
     * @param options - options for the dropdown component
     */
    const createFilterPopoverRadioGroupWithTag = (
        filterStateName: string | undefined,
        filterName: string,
        filterPlaceholder: string,
        options: any
    ) => {
        const stateName = filterStateName || filterName;
        const filterSelectValue = get(searchFilters, stateName);
        const filterPlaceholderValue = filterPlaceholder || 'Select state';

        const updateFiltersFunction = () => updateFilters(stateName);
        const tagLabel = get(
            find(options, ['value', filterSelectValue]),
            'label'
        );

        return (
            <>
                <ProtectedPopoverButton
                    containerRef={filterBarContainerRef}
                    popoverVisible={showConditions[stateName]}
                    popoverOnVisibleChange={(visible: boolean) => {
                        if (visible) {
                            changeConditionVisibility(visible, stateName);
                        }
                    }}
                    popoverPlacement="bottomLeft"
                    popoverContent={
                        <div className="pop-action-content">
                            <div>
                                <Select
                                    onChange={(selectValue: any) => {
                                        changeSearchFilter(
                                            stateName,
                                            selectValue
                                        );
                                    }}
                                    placeholder={getTranslatedText(filterPlaceholderValue)}
                                    value={filterSelectValue}
                                    getPopupContainer={populatePopupContainer()}
                                >
                                    {map(options, ({ label, value }: any) => (
                                        <Option key={value} value={value}>
                                            {label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>

                            <div className="ok-button-container">
                                <Button
                                    type="primary"
                                    onClick={updateFiltersFunction}
                                >
                                    {getTranslatedText("Ok")}
                                </Button>
                            </div>
                        </div>
                    }
                    popoverTrigger="click"
                    buttonType="link"
                    buttonRestProps={{
                        onClick: () => {
                            if (showConditions[stateName]) {
                                changeConditionVisibility(false, stateName);
                            }
                        },
                    }}
                    buttonContent={
                        <div>
                            <span>{getTranslatedText(filterName)}</span>
                            <FontAwesome icon={['fas', 'sort-down']} />
                        </div>
                    }
                />
                {showConditions[`${stateName}FilterTags`] && (
                    <>
                        <br />
                        <Tag
                            className="tag-ws"
                            color={
                                isUndefined(
                                    newlyAddedFilters[
                                    `${stateName}${appliedFilterIndicator}`
                                    ]
                                )
                                    ? 'grey'
                                    : ''
                            }
                            closable
                            onClose={() => removeSearchFilter(stateName)}
                        >
                            {tagLabel}
                        </Tag>
                    </>
                )}
            </>
        );
    };

    const showModalError = (content: any, filterIdx: number) => {
        Modal.error({
            className: 'modal-error-left',
            title: getTranslatedText("Error"),
            content,
            getContainer: () =>
                getPopoverContainer(okButtonRef.current[filterIdx]),
            okText: getTranslatedText("OK")
        });
    };

    const checkDateRangeIfCorrect = (fromDate: string, toDate: string) => {
        if (fromDate && toDate) {
            return moment(fromDate).isAfter(moment(toDate));
        } else {
            return false;
        }
    };

    /**
     * Function for the Amount filters - mostly with dropdown.
     * @param filterStateName - name of state for filter
     * @param filterName - name of filter
     * @param options - options for dropdown
     */
    const createSelectMultipleAmountPopoverWithTag = (
        filterStateName: string | undefined,
        filterName: string,
        options: any
    ) => {
        const stateName = filterStateName || filterName;

        const searchFilterValues = get(searchFilters, stateName);

        let defaultAmountType: any, defaultAmountOperator: any;
        let defaultAmountValue: number = get(
            searchFilterValues,
            'AmountValue',
            0
        );

        const currencyObj = props.formatToParts(1111);
        const currencySign = get(
            filter(currencyObj, ['type', 'currency']),
            `0.value`,
            '$'
        );

        return (
            <>
                <ProtectedPopoverButton
                    containerRef={filterBarContainerRef}
                    popoverVisible={showConditions[stateName]}
                    popoverOnVisibleChange={(visible: boolean) => {
                        if (visible) {
                            changeConditionVisibility(visible, stateName);
                        }
                    }}
                    popoverPlacement="bottomLeft"
                    popoverContent={
                        <div className="pop-action-content">
                            <div>
                                {map(
                                    options,
                                    (optionSelect: any, index: number) => {
                                        const keyName = get(
                                            keys(optionSelect),
                                            0
                                        );
                                        const valuesArray = get(
                                            values(optionSelect),
                                            0
                                        );

                                        const firstOption = head(valuesArray);
                                        const valueIsObject =
                                            isObject(firstOption);
                                        const defaultValue = valueIsObject
                                            ? get(firstOption, 'value')
                                            : firstOption;

                                        const filterSelectValue = get(
                                            searchFilters,
                                            `${stateName}.${keyName}`,
                                            defaultValue
                                        );

                                        const optionName = get(
                                            keys(optionSelect),
                                            0
                                        );

                                        if (optionName === 'AmountType') {
                                            defaultAmountType =
                                                filterSelectValue;
                                        } else if (
                                            optionName === 'AmountOperator'
                                        ) {
                                            defaultAmountOperator =
                                                filterSelectValue;
                                        }

                                        return (
                                            <div key={index}>
                                                <Select
                                                    className="mb-10"
                                                    onChange={(value: any) => {
                                                        changeSearchFilter(
                                                            stateName,
                                                            {
                                                                ...searchFilterValues,
                                                                [keyName]:
                                                                    value,
                                                            }
                                                        );
                                                    }}
                                                    value={filterSelectValue}
                                                    getPopupContainer={populatePopupContainer()}
                                                >
                                                    {map(
                                                        valuesArray,
                                                        (option: any) => {
                                                            let value, label;
                                                            if (
                                                                isObject(option)
                                                            ) {
                                                                value = get(
                                                                    option,
                                                                    'value'
                                                                );
                                                                label = get(
                                                                    option,
                                                                    'label'
                                                                );
                                                            } else {
                                                                value = option;
                                                                label = option;
                                                            }

                                                            return (
                                                                <Option
                                                                    key={value}
                                                                    value={
                                                                        value
                                                                    }
                                                                >
                                                                    {label}
                                                                </Option>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                            <div>
                                 {`${defaultAmountType === 'Percentage' ? '%' : currencySign} `}
                                <InputNumber
                                    value={defaultAmountValue}
                                    // min={0}
                                    onChange={(value: number | undefined) => {
                                        changeSearchFilter(stateName, {
                                            ...searchFilterValues,
                                            AmountValue: value,
                                        });
                                    }}
                                />
                            </div>
                            <div className="ok-button-container">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        if (
                                            !(
                                                (
                                                    get(
                                                        searchFilterValues,
                                                        'AmountType',
                                                        defaultAmountType
                                                    ) &&
                                                    get(
                                                        searchFilterValues,
                                                        'AmountOperator',
                                                        defaultAmountOperator
                                                    )
                                                )
                                                //  &&
                                                // get(
                                                //     searchFilterValues,
                                                //     'AmountValue',
                                                //     defaultAmountValue
                                                // ) > -1
                                            )
                                        ) {
                                            updateModalFilterRequired(
                                                filterName
                                            );
                                        } else {
                                            updateFiltersSelect(
                                                'amount-filter',
                                                stateName,
                                                {
                                                    AmountType:
                                                        defaultAmountType,
                                                    AmountOperator:
                                                        defaultAmountOperator,
                                                    AmountValue:
                                                        defaultAmountValue,
                                                }
                                            );
                                        }
                                    }}
                                >
                                    {getTranslatedText("Ok")}
                                </Button>
                            </div>
                        </div>
                    }
                    popoverTrigger="click"
                    buttonType="link"
                    buttonRestProps={{
                        onClick: () => {
                            if (showConditions[stateName]) {
                                changeConditionVisibility(false, stateName);
                            }
                        },
                    }}
                    buttonContent={
                        <div>
                            <span>{getTranslatedText(filterName)}</span>
                            <FontAwesome icon={['fas', 'sort-down']} />
                        </div>
                    }
                />
                {showConditions[`${stateName}FilterTags`] && (
                    <>
                        <br />
                        <Tag
                            className="tag-ws"
                            color={
                                isUndefined(
                                    newlyAddedFilters[
                                    `${stateName}${appliedFilterIndicator}`
                                    ]
                                )
                                    ? 'grey'
                                    : ''
                            }
                            closable
                            onClose={() => removeSearchFilter(stateName)}
                        >
                            {
                                searchFilters[
                                `${stateName}${appliedFilterIndicator}`
                                ]
                            }
                        </Tag>
                    </>
                )}
            </>
        );
    };
    
    /**
     * Function that controls whta type of filter to render and how it will be rendered.
     * @param filter - filter object
     */
    const renderPopoverBasedOnFilter = (filter: DynamicObject) => {
        const {
            filterElement,
            filterName,
            filterStateName,
            filterPlaceholder,
            filterQuery,
            filterSort,
            filterResponse,
            filterLabelField,
            filterOptions,
            filterOptionsCustomTypes,
            filterLoading,
            filterMaxDate,
            filterNameQuery,
            filterNameQuerySub,
            filterSubChecking,
            filterJSONValueFieldSub,
            filterMappingUsed,
            filterCustomError,
            filterType,
            extraQueryVariables
        } = filter;
        const tagType = get(filter, 'tagType', '');
        const tagClass = get(filter, 'tagClass', '');

        if (filterElement === 'input') {
            return createFilterPopoverTextWithTag(filterStateName, filterName, filterType);
        } else if (filterElement === 'input-autocomplete') {
            return createFilterPopoverAutoCompleteWithTag(
                filterStateName,
                filterName,
                filterQuery,
                filterNameQuery,
                filterSort,
                filterResponse,
                filterLabelField,
                extraQueryVariables
            );
        } else if (filterElement === 'select-with-search') {
            return createFilterPopoverSelectSearchWithTag(
                filterStateName,
                filterName,
                filterQuery,
                filterNameQuery,
                filterSort,
                filterResponse,
                filterLabelField,
                filterNameQuerySub,
                filterSubChecking,
                filterJSONValueFieldSub,
                tagClass,
                filterMappingUsed,
                filterCustomError,
                extraQueryVariables
            );
        } else if (filterElement === 'input-checkbox-group') {
            return createFilterPopoverTextCheckboxGroupWithTag(
                filterStateName,
                filterName,
                filterOptions
            );
        } else if (filterElement === 'select-checkbox-group') {
            return createFilterPopoverSelectCheckboxGroupWithTag(
                filterStateName,
                filterName,
                filterOptions
            );
        } else if (filterElement === 'checkbox-group') {
            return createFilterPopoverCheckboxGroupWithTag(
                filterStateName,
                filterName,
                filterOptions,
                tagType,
                filterLoading,
                filterStateName === 'CustomerFirstLetter' ? false : true
            );
        } else if (filterElement === 'checkbox-group-tree') {
            return createFilterPopoverCheckboxGroupTreeWithTag(
                filterStateName,
                filterName,
                filterOptions,
                filterLoading
            );
        } else if (filterElement === 'select') {
            return createFilterPopoverSelectWithTag(
                filterStateName,
                filterName,
                filterOptions,
                filterOptionsCustomTypes,
                filterMaxDate
            );
        } else if (filterElement === 'radio-group') {
            return createFilterPopoverRadioGroupWithTag(
                filterStateName,
                filterName,
                filterPlaceholder,
                filterOptions
            );
        } else if (filterElement === 'select-multiple-and-input-amount') {
            return createSelectMultipleAmountPopoverWithTag(
                filterStateName,
                filterName,
                filterOptions
            );
        } else if (filterElement === 'input-multiple-tag') {
           return createFilterPopoverMultipleInputTextWithTag(
                filterStateName, 
                filterName, 
                filterType
            );
        }
    };

    /**
     * Function that controls the section for filter bar.
     * Where each of the filter item is populated.
     */
    const populateFilters = () => {
        const filtersDivElement = map(props.filters, (filter: any) => {
            const offset = get(filter, 'offset', 0);
            const { span, filterName } = filter;
            return (
                <Col
                    xl={{
                        span: span || props.colDivision,
                        offset,
                    }}
                    lg={6}
                    md={8}
                    sm={12}
                    xs={24}
                    key={filterName}
                >
                    <Row>
                        <Col span={24}>
                            {renderPopoverBasedOnFilter(filter)}
                        </Col>
                    </Row>
                </Col>
            );
        });

        return filtersDivElement;
    };

    const populateInvoiceCustomFieldProperties = () => {
        let result: any = {
            filterQuery: 'GET_INVOICE_CUSTOM_FIELD_VALUES',
            filterResponse: 'GetInvoiceCustomFieldValues',
            extraQueryVariables: undefined
        };

        if (props.isOrgView) {
            result.filterQuery = 'GET_ORGANISATION_INVOICE_CUSTOM_FIELD_VALUES';
            result.filterResponse = 'GetOrganisationInvoiceCustomFieldValues';
            result.extraQueryVariables = {
                CompanyIds: props.companyIds
            };
        }

        return result;
    };

    const populateCustomerCustomFieldProperties = () => {
        let result: any = {
            filterQuery: 'GET_CUSTOMER_CUSTOM_FIELD_VALUES',
            filterResponse: 'GetCustomerCustomFieldValues',
            extraQueryVariables: undefined
        };

        if (props.isOrgView) {
            result.filterQuery = 'GET_ORGANISATION_CUSTOMER_CUSTOM_FIELD_VALUES';
            result.filterResponse = 'GetOrganisationCustomerCustomFieldValues';
            result.extraQueryVariables = {
                CompanyIds: props.companyIds
            };
        }

        return result;
    };

    const populateCreditCustomFieldProperties = () => {
        let result: any = {
            filterQuery: 'GET_CREDIT_CUSTOM_FIELD_VALUES',
            filterResponse: 'GetCreditCustomFieldValues',
            extraQueryVariables: undefined
        };

        if (props.isOrgView) {
            result.filterQuery = 'GET_ORGANISATION_CREDIT_CUSTOM_FIELD_VALUES';
            result.filterResponse = 'GetOrganisationCreditCustomFieldValues';
            result.extraQueryVariables = {
                CompanyIds: props.companyIds
            };
        }

        return result;
    };

    /**
     * Function that populates the custom fields filters section.
     */
    const populateCustomFieldsFilters = () => {
        const customFieldsFiltersDivElement = map(
            props.customFieldsFilters,
            ({
                Type,
                Number: CFNumber,
                FieldName,
                children
            }: CompanyCustomFieldConfigure) => {
                let filterQuery = '';
                const filterSort = JSON.stringify({
                    Type: Type,
                    Name: FieldName,
                });
                let filterResponse = '';
                let extraQueryVariables = undefined;
                if (Type === CUSTOM_FIELD_TYPES.CUSTOMER) {
                    const customFieldProperties = populateCustomerCustomFieldProperties();
                    filterQuery = customFieldProperties.filterQuery;
                    filterResponse = customFieldProperties.filterResponse;
                    extraQueryVariables = customFieldProperties.extraQueryVariables
                } else if (Type === CUSTOM_FIELD_TYPES.INVOICE) {
                    const customFieldProperties = populateInvoiceCustomFieldProperties();
                    filterQuery = customFieldProperties.filterQuery;
                    filterResponse = customFieldProperties.filterResponse;
                    extraQueryVariables = customFieldProperties.extraQueryVariables
                } else if (Type === CUSTOM_FIELD_TYPES.CREDIT) {
                    const customFieldProperties = populateCreditCustomFieldProperties();
                    filterQuery = customFieldProperties.filterQuery;
                    filterResponse = customFieldProperties.filterResponse;
                    extraQueryVariables = customFieldProperties.extraQueryVariables
                }
                const filterNameQuery = FieldName;

                return (
                    <Col
                        key={`${Type}-${CFNumber}-${FieldName}`}
                    >
                        <Row>
                            <Col span={24}>
                                {   
                                    children ?
                                    createFilterPopoverCheckboxGroupWithTag(
                                        `${customFieldIndicator}${Type}--${FieldName}`,
                                        FieldName,
                                        children,
                                        "",
                                        false,
                                        false
                                    )
                                    : 
                                    createFilterPopoverAutoCompleteWithTag(
                                        `${customFieldIndicator}${Type}--${FieldName}`,
                                        FieldName,
                                        filterQuery,
                                        filterNameQuery,
                                        filterSort,
                                        filterResponse,
                                        customFieldIndicator,
                                        extraQueryVariables
                                    )
                                }
                            </Col>
                        </Row>
                    </Col>
                );
            }
        );
        
        return customFieldsFiltersDivElement;
    };

    /**
     * Function called when `Apply filters` button is clicked.
     */
    const applyFilters = () => {
        hasNewAppliedFilter = true;
        const filters: any = {};
        forEach(searchFilters, (filterValue: any, filterName: string) => {
            if (includes(filterName, appliedFilterIndicator)) {
                filters[filterName] = filterValue;

                const filterNameRaw = filterName.replace(
                    appliedFilterIndicator,
                    ''
                );

                if (typeof filterValue === 'object' && React.isValidElement(filterValue)) {
                    if (includes(dateTypeFilterStateNames, filterNameRaw)) {
                        const filterTypeName = filterNameRaw;
                        // filters[`${filterTypeName}Min`] = minDate;
                        // filters[`${filterTypeName}Max`] = maxDate;
                        filters[`${filterTypeName}${getMinMaxIndicator}`] =
                            true;
                    }
                    if (filterNameRaw === 'Amount') {
                        const amountFilterValues = searchFilters[filterNameRaw];
                        forEach(
                            amountFilterValues,
                            (value: any, keyName: string) => {
                                filters[keyName] = value;
                            }
                        );
                        filters[filterNameRaw] = amountFilterValues;
                    } else {
                        filters[filterNameRaw] = searchFilters[filterNameRaw];
                    }
                } else if (includes(selectUserTypeFilterStateNames, filterNameRaw)) {
                    const filterAppliedValue = get(
                        searchFilters,
                        `${filterNameRaw}${appliedFilterIndicator}`
                    );
                    if (filterAppliedValue) {
                        if (
                            props.checkingForAssignedUser &&
                            props.checkingForAssignedUser(filterAppliedValue) &&
                            !checkIsValidJsonString(filterAppliedValue)
                        ) {
                            filters[filterNameRaw] = filterAppliedValue;
                        } else {
                            const parsedValue = JSON.parse(filterAppliedValue);
                            filters[filterNameRaw] =
                                get(parsedValue, filterNameRaw) ||
                                get(
                                    parsedValue,
                                    get(
                                        selectUserFilterMapping,
                                        filterNameRaw,
                                        '--'
                                    )
                                );
                        }
                    }
                } else if (includes(selectActionedByUserIdFilterStateNames, filterNameRaw)) {
                    let filterAppliedValue = get(
                        searchFilters,
                        `${filterNameRaw}${appliedFilterIndicator}`
                    );
                    if (filterAppliedValue) {
                        if (checkIfEmailIsValid(filterAppliedValue) && !checkIsValidJsonString(filterAppliedValue)) {
                            filters[filterNameRaw] = filterAppliedValue;
                        } else {
                            const parsedValue = JSON.parse(filterAppliedValue);
                            filters[filterNameRaw] =
                                get(parsedValue, filterNameRaw) ||
                                get(
                                    parsedValue,
                                    get(
                                        (selectActionedByUserIdFilterMapping),
                                        filterNameRaw,
                                        '--'
                                    )
                                );
                        }
                    }
                } else if (includes(selectCompanyIdFilterStateNames, filterNameRaw)) {
                    const filterAppliedValue = get(
                        searchFilters,
                        `${filterNameRaw}${appliedFilterIndicator}`
                    );
                    if (filterAppliedValue) {
                        const parsedValue = JSON.parse(filterAppliedValue);
                        filters[filterNameRaw] =
                            get(parsedValue, filterNameRaw) ||
                            get(
                                parsedValue,
                                get(
                                    selectCompanyIdFilterMapping,
                                    filterNameRaw,
                                    '--'
                                )
                            );
                    }
                } else {
                    filters[filterNameRaw] = filterValue;
                }
            }
        });
        updateShowConditionsObject({
            applyFiltersButtonGroup: false,
        });
        props.applyFilters(filters, true);
    };

    /**
     * Function called for showing the saving view modal for the currently applied filters.
     */
    const handleShowSaveViewModal = () => {
        updateSaveModalConditionsObject({
            showModal: true,
        });
    };

    /**
     * Function called for hiding the saving view modal for the currently applied filters.
     */
    const handleHideSaveViewModal = () => {
        updateSaveModalConditionsObject({
            showModal: false,
        });
        setSaveModalFormInitialValues({});
    };

    /**
     * Function called when clicking on the `Save` button in save view modal form.
     * @param values - values needed as payload when saving the view (only name and type)
     */
    const saveView = (values: DynamicObject) => {
        const payload = { ...values };
        // const viewName = get(values, 'Name');
        // if (props.doesViewNameExist && props.doesViewNameExist(viewName)) {
        //     confirm({
        //         className: 'modal-swapped-buttons',
        //         title: 'Do you want to continue?',
        //         content: (
        //             <div>
        //                 View with the name <b className="red">{viewName}</b>{' '}
        //                 already exists. Proceeding will overwrite that existing
        //                 view if you're the one who created it.
        //             </div>
        //         ),
        //         onOk: () => saveViewActionCall(payload),
        //         okText: confirmModalOkText,
        //         cancelText: confirmModalCancelText,
        //     });
        // } else {
        saveViewActionCall(payload);
        // }
    };

    /**
     * Function called when saving the new page filter.
     * @param payload
     */
    const saveViewActionCall = (payload: DynamicObject) => {
        const filterValuesUsed: DynamicObject = {
            tableSortState: props.tableSortState || {},
        };
        forEach(props.filterValues, (filterValue, filterName) => {
            const filterValueHTML =
                includes(filterName, appliedFilterIndicator) &&
                    React.isValidElement(filterValue)
                    ? renderToStaticMarkup(filterValue)
                    : filterValue;

            filterValuesUsed[filterName] = filterValueHTML;
        });
        payload.FilterState = filterValuesUsed
            ? JSON.stringify(filterValuesUsed)
            : '';

        payload.PageName = props.pageName;

        updateSaveModalConditionsObject({
            showSubmitLoading: true,
            showModal: false,
        });
        setSaveModalFormInitialValues(payload);

        dispatch(
            saveAppliedFiltersViewAction(
                payload,
                ({ IsSuccess, Messages }: ResponseModalObject) => {
                    saveViewResponseModal(
                        IsSuccess,
                        Messages,
                        get(payload, 'Name')
                    );
                    if (props.pageName) {
                        dispatch(
                            updatePageViewsSelectedAction(
                                props.pageName,
                                filterValuesUsed
                            )
                        );
                    }
                }
            )
        );
    };

    /**
     * Function for populating the response modal after Save view action API call has finished.
     * @param IsSuccess
     * @param Messages
     * @param lastSavedViewName
     */
    const saveViewResponseModal = (
        IsSuccess: boolean,
        Messages: string[] | undefined,
        lastSavedViewName: string | undefined
    ) => {
        updateSaveModalConditionsObject({
            showSubmitLoading: false,
            showModal: false,
        });
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText("Success"),
                content: getTranslatedText("View saved successfully"),
                onOk: () => {
                    handleHideSaveViewModal();
                    setSaveModalFormInitialValues({});
                    dispatch(
                        updateRefetchPageViewsAction(true, lastSavedViewName)
                    );
                },
                okText: getTranslatedText("OK")
            });
        } else {
            let errorMessageContent:
                | string
                | JSX.Element[] = getTranslatedText(`Failed to save the view!`);
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText("Error"),
                content: errorMessageContent,
                onOk: () => {
                    updateSaveModalConditionsObject({
                        showModal: true,
                    });
                },
                okText: getTranslatedText("OK")
            });
        }
    };

    /**
     * Function called when `Cancel` button is clicked.
     * Resets all the existing filters (including those that are applied or in redux).
     */
    const cancelAllFilters = () => {
        const newFiltersObject: any = {};
        let hasAppliedFilters = false;
        forEach(props.filterValues, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasAppliedFilters = true;
            }
        });

        forEach(searchFilters, (_filterValue: any, filterName: string) => {
            newFiltersObject[filterName] = undefined;
        });

        setSearchFilters({
            ...newFiltersObject,
        });

        if (hasAppliedFilters) {
            // call and reset the applied filters
            props.applyFilters();
        } else {
            return closeFilterBar();
        }
        const newConditionsObject: any = {};
        forEach(
            showConditions,
            (_conditionValue: boolean, conditionName: string) => {
                newConditionsObject[conditionName] = false;
            }
        );
        updateShowConditionsObject({
            ...newConditionsObject,
        });
    };

    /**
     * Function called when closing the filter bar.
     */
    const closeFilterBar = () => {
        props.closeFilterBar();
    };

    let hasFilterTags = false;
    forEach(
        showConditions,
        (conditionValue: boolean, conditionName: string) => {
            if (
                includes(conditionName, 'FilterTags') &&
                conditionName !== 'applyFiltersButtonGroup'
            ) {
                if (conditionValue) {
                    hasFilterTags = true;
                }
            }
        }
    );

    const getNewFilterValues = () => {
        if (!hasNewAppliedFilter) return;
        if (filterTimeoutHandler) clearTimeout(filterTimeoutHandler);

        filterTimeoutHandler = setTimeout(() => {
            const freshFilters: DynamicObject = {};
            forEach(searchFilters, (sf, sfIdx) => {
                if (includes(sfIdx, appliedFilterIndicator)) {
                    return;
                }
                const filterApplied = get(props.filterValues, sfIdx);

                if (!isEqual(filterApplied, sf)) {
                    let skipFreshFilter = false;
                    if (includes(selectUserTypeFilterStateNames, sfIdx)) {
                        let usedExistingValue = '';
                        if (
                            props.checkingForAssignedUser &&
                            props.checkingForAssignedUser(sf) &&
                            !checkIsValidJsonString(sf)
                        ) {
                            usedExistingValue = sf;
                        } else {
                            const parsedSf = sf ? JSON.parse(sf) : {};
                            usedExistingValue = get(
                                parsedSf,
                                get(selectUserFilterMapping, sfIdx)
                            );
                        }

                        if (
                            filterApplied &&
                            filterApplied === usedExistingValue
                        ) {
                            skipFreshFilter = true;
                        }
                    }
                    if (includes(selectActionedByUserIdFilterStateNames, sfIdx)) {
                        let usedExistingValue = '';
                        if (
                            checkIfEmailIsValid(sf) &&
                            !checkIsValidJsonString(sf)
                        ) {
                            usedExistingValue = sf;
                        } else {
                            const parsedSf = sf ? JSON.parse(sf) : {};
                            usedExistingValue = get(
                                parsedSf,
                                get(selectActionedByUserIdFilterMapping, sfIdx)
                            );
                        }

                        if (
                            filterApplied &&
                            filterApplied === usedExistingValue
                        ) {
                            skipFreshFilter = true;
                        }
                    }
                    if (includes(selectCompanyIdFilterStateNames, sfIdx)) {
                        const parsedSf = sf ? JSON.parse(sf) : {};
                        const usedExistingValue = get(
                            parsedSf,
                            get(selectCompanyIdFilterMapping, sfIdx)
                        );

                        if (
                            filterApplied &&
                            filterApplied === usedExistingValue
                        ) {
                            skipFreshFilter = true;
                        }
                    }
                    if (!skipFreshFilter)
                        freshFilters[`${sfIdx}${appliedFilterIndicator}`] = sf;
                }
            });

            setNewlyAddedFilters(freshFilters);
            hasNewAppliedFilter = false;
        }, TIME_DELAY_LISTENER_FILTER_UPDATES);
    };

    useEffect(getNewFilterValues, [
        hasNewAppliedFilter,
        props.filterValues,
        searchFilters,
    ]);

    /**
     * Function that checks the applied view and sets variable indicators for comparing.
     */
    const checkAppliedViewDifference = () => {
        if (isUndefined(lastAppliedView)) {
            lastAppliedView = props.appliedView;
            return;
        }

        if (props.appliedView !== lastAppliedView) {
            hasNewAppliedFilter = true;
        }
    };

    useEffect(checkAppliedViewDifference, [props.appliedView]);

    return (
        <Row id="filter-bar-container" className="filter-bar" type="flex">
            <Col className="fx-1">
                <QueueAnim type="top" duration={300} leaveReverse>
                    <Row key="filters" type="flex" align="top">
                        {populateFilters()}
                    </Row>
                    <Row
                        key="custom-fields-filters"
                        type="flex"
                        align="top"
                        gutter={50}
                    >
                        {populateCustomFieldsFilters()}
                    </Row>
                    {(showConditions.applyFiltersButtonGroup ||
                        hasFilterTags) && (
                            <Row key="button-group-filter">
                                <Col span={24} className="mt-8">
                                    <Button
                                        type="primary"
                                        className="mr-10"
                                        onClick={applyFilters}
                                        loading={props.loading}
                                        disabled={isEmpty(newlyAddedFilters)}
                                    >
                                        {getTranslatedText("Apply filters")}
                                    </Button>
                                    {props.pageName && (
                                        <Button
                                            className="mr-10"
                                            type="primary"
                                            ghost
                                            onClick={handleShowSaveViewModal}
                                            loading={props.loading}
                                            disabled={saveViewBtnDisabled}
                                        >
                                            {getTranslatedText("Save view")}
                                        </Button>
                                    )}
                                    <Button
                                        disabled={props.loading}
                                        onClick={cancelAllFilters}
                                    >
                                    {getTranslatedText("Cancel")}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                </QueueAnim>
            </Col>
            <Col className="close-section">
                <Button type="link" onClick={cancelAllFilters}>
                    <FontAwesome icon={['fas', 'times-circle']} />
                </Button>
            </Col>
            {modalFilterRequired && (
                <Suspense fallback={null}>
                    <FilterRequiredModal
                        modalFilterRequired={modalFilterRequired}
                        handleOkClick={() => updateModalFilterRequired('')}
                    />
                </Suspense>
            )}
            {saveModalConditions.showModal && (
                <Suspense fallback={null}>
                    <SaveViewModal
                        visible={saveModalConditions.showModal}
                        handleSave={saveView}
                        handleCancel={handleHideSaveViewModal}
                        containerRef={filterBarContainerRef}
                        formInitialValue={saveModalFormInitialValues}
                    />
                </Suspense>
            )}
            {saveModalConditions.showSubmitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Saving view")}
                        modalVisible={saveModalConditions.showSubmitLoading}
                        displayMessage={getTranslatedText("Please wait while saving the view. . .")}
                        containerRef={filterBarContainerRef}
                    />
                </Suspense>
            )}
        </Row>
    );
};

export default memo(withNumberFormatHandler(withDateFormatHandler(FilterBar)));