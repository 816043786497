import API, { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';
import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import { CREDITS_PAGE, DETAILS_TAB } from '../../config/tableAndPageConstants';
import queries from '../../graphql/queries.graphql';
import {
    checkShouldRequestRefetch,
    getCompanyFlagValue,
    getRegionallyGraphqlResponse,
    getSortFieldsWithCustomFields,
    removeAppliedFiltersForApiRequest,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    getCreditConversationErrorAction,
    getCreditConversationSuccessAction,
    getCreditDataErrorAction,
    getCreditDataSuccessAction,
    getCreditInvoicesErrorAction,
    getCreditInvoicesSuccessAction,
    getCreditsErrorAction,
    getCreditsRequestAction,
    getCreditsSuccessAction,
    setCreditSelectedIdSuccessAction,
    getCreditChangesSuccessAction,
    getCreditChangesErrorAction,
    getCreditsByCreditCodesErrorAction,
    getCreditsForOrganisationRequestAction,
    creditsFetchedForOrganisationAction,
    getRebatesRequestAction,
    getRebatesErrorAction,
    getRebatesSuccessAction
} from './actions';
import { Credit, CreditsActionTypes, CreditsFetchedData } from './types';
import { AtbViewType } from '../../constants/settings';
import { store } from '../..';
import { getOrganisationActionUrl } from '../organisations/sagas';

export const getCreditData = (state: ApplicationState) =>
    state.credits.activeData;

export const getCreditSelectedId = (state: ApplicationState) =>
    state.credits.activeData.selectedId;

export const getCustomerUILabel = (state: ApplicationState) =>
    get(state.companies.selectedUserCompany, 'Company.CustomerTitleLabel') ||
    'customer';

export const getCompanyIdByCreditSelectedId = (state: ApplicationState) => {
    const creditSelectedId = state.credits.activeData.selectedId;
    const creditSelected = state.credits.data.find(c => c.Id === creditSelectedId) as any;

    return creditSelected ? creditSelected.CompanyId : undefined;
}

let refetchCount = 0;
/**
 * Function that calls the API for fetching the credit list.
 * @param param0
 */
function* handleGetCreditsRequest({ payload }: any) {
    const errorMessage = 'Error fetching credits list. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            creditState,
            isUsingCloudImportType,
            excludeCredits
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);
        const sortObject = getSortFieldsWithCustomFields(sortBy);

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CREDITS_FOR_COMPANY, {
                ...cleanFilters,
                CreditState: creditState,
                // SortField: sortBy,
                ...sortObject,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * CREDITS_PAGE.pageSize,
                IsCloudImportType: isUsingCloudImportType,
                ExcludeCredits: excludeCredits
            })
        );

        const { Credits } = get(res.data, 'GetCreditsForCompany');
        const responsePayload = {
            data: Credits,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage:
                    !(Credits.length < pageSize) &&
                    !(pageSize < CREDITS_PAGE.pageSize),
            },
        };

        refetchCount = 0;
        yield put(getCreditsSuccessAction(responsePayload));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getCreditsRequestAction(payload));
        } else {
            yield put(getCreditsErrorAction([errorMessage]));
        }
    }
}

function* handleGetCreditsForOrganisationRequest({ payload: sagaPayload }: any) {
    let { refetchCount, ...payload } = sagaPayload;
    refetchCount = refetchCount || 0;
    const errorMessage = 'Error fetching credits list. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            creditState,
            isUsingCloudImportType,
            excludeCredits,
            region,
            conversionCurrency,
            organisationCurrencies,
            companyId
        } = payload;
        
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);
        const sortObject = getSortFieldsWithCustomFields(sortBy);
        
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_CREDITS_FOR_ORGANISATION,
            {
                ...cleanFilters,
                CreditState: creditState,
                // SortField: sortBy,
                ...sortObject,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * CREDITS_PAGE.pageSize,
                IsCloudImportType: isUsingCloudImportType,
                ExcludeCredits: excludeCredits,
                ConversionCurrency: conversionCurrency,
                OrganisationCurrencies: organisationCurrencies,
                CompanyId: companyId
            }
        );

        const { Credits } = get(res.data, 'GetCreditsForOrganisation');
        const responsePayload = {
            data: Credits,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage:
                    !(Credits.length < pageSize) &&
                    !(pageSize < CREDITS_PAGE.pageSize),
            },
        };

        refetchCount = 0;
        yield put(getCreditsSuccessAction(responsePayload));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getCreditsForOrganisationRequestAction({ ...payload, refetchCount }));
        } else {
            yield put(getCreditsErrorAction([errorMessage]));
        }
    }
}

/**
 * Function that calls the API for fetching the credit list.
 * @param param0
 */
function* handleGetRebatesRequest({ payload }: any) {
    const errorMessage = 'Error fetching rebates list. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            creditState,
            isUsingCloudImportType,
            excludeCredits
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);
        const sortObject = getSortFieldsWithCustomFields(sortBy);

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CREDITS_FOR_COMPANY, {
                ...cleanFilters,
                CreditState: creditState,
                ...sortObject,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * CREDITS_PAGE.pageSize,
                IsCloudImportType: isUsingCloudImportType,
                ExcludeCredits: excludeCredits
            })
        );

        const { Credits } = get(res.data, 'GetCreditsForCompany');
        const responsePayload = {
            data: Credits,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage:
                    !(Credits.length < pageSize) &&
                    !(pageSize < CREDITS_PAGE.pageSize),
            },
        };

        refetchCount = 0;
        yield put(getRebatesSuccessAction(responsePayload));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getRebatesRequestAction(payload));
        } else {
            yield put(getRebatesErrorAction([errorMessage]));
        }
    }
}

/**
 * Function calling the API for fetching the credit data based on the given id.
 * @param param0
 */
function* handleGetCreditDataRequest({
    payload: { creditId, isUsingCloudImportType },
}: any) {
    const errorMessage =
        'Error fetching credit details. Please try again later.';
    try {
        const isCalendarView: boolean = yield select(
            (state: ApplicationState) => getCompanyFlagValue(state, AtbViewType.CalendarView)
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CREDIT_DETAILS_FOR_COMPANY, {
                CreditId: creditId,
                IsCloudImportType: isUsingCloudImportType,
                IsCalendarView: isCalendarView
            })
        );

        const Credit = get(res.data, 'GetCreditDetailsForCompany');

        if (Credit) {
            const responsePayload = {
                record: Credit,
            };

            yield put(getCreditDataSuccessAction(responsePayload));
        } else {
            yield put(getCreditDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCreditDataErrorAction([errorMessage]));
    }
}

function* handleGetOrganisationCreditDataRequest({
    payload: {
        creditId,
        region,
        isUsingCloudImportType,
        companyId,
        conversionCurrency,
        currencies,
    },
}: any) {
    const errorMessage =
        'Error fetching credit details. Please try again later.';
    try {
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );

        const isCalendarView: boolean = yield select(
            (state: ApplicationState) => getCompanyFlagValue(state, AtbViewType.CalendarView)
        );
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_CREDIT_DETAILS_FOR_ORGANISATION,
            {
                CompanyId: companyId,
                CreditId: creditId,
                IsCloudImportType: isUsingCloudImportType,
                IsCalendarView: isCalendarView,
                ConversionCurrency: conversionCurrency,
                CompanyIds: [companyId],
                OrganisationCurrencies: currencies
            }
        );

        const Credit = get(res.data, 'GetCreditDetailsForOrganisation');

        if (Credit) {
            const responsePayload = {
                record: Credit,
            };

            yield put(getCreditDataSuccessAction(responsePayload));
        } else {
            yield put(getCreditDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCreditDataErrorAction([errorMessage]));
    }
}

/**
 * Function calling the API for fetching the credit data based on the given credit codes.
 * @param param0
 */
function* handleGetCreditsByCreditCodesRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    const errorMessage =
        'Error fetching credit details. Please try again later.';
    try {
        
        const { ...creditCodes } = payload;
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CREDITS_BY_CREDIT_CODES, {
                ...creditCodes
            })
        );

        const { Credits } = get(res.data, 'GetCreditsByCreditCodes');
        
        refetchCount = 0;
        if (callback) callback(Credits);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            //yield put(getCreditsByCreditCodesRequestAction(payload));
        } else {
            yield put(getCreditsByCreditCodesErrorAction([errorMessage]));
        }
    }
}

/**
 * Function that sets the selected creditId id for reference.
 * @param param0
 */
function* handleSetCreditSelectedIdRequest({ payload }: any) {
    const { creditId, callback } = payload;
    yield put(setCreditSelectedIdSuccessAction(creditId));
    if (callback) callback();
}

/**
 * Function that gets the invoices list for a certain credit.
 * @param param0
 */
function* handleGetCreditInvoicesRequest({ payload }: any) {
    const errorMessage = `Error fetching credit's invoice list. Please try again later.`;
    try {
        const {
            filters,
            invoiceState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_INVOICES_FOR_COMPANY, {
                ...cleanFilters,
                InvoiceState: invoiceState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.INVOICE_LIST_COMPLETE.pageSize,
            })
        );

        const { Invoices } = get(res.data, 'GetInvoicesForCompany');
        if (Invoices) {
            const responsePayload = {
                data: Invoices,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage: !(Invoices.length < pageSize),
                },
            };

            yield put(getCreditInvoicesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCreditInvoicesErrorAction([errorMessage]));
    }
}

/**
 * Function for fetching the conversation list of a given credit.
 * @param param0
 */
function* handleGetCreditConversationRequest({ payload }: any) {
    const errorMessage = `Error fetching credit's conversation list. Please try again later.`;
    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            undefined,
            true
        );

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CONVERSATION_LINES_FOR_COMPANY, {
                ...cleanFilters,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
            })
        );

        const { ConversationLines } = get(
            res.data,
            'GetConversationLinesForCompany'
        );
        const Conversation = ConversationLines;

        if (Conversation) {
            const responsePayload = {
                data: Conversation,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Conversation.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.CONVERSATION_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getCreditConversationSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCreditConversationErrorAction([errorMessage]));
    }
}

/**
 * Function for fetching the creditChanges list of a given credit.
 * @param param0
 */
function* handleGetCreditChangesRequest({ payload }: any) {
    const errorMessage = `Error fetching credit changes list. Please try again later.`;
    try {
        const { filters, pageSize, currentPage } = payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CHANGE_LINES_FOR_COMPANY, {
                ...cleanFilters,
                PageSize: pageSize,
                Skip:
                    currentPage * DETAILS_TAB.CREDIT_CHANGES_TIMELINE.pageSize,
            })
        );

        const { ChangeLines } = get(res.data, 'GetChangeLinesForCompany');

        // const res = yield call(
        //     [API, 'graphql'],
        //     graphqlOperation(queries.GET_CONVERSATION_LINES_FOR_COMPANY, {
        //         ...cleanFilters,
        //         SortField: sortBy,
        //         Ascending: sortAscending,
        //         PageSize: pageSize,
        //         Skip:
        //             currentPage * DETAILS_TAB.CREDIT_CHANGES_TIMELINE.pageSize,
        //     })
        // );

        // const { ConversationLines: CreditChanges } = get(
        //     res.data,
        //     'GetConversationLinesForCompany'
        // );

        if (ChangeLines) {
            const responsePayload = {
                data: ChangeLines,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(ChangeLines.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.CREDIT_CHANGES_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getCreditChangesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCreditChangesErrorAction([errorMessage]));
    }
}

/**
 * Function for fetching the creditChanges list of a given credit.
 * @param param0
 */
function* handleGetOrganisationCreditChangesRequest({
    payload: sagaPayload
}: any) {
    const errorMessage = `Error fetching credit changes list. Please try again later.`;
    const { payload, region, companyIds } = sagaPayload;

    try {
        const { filters, pageSize, currentPage } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);
        
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );

        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_CHANGE_LINES_FOR_ORGANISATION,
            {
                ...cleanFilters,
                CompanyIds: companyIds,
                PageSize: pageSize,
                Skip:
                    currentPage * DETAILS_TAB.CREDIT_CHANGES_TIMELINE.pageSize,
            }
        );

        const { ChangeLines } = get(res.data, 'GetChangeLinesForOrganisation');
        if (ChangeLines) {
            const responsePayload = {
                data: ChangeLines,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(ChangeLines.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.CREDIT_CHANGES_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getCreditChangesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCreditChangesErrorAction([errorMessage]));
    }
}

/**
 * Function for adding comment to the credit's conversation list.
 * @param param0
 */
function* handleCreditAddCommentRequest({ payload: sagaPayload }: any) {
    const { filter, creditIds, excludeCredits, comment, callback } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(filter, true);
    const payload = {
        CreditManagementFilter: {
            ...cleanFilters,
            CreditIds: creditIds,
            ExcludeCredits: excludeCredits,
        },
        Comment: comment,
    };

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/conversation/save/creditcomment',
            {
                body: payload,
            }
        );
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function for getting the reconciliation report of selected credits..
 * @param param0
 */
function* handleGetCreditsReconciliationReportRequest({
    payload: sagaPayload,
}: any) {
    const { startDate, endDate, callback } = sagaPayload;

    const payload = {
        StartDate: startDate,
        EndDate: endDate,
    };

    try {
        yield call([API, 'post'], API_NAME, '/report/reconcile', {
            body: payload,
        });
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function for getting the credit custom fields for a company.
 * @param param0
 */
function* handleGetCreditCustomFieldsForCompanyRequest({ payload }: any) {
    //const errorMessage = '';
    const { 
            CompanyId, 
            callback
        } = payload;
    try {
        //const errorMessage =
        //'Error fetching custom field values. Please try again later.';
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CREDIT_CUSTOM_FIELDS_FOR_COMPANY, {
                CompanyId: CompanyId,
            })
        );
        // const response = get(res.data, 'GetTaskViewerDetailForUser');
        const CustomFieldValues  = get(res.data, 'GetCreditCustomFieldsForCompany');
        
        if (callback) {
            CustomFieldValues.IsSuccess = true;
            callback(CustomFieldValues);
        }
    } catch (err) {
        if (callback) callback([]);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}
/*
 * Function for creating the credit manually.
 * @param param0
 */
function* handleCreateCreditManuallyRequest({ payload: sagaPayload }: any) {
    const { Credits, callback } = sagaPayload;
    
    const payload = {Credits};

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/credit/create-manually',
            {
                body: payload
            }
        );
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function for editing the credit manually.
 * @param param0
 */
function* handleEditCreditManuallyRequest({ payload: sagaPayload }: any) {
    const { Credit, callback } = sagaPayload;
    
    const payload = {Credit};

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/credit/edit-manually',
            {
                body: payload
            }
        );
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

function* handleDeleteCreditManuallyRequest({ payload: sagaPayload }: any) {
    const { filter, creditIds, excludeCredits, callback } =
        sagaPayload;

    const cleanFilters = removeAppliedFiltersForApiRequest(filter, true);

    if (cleanFilters.CustomFieldMultipleValuesFilters) {
        cleanFilters.CustomFieldMultipleValuesFilters = JSON.parse(
            cleanFilters.CustomFieldMultipleValuesFilters
        );
    }
    
    const payload = {
        CreditManagementFilter: {
            ...cleanFilters,
            CreditIds: creditIds,
            ExcludeCredits: excludeCredits,
        }
    };

    try {
        const apiResponse: DynamicObject = yield call(
            [API, 'del'],
            API_NAME,
            '/credit/delete-manually',
            {
                body: payload,
            }
        );
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: get(apiResponse, 'Messages')
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

function CreditsFetchedForOrganisationReceived(params: { pageSize: number, currentPage: number }) {
    return function (data: CreditsFetchedData) {
        store.dispatch(creditsFetchedForOrganisationAction({ data, ...params }));
    };
}

function* watchCreditsFetchedForOrganisationReceived() {
    yield takeLatest(CreditsActionTypes.CREDITS_FETCHED_FOR_ORGANISATION_RECEIVED, function* (action: any) {
        const { data, pageSize, currentPage } = action.payload;
        const credits: Credit[] = data.OnCreditsFetchedForOrganisation.Credits;

        const responsePayload = {
            data: credits,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage:
                    !(credits.length < pageSize) &&
                    !(pageSize < CREDITS_PAGE.pageSize),
            },
        };

        refetchCount = 0;
        yield put(getCreditsSuccessAction(responsePayload));
    });
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetCreditsRequest() {
    yield takeLatest(
        CreditsActionTypes.GET_CREDITS_REQUEST,
        handleGetCreditsRequest
    );
}

function* watchGetCreditsForOrganisationRequest() {
    yield takeLatest(
        CreditsActionTypes.GET_CREDITS_FOR_ORGANISATION_REQUEST,
        handleGetCreditsForOrganisationRequest
    );
}
function* watchGetRebatesRequest() {
    yield takeLatest(
        CreditsActionTypes.GET_REBATES_REQUEST,
        handleGetRebatesRequest
    );
}

function* watchGetCreditDataRequest() {
    yield takeLatest(
        CreditsActionTypes.GET_CREDIT_DATA_REQUEST,
        handleGetCreditDataRequest
    );
}

function* watchGetOrganisationCreditDataRequest() {
    yield takeLatest(
        CreditsActionTypes.GET_ORGANISATION_CREDIT_DATA_REQUEST,
        handleGetOrganisationCreditDataRequest
    );
}

function* watchGetCreditsByCreditCodesRequest() {
    yield takeLatest(
        CreditsActionTypes.GET_CREDITS_BY_CREDIT_CODES_REQUEST,
        handleGetCreditsByCreditCodesRequest
    );
}

function* watchSetCreditSelectedIdRequest() {
    yield takeLatest(
        CreditsActionTypes.SET_CREDIT_SELECTED_ID_REQUEST,
        handleSetCreditSelectedIdRequest
    );
}

function* watchGetCreditInvoicesRequest() {
    yield takeLatest(
        CreditsActionTypes.GET_CREDIT_INVOICES_REQUEST,
        handleGetCreditInvoicesRequest
    );
}

function* watchGetCreditConversationRequest() {
    yield takeLatest(
        CreditsActionTypes.GET_CREDIT_CONVERSATION_REQUEST,
        handleGetCreditConversationRequest
    );
}

function* watchGetCreditChangesRequest() {
    yield takeLatest(
        CreditsActionTypes.GET_CREDIT_CREDIT_CHANGES_REQUEST,
        handleGetCreditChangesRequest
    );
}

function* watchGetOrganisationCreditChangesRequest() {
    yield takeLatest(
        CreditsActionTypes.GET_ORGANISATION_CREDIT_CREDIT_CHANGES_REQUEST,
        handleGetOrganisationCreditChangesRequest
    );
}

function* watchCreditAddCommentRequest() {
    yield takeLatest(
        CreditsActionTypes.CREDIT_ADD_COMMENT_REQUEST,
        handleCreditAddCommentRequest
    );
}

function* watchGetCreditsReconciliationReportRequest() {
    yield takeLatest(
        CreditsActionTypes.GET_CREDITS_RECONCILIATION_REPORT_REQUEST,
        handleGetCreditsReconciliationReportRequest
    );
}

function* watchGetCreditCustomFieldsForCompanyRequest() {
    yield takeLatest(
        CreditsActionTypes.GET_CREDIT_CUSTOM_FIELDS_FOR_COMPANY_REQUEST,
        handleGetCreditCustomFieldsForCompanyRequest
        );
    }
function* watchCreateCreditManuallyRequest() {
    yield takeLatest(
        CreditsActionTypes.CREATE_CREDIT_MANUALLY_REQUEST,
        handleCreateCreditManuallyRequest
    );
}

function* watchEditCreditManuallyRequest() {
    yield takeLatest(
        CreditsActionTypes.EDIT_CREDIT_MANUALLY_REQUEST,
        handleEditCreditManuallyRequest
    );
}

function* watchDeleteCreditManuallyRequest() {
    yield takeLatest(
        CreditsActionTypes.DELETE_CREDIT_MANUALLY_REQUEST,
        handleDeleteCreditManuallyRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* creditsSaga() {
    yield all([
        fork(watchGetCreditsRequest),
        fork(watchGetCreditsForOrganisationRequest),
        fork(watchGetRebatesRequest),
        fork(watchGetCreditDataRequest),
        fork(watchGetOrganisationCreditDataRequest),
        fork(watchGetCreditsByCreditCodesRequest),
        fork(watchSetCreditSelectedIdRequest),
        fork(watchGetCreditInvoicesRequest),
        fork(watchGetCreditConversationRequest),
        fork(watchGetCreditChangesRequest),
        fork(watchGetOrganisationCreditChangesRequest),
        fork(watchCreditAddCommentRequest),
        fork(watchGetCreditsReconciliationReportRequest),
        fork(watchGetCreditCustomFieldsForCompanyRequest),
        fork(watchCreateCreditManuallyRequest),
        fork(watchEditCreditManuallyRequest),
        fork(watchDeleteCreditManuallyRequest),
        fork(watchCreditsFetchedForOrganisationReceived),
    ]);
}

export default creditsSaga;
