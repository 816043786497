/**
 * File responsible for all the UI and actions for Settings>Customer Payments page - `/app/settings/customer-payments`.
 */

 import {
    Button,
    Col,
    Divider,
    Form,
    Modal,
    Row,
    Spin,
    Switch,
    Typography,
} from 'antd';
import {
    difference,
    filter,
    get,
    includes,
    isEmpty,
    isUndefined,
    map,
    remove,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { RefObject, Suspense, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-quill/dist/quill.snow.css';
import { withRouter } from 'react-router-dom';
import ModalWithSpinner from '../../components/common/ModalWithSpinner';
import {
    paymentOptionsDataMapping,
} from '../../constants/notifications';
import { DynamicObject } from '../../utils/commonInterfaces';
import './settings.less';
import ActionBar from '../../components/common/ActionBar';
import FontAwesome from '../../components/common/FontAwesome';
import VirtualizedList from '../../components/common/VirtualizedList';
import { ApplicationState } from '../../store';
import { CompanyUserRole } from '../../store/companies/types';
import { computeTableScroll } from '../../utils/commonFunctions';
import { CUSTOMIZE_SMS_PROVIDER } from '../../config/tableAndPageConstants';
import SmsCustomizationItemComponent from '../../components/settings/SmsCustomizationItemComponent';
import { getCurrentUser } from '../../store/users/sagas';
import { CompanyIdAttribute, SubAttribute } from '../../constants/authUserAttributes';
import { confirmModalCancelText, confirmModalOkText } from '../../config/config';
import SmsProviderItemDetailsComponent from '../../components/settings/SmsProviderItemDetailsComponent';
import SmsProviderItemDetailsDrawerComponent from '../../components/settings/SmsProviderItemDetailsDrawerComponent';
import TokenCreateDrawerContent from '../../components/settings/TokenCreateDrawerContent';
import { RequestSaveCompanySmsCustomizationItemPayload, SmsCustomization, SmsCustomizationItem, SmsCustomizationState } from '../../store/smsProviders/types';
import { getSmsCustomizationProviderRequestAction, saveSmsCustomizationProviderRequestAction } from '../../store/smsProviders/actions';
import { getRolePermissions } from '../../store/roles/sagas';

const { Title } = Typography;
const { Item: FormItem } = Form;
interface IProps {
    form: DynamicObject;
    history: {
        push: (path: string) => void;
    };
}

const { confirm } = Modal;

let resetTableScroll = false;
let isRefetching = false;
let lastSelectedCompanyId: null | string = null;

const formFieldNames: DynamicObject = {
    EnableDefaultSmsProvider: 'EnableDefaultSmsProvider',
    EnableCustomizeSmsProvider: 'EnableCustomizeSmsProvider',
};

export const defaultCardPaymentClientRef = '[InvoiceList]';

const SmsCommunicationsProviderManagementPage: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();

    const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = props.form;

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const userRole = get(selectedUserCompany, 'Role.Name');
    
    const currentUser = useSelector(getCurrentUser);
    
    const rolePermissions = useSelector(getRolePermissions);

    // const smsCustomizationState: CompanySmsCustomization | undefined = selectedUserCompany.Company.CompanySmsCustomization;
    const smsCustomizationState: SmsCustomizationState = useSelector(
        (state: ApplicationState) => state.smsProviders
    );

    const [smsCustomization, setSmsCustomization] = useState<SmsCustomization>({
        IsCustomizeSms: false,
        SelectedItemId: '',
        Items: []
    });
    
    useEffect(() => {
        if (smsCustomizationState.data) {
            setSmsCustomization(smsCustomizationState.data);
        }
    }, [smsCustomizationState.data]);

    const handleDefaultProviderChange = (checked: boolean) => {
        setSmsCustomization(prevState => {
            return {
                ...prevState,
                IsCustomizeSms: !checked
            }
        });
        
        setFieldsValue({
            [formFieldNames.EnableDefaultSmsProvider]: checked,
            [formFieldNames.EnableCustomizeSmsProvider]: !checked
        });
    };
    
    const handleCustomProviderChange = (checked: boolean) => {
        setSmsCustomization(prevState => {
            return {
                ...prevState,
                IsCustomizeSms: checked
            }
        });

        setFieldsValue({
            [formFieldNames.EnableDefaultSmsProvider]: !checked,
            [formFieldNames.EnableCustomizeSmsProvider]: checked
        });
    };

    const handleAddSmsCustomizationItem = (item: SmsCustomizationItem) => {
        setSmsCustomization(prevState => {
            return {
                ...prevState,
                SelectedItemId: prevState.SelectedItemId ? prevState.SelectedItemId : item.Id,
                Items: prevState.Items ? [...prevState.Items, item] : [item]
            }
        });
      };

    const handleRemoveSmsCustomizationItem = () => {
        let keysToUses = [...tableRowSelection.selectedRowKeys];

        setSmsCustomization(prevState => {
            return {
                ...prevState,
                Items: prevState.Items.filter(item => !keysToUses.includes(item.Id))
            }
        });
        resetAllSelectedRowKeys();
    };

    const [showConditions, setShowConditions] = useState<{
        selectProvider: boolean;
        providerCreateDrawer: boolean;
    }>({
        selectProvider: false,
        providerCreateDrawer: false,
    });

    const [providerDetailsDrawerValues, setProviderDetailsDrawerValues] = useState<{
        visible: boolean;
        record: DynamicObject;
    }>({
        visible: false,
        record: {},
    });

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: any[];
    }>({
        selectedRowKeys: [],
    });

    /**
     * Fetch the SmsCustomizationProvider from API.
     * @param currentPage - page where the scroll is at
     * @param pageSize - number of items in a page
     */
    const fetchSmsCustomizationProviders = () => {
        if (isEmpty(selectedUserCompany)) return;

        if (!isRefetching) resetTableScroll = false;
        dispatch(getSmsCustomizationProviderRequestAction());
    };

    /**
     * Function that will be called upon initial loading of page, filter and sort changes, and company switch.
     */
    const callFetchSmsCustomizationProviders = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            if (lastSelectedCompanyId !== selectedCompanyId) {
                lastSelectedCompanyId = selectedCompanyId;
                resetAllSelectedRowKeys();
            }
            fetchSmsCustomizationProviders();
        }
    };

    useEffect(callFetchSmsCustomizationProviders, [selectedUserCompany]);

    // on Unmount
    useEffect(() => {
        return () => {
            lastSelectedCompanyId = null;
        };
    }, []);

    /**
     * A common function for updating tableRowSelection state object.
     * @param selectionObject - object to be updated. Key value pair must conform to tableRowSelection
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    const onRowSelect = (
        selectedRows: string[],
        nativeEvent: Event | boolean
    ) => {
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        updateTableRowSelection({
            selectedRowKeys
        });
    };

    /**
     * Function called when the checkbox is clicked.
     * @param record - record for the row - type is `any` because there may be some adjustments and additional keys that will be added
     */
    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(newSelectedRowKeys, true);
    };

    /**
     * Function called when row is clicked.
     * @param record - data where the row is clicked
     */
    const onRowClick = (record: DynamicObject) => {
        setProviderDetailsDrawerValues({
            visible: true,
            record,
        });
    };

    const resetAllSelectedRowKeys = () => {
        // reset Selected Row Keys after change role success
        updateTableRowSelection({
            selectedRowKeys: [],
        });
    };

    /**
     * Function responsible for showing a confirmation and eventually revoking the access tokens.
     */
    const useSmsProviderOfSelectedRow = () => {
        let keysToUse = [...tableRowSelection.selectedRowKeys];

        if (keysToUse.length > 1) return;
        
        resetAllSelectedRowKeys();

        setSmsCustomization(prevState => {
            return {...prevState, SelectedItemId: keysToUse[0]};
        });
    };

    /**
     * Function for updating the showConditions state.
     * @param showConditionObject
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions({
            ...showConditions,
            ...showConditionObject,
        });
    };

    /**
     * Function that controls the visibility of popover.
     * @param name
     * @param condition
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                let visibilityCondition = visible;
                if (name === 'providerCreateDrawer') {
                    visibilityCondition = !showConditions[name];
                    updateShowConditionsObject({
                        [name]: visibilityCondition,
                    });
                } else {
                    updateShowConditionsObject({
                        [name]: visible,
                    });
                }
            }
        };
    };

    /**
     * Function for populating the token create drawer content.
     */
    const populateSmsProviderCreateDrawerContent = () => {
        return (
            <SmsProviderItemDetailsComponent
                visible={showConditions.providerCreateDrawer}
                record={{}}
                isAddNew={true}
                closeDrawer={() => {
                    updateShowConditionsObject({
                        providerCreateDrawer: false,
                    });
                }}
                addSmsCustomizationItem={handleAddSmsCustomizationItem}
            />
        );
    };

    const closeDrawer = () => {
        setProviderDetailsDrawerValues({
            visible: false,
            record: {},
        });
    };

    const populateVirtualListDataSource = (Items: SmsCustomizationItem[]) => {
        if (!Items) return [];
        return Items.map(item => {
            return {
                ...item,
                IsSelected: item.Id == smsCustomization.SelectedItemId 
            }
        });
    };

    const areSmsCustomizationEquals = (object1: SmsCustomization, object2: SmsCustomization) => {
        if (object1 == object2) return true;
        if (!object1 || !object2) return false;
        if (object1.IsCustomizeSms != object2.IsCustomizeSms) return false;
        if (object1.SelectedItemId != object2.SelectedItemId) return false;
        if (object1.Items == object2.Items){
            return true;
        }
        else {
            if (object1.Items.length != object2.Items.length) return false;

            for (const item1 of object1.Items) {
                const item2 = object2.Items.find(x => x.Id == item1.Id);

                if (!item2) return false;

                if (item1.Name != item2.Name) return false;
                if (item1.Type != item2.Type) return false;
                if (item1.Url != item2.Url) return false;
                if (item1.BasicAuthUserName != item2.BasicAuthUserName) return false;
                if (item1.BasicAuthPassword != item2.BasicAuthPassword) return false;
                if (item1.OauthUrl != item2.OauthUrl) return false;
                if (item1.ClientId != item2.ClientId) return false;
                if (item1.ClientSecret != item2.ClientSecret) return false;
            }
        }
        return true;
    };



    const allowedRoles = rolePermissions.CUSTOMIZATION_UPDATE;
    const formDisabled = !isEmpty(allowedRoles) && !includes(allowedRoles, userRole);
    const formHasChanges = !areSmsCustomizationEquals(smsCustomizationState.data, smsCustomization);

    /**
     * Function called when `Save` button is clicked and will send all the changes to API.
     */
    const onSaveButtonClick = () => {
        validateFields((err: DynamicObject, _: any) => {
            if (!err) {
                 const payload: RequestSaveCompanySmsCustomizationItemPayload = {
                     SmsCustomization: smsCustomization,
                     callback: ({ IsSuccess }: { IsSuccess: boolean }) =>
                        handleModalSaveResponse(IsSuccess),
                 };

                 dispatch(
                    saveSmsCustomizationProviderRequestAction(
                         payload
                     )
                 );
            }
        });
    };
    
    const handleModalSaveResponse = (
        IsSuccess: boolean
    ) => {
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: `Sms Customization Provider saved successfully!`,
                onOk: () => {
                    dispatch(getSmsCustomizationProviderRequestAction());
                },
            });
        } else {
            Modal.error({
                title: 'Error',
                content: `Failed to save SMS Customization Provider`,
            });
        }
    };

    const populateLoadingText = () => {
        const loadingText = `Fetching SMS Customization Provider data`;

        isRefetching = false;

        return loadingText;
    };

    const onCancelButtonClick = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: 'Continue?',
            content: (
                <div>
                    When you click the <b>{confirmModalOkText}</b> button, all
                    the data will be reverted to the last saved values.
                </div>
            ),
            onOk() {
                setSmsCustomization(smsCustomizationState.data);
        
                setFieldsValue({
                    [formFieldNames.EnableDefaultSmsProvider]: !smsCustomizationState.data.IsCustomizeSms,
                    [formFieldNames.EnableCustomizeSmsProvider]: smsCustomizationState.data.IsCustomizeSms
                });
            },
            onCancel() {},
            okText: confirmModalOkText,
            cancelText: confirmModalCancelText,
        });
    };

    return (
        
        <Spin
            spinning={smsCustomizationState.loading}
            tip={populateLoadingText()}
            wrapperClassName="spin-vlist h-100"
        >
            <div className="h-100">
                <Col span={24}>
                    <Form
                        className="form-inline-mb-0"
                        labelCol={{
                            xxl: { span: 'auto' },
                            xl: { span: 7 },
                            lg: { span: 8 },
                            md: { span: 6 },
                            sm: { span: 6 },
                            xs: { span: 6 },
                        }}
                        wrapperCol={{
                            xxl: { span: 16 },
                            xl: { span: 17 },
                            lg: { span: 16 },
                            md: { span: 18 },
                            sm: { span: 18 },
                            xs: { span: 18 },
                        }}
                    >
                        <QueueAnim type={['right', 'left']} leaveReverse>
                            <Row key="title-container" type="flex" align="middle">
                                <Col span={12}>
                                    <Row>
                                        <Col>
                                            <Title level={3}>SMS Communications Provider</Title>
                                        </Col>
                                        <Col>
                                            Select the SMS Communications Provider
                                        </Col>
                                    </Row>
                                </Col>

                                <Col span={12} className="ta-right">
                                    <Button
                                        className="mr-10 w-100px"
                                        type="primary"
                                        onClick={onSaveButtonClick}
                                        disabled={formDisabled || !formHasChanges}
                                        loading={smsCustomizationState.saveLoading}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        className="buttonGrey w-100px"
                                        onClick={onCancelButtonClick}
                                        disabled={formDisabled || !formHasChanges}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col span={24}>
                                    <h4>Use default SMS provider</h4>
                                </Col>
                                <Col className="center-flex-i"
                                    xxl={10}
                                    xl={15}
                                    lg={18}
                                    md={18}
                                    sm={18}
                                    xs={18}
                                >
                                    Allow your customers to use default SMS provider of the system.
                                    &nbsp;&nbsp;
                                    <FormItem>
                                        {getFieldDecorator(
                                            formFieldNames.EnableDefaultSmsProvider,
                                            {
                                                valuePropName: 'checked',
                                                initialValue: !smsCustomization.IsCustomizeSms,
                                            }
                                        )(
                                            <Switch
                                                checkedChildren="ON"
                                                unCheckedChildren="OFF"
                                                onChange={handleDefaultProviderChange}
                                                // disabled={formDisabled}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col span={24}>
                                    <h4>Use customize SMS provider</h4>
                                </Col>
                                <Col className="center-flex-i"
                                    xxl={10}
                                    xl={15}
                                    lg={18}
                                    md={18}
                                    sm={18}
                                    xs={18}
                                >
                                    Allow your customers to customize SMS provider.
                                    &nbsp;&nbsp;
                                    <FormItem>
                                        {getFieldDecorator(
                                            formFieldNames.EnableCustomizeSmsProvider,
                                            {
                                                valuePropName: 'checked',
                                                initialValue: smsCustomization.IsCustomizeSms,
                                            }
                                        )(
                                            <Switch
                                                checkedChildren="ON"
                                                unCheckedChildren="OFF"
                                                onChange={handleCustomProviderChange}
                                                // disabled={formDisabled}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                                {getFieldValue(formFieldNames.EnableCustomizeSmsProvider) && (
                                    <Col
                                        xxl={24}
                                        xl={18}
                                        lg={22}
                                        md={24}
                                        sm={24}
                                        xs={24}
                                    >
                                        <Row>
                                            <Col>  
                                                <div key="action-bar-container">
                                                    <ActionBar
                                                        ref={actionBarRef}
                                                        loading={smsCustomizationState.loading}
                                                        actionItems={[
                                                            {
                                                                actionKey: 'create-provider',
                                                                actionType: 'protected-drawer-button',
                                                                popoverVisible:
                                                                    showConditions.providerCreateDrawer,
                                                                drawerCloseable: true,
                                                                popoverTitle: 'Create new provider',
                                                                popoverOnVisibleChange: popoverOnVisibleChange(
                                                                    'providerCreateDrawer'
                                                                ),
                                                                popoverContent: populateSmsProviderCreateDrawerContent(),
                                                                buttonContent: (
                                                                    <>
                                                                        <FontAwesome
                                                                            icon={['fa', 'plus-circle']}
                                                                        />
                                                                        <span>Create provider</span>
                                                                    </>
                                                                ),
                                                                drawerWidth: 500,
                                                            },
                                                            {
                                                                actionKey: 'select-provider',
                                                                actionType: 'protected-button',
                                                                onButtonClick: useSmsProviderOfSelectedRow,
                                                                buttonDisabled:
                                                                    isEmpty(
                                                                        tableRowSelection.selectedRowKeys
                                                                    ) ||
                                                                    tableRowSelection.selectedRowKeys.length >
                                                                        1,
                                                                buttonContent: (
                                                                    <>
                                                                        <FontAwesome
                                                                            icon={['fa', 'check']}
                                                                            className="mr-8"
                                                                        />
                                                                        <span>Use provider</span>
                                                                    </>
                                                                ),
                                                            },
                                                            {
                                                                actionKey: 'delete-provider',
                                                                actionType: 'protected-button',
                                                                buttonDisabled:
                                                                    isEmpty(
                                                                        tableRowSelection.selectedRowKeys
                                                                    ) ||
                                                                    tableRowSelection.selectedRowKeys.includes(smsCustomization.SelectedItemId),
                                                                onButtonClick: handleRemoveSmsCustomizationItem,
                                                                buttonContent: (
                                                                    <>
                                                                        <FontAwesome
                                                                            icon={['fas', 'trash']}
                                                                        />
                                                                        <span>Delete provider</span>
                                                                    </>
                                                                ),
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                                <div className="spacer-15" />
                                                {/* Table Section */}
                                                <Row key="table-container">
                                                    <Col span={24}>
                                                        <VirtualizedList 
                                                            dataSource={populateVirtualListDataSource(smsCustomization.Items)}
                                                            scroll={computeTableScroll(
                                                                window.innerHeight - 185,
                                                                CUSTOMIZE_SMS_PROVIDER.pageSize,
                                                                CUSTOMIZE_SMS_PROVIDER.rowHeight
                                                            )}
                                                            resetTableScroll={resetTableScroll}
                                                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                                                            onRowClick={onRowClick}
                                                            onCheckboxClick={onCheckboxClick}
                                                            loading={smsCustomizationState.loading} 
                                                            emptyText={
                                                                !isEmpty(smsCustomizationState.errorMessages)
                                                                    ? get(smsCustomizationState.errorMessages, 'errorMessages.0')
                                                                    : 'No custom provoder configurations found'
                                                            }
                                                            hasNextPage={false} 
                                                            itemComponent={SmsCustomizationItemComponent}
                                                            itemHeight={CUSTOMIZE_SMS_PROVIDER.rowHeight}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                            </Row>
                        </QueueAnim>
                    </Form>
                    <Suspense fallback={null}>
                        <SmsProviderItemDetailsDrawerComponent
                            visible={providerDetailsDrawerValues.visible}
                            record={providerDetailsDrawerValues.record}
                            closeDrawer={closeDrawer}   
                        />
                    </Suspense>
                    <Suspense fallback={null}>
                        <ModalWithSpinner
                            modalTitle="Saving SMS customiztion provider data"
                            modalVisible={smsCustomizationState.saveLoading}
                            displayMessage="Please wait while saving SMS customiztion provider data. . ."
                        />
                    </Suspense>
                </Col>
            </div>
        </Spin>
    );
};

const SmsCommunicationsProviderManagementPageForm = Form.create({
    name: 'sms-communication-provider',
})(SmsCommunicationsProviderManagementPage);
export default withRouter(SmsCommunicationsProviderManagementPageForm);
