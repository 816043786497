/**
 * File responsible for all the UI and actions for Dashboard page - `/app`.
 */
import {
    Button,
    Col,
    Divider,
    Icon,
    Modal,
    Row,
    Spin,
    Tooltip,
    Typography,
} from 'antd';
import { History as IHistory } from 'history';
import {
    clone,
    debounce,
    filter,
    find,
    findIndex,
    first,
    forEach,
    get,
    includes,
    isArray,
    isEmpty,
    isEqual,
    isUndefined,
    keys,
    map,
    omitBy,
    orderBy,
    range,
    reject,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    Suspense,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import 'react-resizable/css/styles.css';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ActionBar from '../../components/common/ActionBar';
import FontAwesome from '../../components/common/FontAwesome';
import RouteLeavingGuard from '../../components/common/RouteLeavingGuard';
import AddWidgetsPanel from '../../components/dashboard/AddWidgetsPanel';
import ConfigureWidgetPanel from '../../components/dashboard/ConfigureWidgetPanel';
import {
    confirmModalCancelText,
    confirmModalOkText,
} from '../../config/config';
import {
    CUSTOM_FIELD_TYPES,
    PAGE_NAMES_FOR_VIEW,
} from '../../config/tableAndPageConstants';
import {
    CompanyIdAttribute,
    IsOrganisationViewAttribute,
    OrganisationIdAttribute,
    OrganisationRoleIdAttribute,
} from '../../constants/authUserAttributes';
import { currencyCodes } from '../../constants/companyGeneralOptions';
import {
    dashboardRowHeight,
    DYNAMIC_CONTAINER_WIDGETS,
    FLEX_CONTAINER_WIDGETS,
    FULL_STYLE_WIDGETS,
    notQueryRelatedKeys,
    ORGANISATION_WIDGET_TYPES,
    populateDashboardWidgetTitle,
    WIDGET_TYPES,
    WIDGET_TYPE_COMPONENTS,
} from '../../constants/dashboards';
import { defaultOpenCompaniesFilterParams } from '../../constants/organisationsSortAndFilters'
import '../../less/dashboard.less';
import { ApplicationState } from '../../store';
import {
    downloadToExcelAction,
    getCustomFieldsFilterListByTypes,
    saveAppliedFiltersViewAction,
    updateRefetchPageViewsAction,
} from '../../store/common/actions';
import { PageViewCategoryTypes } from '../../store/common/types';
import { Company, CompanyUserRole } from '../../store/companies/types';
import {
    setDashboardSelectedIdRequestAction,
    setIsEditingDashboard,
} from '../../store/dashboards/actions';
import { DashboardsState } from '../../store/dashboards/types';
import {
    getOrganisationCompaniesForUserRequestAction,
    getOrganisationCompaniesRequestAction,
    getOrganisationCustomFieldsFilterListByTypes,
} from '../../store/organisations/actions';
import { Organisation } from '../../store/organisations/types';
import { getRolePermissions } from '../../store/roles/sagas';
import { getCurrentUser } from '../../store/users/sagas';
import {
    downloadToExcelModalResponseHandler,
    getIfIsLg,
    getPopoverContainer,
    mergeByKey,
    populateDownloadToExcelModalDisplayMessage,
    populateDownloadToExcelModalTitle,
    getTranslatedText
} from '../../utils/commonFunctions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import DashboardScheduleReportPanel from '../../components/dashboard/DashboardScheduleReportPanel';


const SaveDashboardModal = lazy(
    () => import('../../components/dashboard/SaveDashboardModal')
);
const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);
const PreviewWidgetModal = lazy(
    () => import('../../components/dashboard/PreviewWidgetModal')
);

const { Title } = Typography;

const { confirm } = Modal;
let lastSelectedCompanyId: string | null = null;
let lastSelectedOrganisationId: string | null = null;
let lastOrgView: boolean | null = null;
const pageName = PAGE_NAMES_FOR_VIEW.DASHBOARD_PAGE;
// let widgetsInitialized: boolean = false;
interface IProps {
    history: typeof IHistory;
}
let currentWidgetsLayout: DynamicObject[] = [];
const DashboardManagementPage: React.FC<IProps> = ({ history }: IProps) => {
    
    const unmountedRef = useRef<any>(null);
    const dispatch = useDispatch();
    const currentUser = useSelector(getCurrentUser);
    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
    const [allOrgCurrencyList, setAllOrgCurrencyList] = useState<
        DynamicObject[]
    >([]);
    const userRole = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Role.Name')
    );
    const roleList = useSelector(
        (state: ApplicationState) => state.roles.allRoles
    );
    const orgUserRoleId = get(currentUser, OrganisationRoleIdAttribute);
    const orgUserRole = find(roleList, ['RoleId', orgUserRoleId]);

    const [configurePanelRef, setConfigurePanelRef] = useState<any>(null);
    const [draggedWidget, setDraggedWidget] = useState<any>(null);
    const [refreshClicked, setRefreshClicked] = useState<boolean>(false);
    const [pageViewsLoading, setPageViewsLoading] = useState<boolean>(false);

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const selectedUserOrganisation: Organisation = useSelector(
        (state: ApplicationState) =>
            state.organisations.selectedUserOrganisation
    );

    const organisationCompanies: Company[] = useSelector(
        (state: ApplicationState) => state.organisations.companies.data
    );

    const organisationCompaniesLoading: boolean = useSelector(
        (state: ApplicationState) => state.organisations.companies.loading
    );

    const organisationUserCompanies = useSelector(
        (app: ApplicationState) => app.organisations.userCompanies.data
    );

    const dashboardsState: DashboardsState = useSelector(
        (state: ApplicationState) => state.dashboards
    );

    const menuCollapsed: boolean = useSelector(
        (state: ApplicationState) => state.storedRoute.menuCollapsed
    );

    const dashboardActiveData = dashboardsState.activeData;

    const rolePermissions = useSelector(getRolePermissions);

    const [showConditions, setShowConditions] = useState<{
        edittingWidgets: boolean;
        configuringWidget: boolean;
        activeConfiguredWidget: DynamicObject | null;
        activeConfiguredWidgetLayout: DynamicObject | null;
        saveToApi: boolean;
        downloadToExcel: boolean;
        scheduledReport: boolean;
        widgetQuery: string[] | [];
    }>({
        edittingWidgets: false,
        configuringWidget: false,
        activeConfiguredWidget: null,
        activeConfiguredWidgetLayout: null,
        saveToApi: false,
        downloadToExcel: false,
        scheduledReport: false,
        widgetQuery: []
    });

    const [previewWidgetState, setPreviewWidgetState] = useState<{
        visible: boolean;
        component: any;
        widgetProps: DynamicObject;
    }>({
        visible: false,
        component: null,
        widgetProps: {},
    });

    const [hoveredWidgetId, setHoveredWidgetId] = useState<string | null>(null);

    const [saveModalConditions, setSaveModalConditions] = useState<{
        showModal: boolean;
        showSubmitLoading: boolean;
    }>({
        showModal: false,
        showSubmitLoading: false,
    });

    /**
     * Common function for getting currency code by given locale
     * @param locale
     */
    const getCurrencyCodeByLocale = (locale: string) => {
        const localeArray = locale.split('-');
        const country = get(localeArray, 1);
        return get(currencyCodes, country);
    };

    const initializeOrgCurrencyList = () => {
        if (
            isEmpty(selectedUserOrganisation) ||
            isEmpty(organisationCompanies) ||
            organisationCompaniesLoading
        )
            return;

        const companyCurrencyList: DynamicObject[] = [];
        forEach(organisationCompanies, (oc: Company) => {
            const localeUsed = get(oc, 'LanguagePackage.Language', '');
            if (!isEmpty(localeUsed)) {
                const currencyCode = getCurrencyCodeByLocale(localeUsed);

                if (
                    isEmpty(find(companyCurrencyList, ['Value', currencyCode]))
                ) {
                    companyCurrencyList.push({
                        Value: currencyCode,
                        Rate: 1,
                        Locale: localeUsed,
                    });
                }
            }
        });
        const savedCurrencyList = get(selectedUserOrganisation, 'Currencies');
        const allCurrencyList = [...companyCurrencyList];
        forEach(savedCurrencyList, (sc: DynamicObject) => {
            const valueIdx = findIndex(allCurrencyList, [
                'Value',
                get(sc, 'Value'),
            ]);
            if (valueIdx > -1) {
                allCurrencyList[valueIdx] = {
                    ...allCurrencyList[valueIdx],
                    ...sc,
                };
            } else {
                allCurrencyList.push(sc);
            }
        });

        const sortedCurrencyList = orderBy(allCurrencyList, ['Value'], ['asc']);
        setAllOrgCurrencyList(sortedCurrencyList);
    };

    useEffect(initializeOrgCurrencyList, [
        isOrgView,
        selectedUserOrganisation,
        organisationCompanies,
    ]);

    const [widgetsState, setWidgetsState] = useState<DynamicObject>({
        items: [],
        loading: false,
        configuredWidgetHasChanges: false,
    });

    const [customFieldsFilterList, setCustomFieldsFilterList] = useState<[]>(
        []
    );

    /**
     * Function that fetches the custom fields filter list
     */
    const getCustomFieldsFilterList = () => {
        if (isOrgView || !selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        const customFieldsFilterTypes = [
            CUSTOM_FIELD_TYPES.INVOICE,
            CUSTOM_FIELD_TYPES.CUSTOMER,
            CUSTOM_FIELD_TYPES.CREDIT,
        ];

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getCustomFieldsFilterListByTypes(
                    customFieldsFilterTypes, // Filters are sorted based on the orders of types here and Number property
                    (response: any) => {
                        if (unmountedRef.current) return;
                        if (response.IsSuccess) {
                            setCustomFieldsFilterList(response.CustomFields);
                        }
                    }
                )
            );
        }
    };

    useEffect(getCustomFieldsFilterList, [selectedUserCompany, isOrgView]);

    /**
     * Function that fetches the custom fields filter list for organisation
     */
    const getOrganisationCustomFieldsFilterList = () => {
        if (
            !isOrgView ||
            !selectedUserOrganisation ||
            isEmpty(organisationUserCompanies)
        )
            return;
        const organisationIdCognito = get(currentUser, OrganisationIdAttribute);
        const selectedOrganisationId = get(
            selectedUserOrganisation,
            'OrganisationId'
        );

        const customFieldsFilterTypes = [
            CUSTOM_FIELD_TYPES.INVOICE,
            CUSTOM_FIELD_TYPES.CUSTOMER,
            CUSTOM_FIELD_TYPES.CREDIT,
        ];

        if (organisationIdCognito === selectedOrganisationId) {
            dispatch(
                getOrganisationCustomFieldsFilterListByTypes(
                    customFieldsFilterTypes, // Filters are sorted based on the orders of types here and Number property
                    (response: any) => {
                        if (unmountedRef.current) return;
                        if (response.IsSuccess) {
                            setCustomFieldsFilterList(response.CustomFields);
                        }
                    }
                )
            );
        }
    };

    useEffect(getOrganisationCustomFieldsFilterList, [
        selectedUserOrganisation,
        organisationUserCompanies,
        isOrgView,
    ]);

    /**
     * Function that initializes the widgets in dashboard.
     */
    const initializeWidgetsValues = () => {
        if (
            (!isOrgView && !selectedUserCompany) ||
            (isOrgView && !selectedUserOrganisation)
        )
            return;

        // if (pageViewsLoading) {
        //     updateWidgetsStateObject({ items: [] });
        //     return;
        // }

        if (!isOrgView) {
            const companyIdCognito = get(currentUser, CompanyIdAttribute);
            const selectedCompanyId = get(
                selectedUserCompany,
                'Company.CompanyId'
            );
            if (companyIdCognito === selectedCompanyId) {
                if (lastSelectedCompanyId !== selectedCompanyId) {
                    updateWidgetsStateObject({ items: [], loading: true });
                    lastSelectedCompanyId = selectedCompanyId;
                    return;
                }
            }
        } else {
            const organisationIdCognito = get(
                currentUser,
                OrganisationIdAttribute
            );
            const selectedOrganisationId = get(
                selectedUserOrganisation,
                'OrganisationId'
            );
            if (organisationIdCognito === selectedOrganisationId) {
                if (lastSelectedOrganisationId !== selectedOrganisationId) {
                    lastSelectedOrganisationId = selectedOrganisationId;
                    updateWidgetsStateObject({ items: [], loading: true });
                    return;
                }
            }
        }

        if (lastOrgView !== isOrgView) {
            lastOrgView = isOrgView;
            updateWidgetsStateObject({ items: [], loading: true });
            return;
        }

        if (pageViewsLoading) return;

        const dashboardActiveRecord = clone(dashboardActiveData.record);
        const reduxDashboardWidgets =
            !isEmpty(dashboardActiveRecord) &&
            get(dashboardActiveRecord, 'DashboardState')
                ? JSON.parse(get(dashboardActiveRecord, 'DashboardState'))
                : [];

        if (
            refreshClicked ||
            (!isEqual(reduxDashboardWidgets, widgetsState.items) &&
                !dashboardsState.loading &&
                !dashboardsState.isEditing)
        ) {
            const usedWidgetsLayout = map(reduxDashboardWidgets, (rdw) => {
                const items: DynamicObject = {};
                forEach(rdw, (item, key) => {
                    if (includes(notQueryRelatedKeys, key)) {
                        items[key] = item;
                    }
                });

                return items;
            });
            // widgetsInitialized = true;

            // setCurrentWidgetsLayout(usedWidgetsLayout);
            currentWidgetsLayout = usedWidgetsLayout;
            const widgetsItemValue = isArray(reduxDashboardWidgets)
                ? reduxDashboardWidgets
                : [];
            if (refreshClicked) {
                setRefreshClicked(false);
                const usedWidgetItems = map(widgetsItemValue, (wi) => ({
                    ...wi,
                    refetchQuery: true,
                }));
                updateWidgetsStateObject({
                    items: usedWidgetItems,
                    loading: false,
                });
            } else {
                const widgetItemsIs = map(widgetsState.items, (item) => item.i);
                const reduxDashboardWidgetsIs = map(
                    reduxDashboardWidgets,
                    (item) => item.i
                );
                if (
                    isUndefined(get(widgetsState.items, '0.refetchQuery')) ===
                        true ||
                    !isEqual(widgetItemsIs, reduxDashboardWidgetsIs)
                ) {
                    updateWidgetsStateObject({
                        items: widgetsItemValue,
                        loading: false,
                    });
                }
            }
        } else if (widgetsState.loading && !dashboardsState.loading) {
            updateWidgetsStateObject({
                loading: false,
            });
        }
    };

    useEffect(initializeWidgetsValues, [
        dashboardActiveData.record,
        selectedUserCompany,
        selectedUserOrganisation,
        pageViewsLoading,
        refreshClicked,
        isOrgView,
    ]);

    const resetVariablesOnUnmount = () => {
        unmountedRef.current = false;
        return () => {
            // widgetsInitialized = false;
            lastSelectedCompanyId = null;
            lastSelectedOrganisationId = null;
            lastOrgView = null;
            unmountedRef.current = true;
        };
    };
    useEffect(resetVariablesOnUnmount, []);

    const initializeOrganisationCompaniesForUser = () => {
        if (isOrgView && !isEmpty(selectedUserOrganisation)) {
            dispatch(
                getOrganisationCompaniesRequestAction(
                    defaultOpenCompaniesFilterParams
                )
            );
            dispatch(getOrganisationCompaniesForUserRequestAction());
        }
    };

    useEffect(initializeOrganisationCompaniesForUser, [
        selectedUserOrganisation,
    ]);

    /**
     * Common function for setting the showConditions state.
     * @param showConditionsObject - must conform to `showConditions` state
     */
    const updateShowConditionsObject = (showConditionsObject: {}) => {
        setShowConditions({
            ...showConditions,
            ...showConditionsObject,
        });
    };

    /**
     * Common function for setting the previewWidgetState state.
     * @param previewWidgetStateObject - must conform to `previewWidgetState` state
     */
    const updatePreviewWidgetStateObject = (previewWidgetStateObject: {}) => {
        setPreviewWidgetState({
            ...previewWidgetState,
            ...previewWidgetStateObject,
        });
    };

    /**
     * Function called whenever the widgets layout changes.
     * @param layout
     */
    const onLayoutChange = (layout: any) => {
        if (draggedWidget) return;

        const newLayout = map(layout, (l: any) => omitBy(l, isUndefined));

        if (newLayout) {
            // setCurrentWidgetsLayout(newLayout);
            currentWidgetsLayout = newLayout;
            // const mergedWidgetsData = mergeByKey(
            //     widgetsState.items,
            //     newLayout,
            //     'i'
            // );

            // updateWidgetsStateObject({
            //     items: mergedWidgetsData,
            // });
        }

        if (showConditions.configuringWidget) {
            const activeConfiguredWidgetLayout = get(
                filter(layout, [
                    'i',
                    get(showConditions, 'activeConfiguredWidget.i'),
                ]),
                0
            );
            if (activeConfiguredWidgetLayout) {
                updateShowConditionsObject({
                    activeConfiguredWidgetLayout: {
                        ...activeConfiguredWidgetLayout,
                    },
                });
            }
        }
    };

    /**
     * Function that populates the dropdown for dashboard list and adds an `Add dashboard` button.
     * @param menu
     */
    const selectDashboardDropdownRenderer = (menu: React.Component) => (
        <div>
            {menu}
            <Divider className="action-bar-divider" />
            <div
                className="pa-12 cursor-p"
                onMouseDown={handleShowSaveDashboardModal}
            >
                {getTranslatedText('Add dashboard')}
            </div>
        </div>
    );

    /**
     * Function called when `Refresh` button is clicked.
     */
    const onRefreshButtonClick = () => {
        setRefreshClicked(true);
        dispatch(
            updateRefetchPageViewsAction(
                true,
                get(dashboardActiveData, 'record.Name')
            )
        );
    };

    const [saveModalFormInitialValues, setSaveModalFormInitialValues] =
        useState<DynamicObject>({});

    /**
     * Common function for updating the saveModalConditions object from state.
     * @param saveModalConditionsObject
     */
    const updateSaveModalConditionsObject = (
        saveModalConditionsObject: DynamicObject
    ) => {
        setSaveModalConditions({
            ...saveModalConditions,
            ...saveModalConditionsObject,
        });
    };

    /**
     * Common function for updating the `widgetsState` state.
     * @param widgetsStateObject
     */
    const updateWidgetsStateObject = (widgetsStateObject: DynamicObject) => {
        setWidgetsState({
            ...widgetsState,
            ...widgetsStateObject,
        });
    };

    /**
     * Function called for showing the saving dashboard modal.
     */
    const handleShowSaveDashboardModal = () => {
        updateSaveModalConditionsObject({
            showModal: true,
        });
    };

    /**
     * Function called for hiding the saving dashboard modal.
     */
    const handleHideSaveDashboardModal = () => {
        updateSaveModalConditionsObject({
            showModal: false,
        });
        setSaveModalFormInitialValues({});
    };

    // const doesDashboardNameExist = (name: string) => {
    //     const filteredViewList = filter(dashboardsState.data, ['Name', name]);
    //     return !isEmpty(filteredViewList);
    // };

    /**
     * Function called when clicking on the `Save` button in save view modal form.
     * @param values - values needed as payload when saving the view (only name and type)
     */
    const saveDashboard = (values: DynamicObject) => {
        const payload = { ...values };
        // const dashboardName = get(values, 'Name');
        // if (doesDashboardNameExist(dashboardName)) {
        //     confirm({
        //         className: 'modal-swapped-buttons',
        //         title: 'Do you want to continue?',
        //         content: (
        //             <div>
        //                 Dashboard with the name{' '}
        //                 <b className="red">{dashboardName}</b> already exists.
        //                 Proceeding will overwrite that existing dashboard if
        //                 you're the one who created it.
        //             </div>
        //         ),
        //         onOk: () => saveDashboardActionCall(payload),
        //         okText: confirmModalOkText,
        //         cancelText: confirmModalCancelText,
        //     });
        // } else {
        saveDashboardActionCall(payload);
        // }
    };

    /**
     * Function called when saving the new page filter.
     * @param payload
     * @param isEdit
     * @param callback
     */
    const saveDashboardActionCall = (
        payload: DynamicObject,
        containerRef?: any,
        callback?: Function
    ) => {
        if (isEmpty(payload.DashboardState)) {
            const filterValuesUsed: any = [];

            payload.DashboardState = filterValuesUsed
                ? JSON.stringify(filterValuesUsed)
                : '';
        }

        payload.PageName = pageName;

        updateSaveModalConditionsObject({
            showSubmitLoading: true,
            showModal: false,
        });
        setSaveModalFormInitialValues(payload);

        dispatch(
            saveAppliedFiltersViewAction(
                payload,
                ({ IsSuccess, Messages }: ResponseModalObject) => {
                    if (unmountedRef.current) return;
                    saveDashboardResponseModal(
                        IsSuccess,
                        Messages,
                        get(payload, 'Name'),
                        callback,
                        containerRef
                    );
                }
            )
        );
    };

    /**
     * Function for populating the response modal after Save dashboard action API call has finished.
     * @param IsSuccess
     * @param Messages
     * @param lastSavedDashboardName
     * @param callback
     * @param containerRef
     */
    const saveDashboardResponseModal = (
        IsSuccess: boolean,
        Messages: string[] | undefined,
        lastSavedDashboardName: string | undefined,
        callback?: Function,
        containerRef?: any
    ) => {
        updateSaveModalConditionsObject({
            showSubmitLoading: false,
            showModal: false,
        });
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: getTranslatedText('Dashboard saved successfully!'),
                onOk: () => {
                    handleHideSaveDashboardModal();
                    setSaveModalFormInitialValues({});

                    // widgetsInitialized = false;
                    dispatch(
                        updateRefetchPageViewsAction(
                            true,
                            lastSavedDashboardName
                        )
                    );

                    if (callback) callback();
                },
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        } else {
            let errorMessageContent:
                | string
                | JSX.Element[] = getTranslatedText(`Failed to save the dashboard!`);
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                onOk: () => {
                    if (!callback) {
                        updateSaveModalConditionsObject({
                            showModal: true,
                        });
                    }
                },
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        }
    };

    /**
     * Function called when dashboard is selected from dropdown.
     * @param dashboardName
     */
    const onSelectedDashboardChange = (dashboardName: string | null) => {
        if (dashboardName !== get(dashboardActiveData.record, 'Name')) {
            // widgetsInitialized = false;
            dispatch(setDashboardSelectedIdRequestAction(dashboardName));
        }
    };

    /**
     * Function called to initialize dashboard edit mode.
     */
    const onDashboarWidgetsdEdit = () => {
        const widgetItems = clone(widgetsState.items);
        const noRefetchWidgetItems = map(widgetItems, (wd: any) => {
            if (!isUndefined(get(wd, 'refetchQuery'))) {
                delete wd.refetchQuery;
            }

            return {
                ...wd,
            };
        });
        updateWidgetsStateObject({
            items: noRefetchWidgetItems,
        });
        updateShowConditionsObject({
            edittingWidgets: true,
        });
    };

    /**
     * Function called after dashboard edit mode has finished.
     * @param resetConfiguredState
     * @param containerRef
     * @param widgetItemsData
     */
    const onDashboardWidgetsEditEnd = (
        resetConfiguredState?: boolean,
        containerRef?: any,
        widgetItemsData?: DynamicObject[]
    ) => {
        const usedWidgetItems = widgetItemsData
            ? clone(widgetItemsData)
            : clone(widgetsState.items);
        const mergedWidgetsData = mergeByKey(
            usedWidgetItems,
            currentWidgetsLayout,
            'i'
        );

        const dashboardData: DynamicObject = clone(dashboardActiveData.record);
        const dashboardStateData = map(mergedWidgetsData, (wd: any) => {
            if (!isUndefined(get(wd, 'refetchQuery'))) {
                delete wd.refetchQuery;
            }

            return {
                ...wd,
            };
        });

        dashboardData.DashboardState = JSON.stringify(dashboardStateData);
        const dashboardHasChanges = !isEqual(
            get(dashboardActiveData.record, 'DashboardState'),
            dashboardData.DashboardState
        );

        if (dashboardHasChanges) {
            saveDashboardActionCall(dashboardData, containerRef, () => {
                updateWidgetsStateObject({
                    items: mergedWidgetsData,
                    configuredWidgetHasChanges: false,
                });
                if (resetConfiguredState) {
                    updateShowConditionsObject({
                        configuringWidget: false,
                        activeConfiguredWidget: null,
                        activeConfiguredWidgetLayout: null,
                        saveToApi: false,
                    });
                } else {
                    updateShowConditionsObject({
                        edittingWidgets: false,
                        saveToApi: false,
                        configuringWidget: false,
                    });
                }
            });
        } else {
            updateWidgetsStateObject({
                items: mergedWidgetsData,
                configuredWidgetHasChanges: false,
            });

            updateShowConditionsObject({
                edittingWidgets: false,
                saveToApi: false,
            });
        }
    };

    /**
     * Function called on widget drag.
     * @param widget
     */
    const onWidgetDrag = (widget: DynamicObject) => {
        setDraggedWidget(widget);
    };

    /**
     * Function called on widget drop.
     * @param layout
     * @param item
     */
    const onWidgetDrop = (layout: any, item: any) => {
        onAddWidget(draggedWidget, layout, item);
    };

    /**
     * Function called when Add widget is clicked.
     * @param widgetItem
     */
    const onAddWidgetClick = (widgetItem: DynamicObject) => {
        setDraggedWidget(null);
        onAddWidget(widgetItem);
    };

    /**
     * Function called when adding widget.
     * @param widgetItem
     * @param newLayout
     * @param newItemLayout
     */
    const onAddWidget = (
        widgetItem: DynamicObject,
        newLayout?: any,
        newItemLayout?: any
    ) => {
        const layoutsMerged = newLayout
            ? [...currentWidgetsLayout, ...newLayout]
            : [...currentWidgetsLayout];
        let mergedWidgetsData = mergeByKey(
            widgetsState.items,
            layoutsMerged,
            'i'
        );

        widgetItem.title = populateDashboardWidgetTitle(
            widgetItem,
            organisationCompanies,
            allOrgCurrencyList
        );

        const sortedYDescending = orderBy(mergedWidgetsData, ['y'], 'desc');
        const greatestY = get(first(sortedYDescending), 'y');
        const xArray = range(0, 12);
        if (draggedWidget === null) {
            forEach(mergedWidgetsData, (wd: DynamicObject) => {
                if (get(wd, 'y') === greatestY) {
                    const rangeEnd = get(wd, 'x') + get(wd, 'w');
                    range(get(wd, 'x'), rangeEnd).forEach((currentX) => {
                        const xIdx = xArray.indexOf(currentX);
                        if (xIdx !== -1) xArray.splice(xIdx, 1);
                    });
                }
            });
        }

        const widgetItemWidth = get(widgetItem, 'w', 2);

        const droppedWidgetData: DynamicObject =
            newItemLayout ||
            get(
                filter(currentWidgetsLayout, ['i', '__dropping-elem__']),
                0,
                {}
            );
        const newWidgetItemData =
            draggedWidget === null
                ? widgetItem
                : {
                      ...widgetItem,
                      ...droppedWidgetData,
                  };

        let xPosition = get(newWidgetItemData, 'x', 0);

        let yPosition = get(newWidgetItemData, 'y', Infinity);

        if (draggedWidget === null) {
            xPosition =
                get(xArray, '0') && widgetItemWidth <= xArray.length
                    ? get(xArray, '0')
                    : 0;

            yPosition = Infinity;
        } else {
            const xyFiltered = filter(mergedWidgetsData, (mwd: any) => {
                if (mwd.y === yPosition) {
                    if (mwd.x === xPosition) {
                        return true;
                    } else if (mwd.x < xPosition && mwd.x + mwd.w > xPosition) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            });

            const sameXY: DynamicObject = get(xyFiltered, 0);

            if (sameXY) {
                sameXY.y = sameXY.y + droppedWidgetData.h;
                mergedWidgetsData = mergeByKey(
                    mergedWidgetsData,
                    [
                        {
                            ...sameXY,
                        },
                    ],
                    'i'
                );
            }
        }

        const newWidgetItems = [
            ...mergedWidgetsData,
            {
                ...widgetItem,
                i: widgetItem.type + uuidv4(),

                // x: (widgetsState.items.length * 2) % 12,
                x: xPosition,
                y: yPosition, // puts it at the bottom
                w: widgetItemWidth,
                h: get(widgetItem, 'h', 2),
            },
        ];

        updateWidgetsStateObject({
            items: orderBy(newWidgetItems, ['y', 'asc']),
        });

        if (draggedWidget) setDraggedWidget(null);
    };

    /**
     * Function called to show a confirmation when deleting the widget.
     * @param widget
     * @param saveToApi
     */
    const confirmDeleteWidget = (
        widget: DynamicObject,
        saveToApi: boolean = false
    ) => {
        confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText('Delete widget'),
            content: getTranslatedText('Are you sure you want to delete this widget?'),
            onOk: () => deleteWidget(get(widget, 'i'), saveToApi),
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
        });
    };

    /**
     * Function called when deleting the widget.
     * @param widgetId
     * @param saveToApi
     */
    const deleteWidget = (widgetId: string | undefined, saveToApi: boolean) => {
        if (widgetId) {
            const mergedWidgetsData = mergeByKey(
                widgetsState.items,
                currentWidgetsLayout,
                'i'
            );
            const updatedWidgetsList = reject(mergedWidgetsData, {
                i: widgetId,
            });
            if (saveToApi) {
                // updateShowConditionsObject({
                //     saveToApi,
                // });
                onDashboardWidgetsEditEnd(true, null, updatedWidgetsList);
            } else {
                updateWidgetsStateObject({
                    items: updatedWidgetsList,
                });
            }
        }
    };

    /**
     * Function called when widget is hovered.
     * @param widgetId
     */
    const onWidgetHoverOver = (widgetId: string) => {
        const debouncedFn = debounce(() => {
            if (
                !showConditions.edittingWidgets &&
                !showConditions.configuringWidget &&
                !isEqual(widgetId, hoveredWidgetId)
            ) {
                setHoveredWidgetId(widgetId);
            }
        }, 200);
        debouncedFn();
    };

    /**
     * Function called when widget mouse leaves the widget upon hover.
     */
    const onWidgetHoverLeave = () => {
        const debouncedFn = debounce(() => {
            if (
                !showConditions.edittingWidgets &&
                !showConditions.configuringWidget &&
                hoveredWidgetId !== null
            ) {
                setHoveredWidgetId(null);
            }
        }, 200);
        debouncedFn();
    };

    const hasActiveData = !isEmpty(dashboardActiveData.record);
    let isEditAllowed = hasActiveData;

    if (
        get(dashboardActiveData.record, 'Category') ===
        PageViewCategoryTypes.COMPANY
    ) {
        const allowedRolesCompany = rolePermissions.DASHBOARD_EDIT_COMPANY;
        const adminPowerRole =
            isEmpty(allowedRolesCompany) ||
            includes(allowedRolesCompany, userRole)
                ? true
                : false;

        if (!adminPowerRole) isEditAllowed = false;
    } else if (
        get(dashboardActiveData.record, 'Category') ===
        PageViewCategoryTypes.ORGANISATION
    ) {
        const allowedRolesOrganisation =
            rolePermissions.ORGANISATION_DASHBOARD_EDIT;
        const adminPowerRole =
            isEmpty(allowedRolesOrganisation) ||
            includes(allowedRolesOrganisation, get(orgUserRole, 'Name'))
                ? true
                : false;

        if (!adminPowerRole) isEditAllowed = false;
    }

    // const populateWidgetsPopover = (widget: DynamicObject) => {
    //     return (
    //         <div className="widget-actions-container">
    //             <div
    //                 className="widget-action-item"
    //                 onClick={() => onWidgetConfigurationStart(widget)}
    //             >
    //                 <Icon type="setting" />
    //                 Configure
    //             </div>
    //             <div
    //                 className="widget-action-item"
    //                 onClick={() => confirmDeleteWidget(widget, true)}
    //             >
    //                 <Icon type="close" style={{ color: 'red' }} />
    //                 Delete
    //             </div>
    //         </div>
    //     );
    // };

    /**
     * Function called when widget is previewed.
     * @param component
     * @param widgetProps
     */
    const onPreviewWidget = (component: any, widgetProps: DynamicObject) => {
        updatePreviewWidgetStateObject({
            visible: true,
            component,
            widgetProps,
        });
    };

    /**
     * Function called when preview widget ends.
     */
    const onPreviewWidgetClose = () => {
        updatePreviewWidgetStateObject({
            visible: false,
            component: null,
            widgetProps: {},
        });
    };

    const downloadToExcelHandler = (functionRefObj: any) => () => {
        if (functionRefObj && functionRefObj.getPayload) {
            functionRefObj.getPayload((payload: any) => {
                updateShowConditionsObject({
                    downloadToExcel: true
                });
                dispatch(downloadToExcelAction(payload, downloadToExcelModalResponse));
            });
        }
    };

    const createScheduleReportHandler = (functionRefObj: any, widget: DynamicObject) => () => {
        if (functionRefObj && functionRefObj.getPayload) {
            functionRefObj.getPayload((payload: any) => {
                const widgetLayout = find(currentWidgetsLayout, [
                    'i',
                    get(widget, 'i'),
                ]);
        
                const widgetData = widgetLayout
                    ? { ...widget, ...widgetLayout }
                    : { ...widget };

                updateShowConditionsObject({
                    scheduledReport: true,
                    activeConfiguredWidget: widgetData,
                    widgetQuery: payload
                });
            });
        }
    };

    const downloadToExcelModalResponse = ({
        IsSuccess,
        Messages,
    }: ResponseModalObject) => {
        updateShowConditionsObject({
            downloadToExcel: false
        });
        downloadToExcelModalResponseHandler(IsSuccess, Messages);
    };

    /**
     * Function called for populating the widgets.
     */
    const populateWidgets = () => {
        return map(widgetsState.items, (widget: DynamicObject) => {
            const functionRefObj = { getPayload: undefined };
            const widgetType = get(widget, 'type');
            const widgetDownloadColor = get(
                WIDGET_TYPE_COMPONENTS,
                `${widgetType}.downloadColor`
            );;
            const WidgetComponent = get(
                WIDGET_TYPE_COMPONENTS,
                `${widgetType}.widget`
            );

            const widgetId = get(widget, 'i');
            const widgetClassName =
                get(showConditions.activeConfiguredWidget, 'i') === widgetId
                    ? 'zi-1400'
                    : '';
            const isHovered =
                isEditAllowed &&
                !showConditions.edittingWidgets &&
                !showConditions.configuringWidget &&
                widget.i === hoveredWidgetId;

            let widgetContainerClass = '';

            const widgetProps = {
                key: widget.i,
                widgetDetails: widget,
                customFieldsFilterList,
            };

            if (get(DYNAMIC_CONTAINER_WIDGETS, widgetType)) {
                const dynamicContainerWidget =
                    DYNAMIC_CONTAINER_WIDGETS[widgetType];

                let isFlex = true;
                forEach(keys(dynamicContainerWidget), (keyName) => {
                    if (
                        get(widget, keyName) !== dynamicContainerWidget[keyName]
                    ) {
                        isFlex = false;
                    }
                });

                widgetContainerClass = isFlex &&
                    get(widget, 'displayType') !== "Table"
                    ? 'widget-flex-container'
                    : 'widget-block-container';
            } else {
                const isFlex = includes(FLEX_CONTAINER_WIDGETS, widgetType);

                widgetContainerClass = isFlex &&
                    get(widget, 'displayType') !== "Table"
                    ? 'widget-flex-container'
                    : 'widget-block-container';
            }

            const isFullStyle = includes(FULL_STYLE_WIDGETS, widget.type);

            const widgetTitle = get(widget, 'title');
            return (
                <div
                    key={widget.i}
                    id={widget.i}
                    className={`widget-component-container ${
                        isFullStyle
                            ? 'widget-component-container-full-style'
                            : ''
                    } ${widgetClassName} ${
                        isHovered ? 'widget-component-container-hovered' : ''
                    }`}
                    style={{
                        cursor: isDraggable ? 'move' : 'initial',
                    }}
                    onMouseOver={() => onWidgetHoverOver(widgetId)}
                    onMouseLeave={() => onWidgetHoverLeave()}
                >
                    <div
                        className={`widget-component ${
                            isFullStyle ? 'widget-full-style' : ''
                        } ${widgetContainerClass}`}
                    >
                        {!isFullStyle && (
                            <>
                                <div className="widget-title">
                                    {getTranslatedText(widgetTitle)}
                                </div>

                                <div className="widget-title-spacer" />
                            </>
                        )}
                        <div
                            className={`widget-direct-container ${
                                widgetProps.widgetDetails.type ===
                                    WIDGET_TYPES.TICKETS.type ||
                                widgetProps.widgetDetails.type ===
                                    ORGANISATION_WIDGET_TYPES
                                        .ORGANISATION_TICKETS.type
                                    ? 'tickets-widget-direct-container'
                                    : ''
                            }`}
                        >
                            {WidgetComponent && (
                                <WidgetComponent
                                    {...widgetProps}
                                    isOrgView={isOrgView}
                                    organisationCurrenciesAll={
                                        allOrgCurrencyList
                                    }
                                    widgetTitle={getTranslatedText(widgetTitle)}
                                    functionRefObj={functionRefObj}
                                />
                            )}
                        </div>
                        {isDraggable && (
                            <div className="widget-icons-section">
                                <Tooltip
                                    title={getTranslatedText("Configure")}
                                    placement="bottomRight"
                                >
                                    <div
                                        className="widget-config-icon"
                                        onClick={() =>
                                            onWidgetConfigurationStart(widget)
                                        }
                                    >
                                        <Icon type="setting" />
                                    </div>
                                </Tooltip>

                                <Tooltip title={getTranslatedText("Delete")} placement="bottomRight">
                                    <div
                                        className="widget-delete-icon"
                                        onClick={() =>
                                            confirmDeleteWidget(widget)
                                        }
                                    >
                                        <Icon type="close" />
                                    </div>
                                </Tooltip>
                            </div>
                        )}
                        {isHovered && (
                            <div className="widget-icons-section-hover">
                                <Tooltip
                                    title={getTranslatedText("Create Scheduled Report")}
                                    placement="bottomRight">
                                    <Button
                                        type="link"
                                        onClick={createScheduleReportHandler(functionRefObj, widget)}
                                    >
                                         <div className="icon-stack">
                                            <FontAwesome
                                                icon={['far', 'calendar']}
                                                className={`icon calendar ${widgetDownloadColor}`}
                                            />
                                            <FontAwesome
                                                icon={['fas', 'clock']}
                                                className={`icon clock ${widgetDownloadColor}`}
                                            />
                                        </div>
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    title={getTranslatedText("Download to excel")}
                                    placement="bottomRight">
                                    <Button
                                        type="link"
                                        onClick={downloadToExcelHandler(functionRefObj)}
                                    >
                                        <FontAwesome
                                            icon={['fas', 'cloud-download-alt']}
                                            className={widgetDownloadColor}
                                        />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    title={getTranslatedText("Expand view")}
                                    placement="bottomRight"
                                >
                                    <div
                                        className="widget-mini-icon"
                                        onClick={() =>
                                            onPreviewWidget(
                                                WidgetComponent,
                                                widgetProps
                                            )
                                        }
                                    >
                                        <Icon type="arrows-alt" />
                                    </div>
                                </Tooltip>

                                {/* <Popover
                                    className="widget-action-popover"
                                    placement="bottomRight"
                                    trigger="click"
                                    content={populateWidgetsPopover(widget)}
                                >
                                    <div className="widget-mini-icon">
                                        <Icon type="ellipsis" />
                                    </div>
                                </Popover> */}
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    };

    /**
     * Function called upon started configuration of widget.
     * @param widget
     */
    const onWidgetConfigurationStart = (widget: DynamicObject) => {
        const widgetLayout = find(currentWidgetsLayout, [
            'i',
            get(widget, 'i'),
        ]);

        const widgetData = widgetLayout
            ? { ...widget, ...widgetLayout }
            : { ...widget };

        updateShowConditionsObject({
            configuringWidget: true,
            activeConfiguredWidget: widgetData,
        });
    };

    /**
     * Function called when widget configuration ends.
     * @param resetChanges
     * @param saveChanges
     * @param containerRef
     */
    const onWidgetConfigurationEnd = (
        resetChanges?: boolean,
        saveChanges?: boolean,
        containerRef?: any
    ) => {
        if (containerRef) {
            setConfigurePanelRef(containerRef);
        }
        let saveToApi = widgetsState.configuredWidgetHasChanges;
        if (resetChanges) {
            const previousWidgetData =
                showConditions.activeConfiguredWidget || {};
            let mergedWidgetsData = mergeByKey(
                widgetsState.items,
                [previousWidgetData],
                'i'
            );

            const prevWidgetIdx = findIndex(mergedWidgetsData, [
                'i',
                get(previousWidgetData, 'i'),
            ]);

            if (prevWidgetIdx > -1) {
                previousWidgetData.refetchQuery = true;
                mergedWidgetsData[prevWidgetIdx] = previousWidgetData;
            }

            currentWidgetsLayout = mergedWidgetsData;

            updateWidgetsStateObject({
                items: mergedWidgetsData,
                configuredWidgetHasChanges: false,
            });
            saveToApi = false;
        } else {
            if (showConditions.edittingWidgets || !saveChanges) {
                updateWidgetsStateObject({
                    configuredWidgetHasChanges: false,
                });
            } else {
                return onDashboardWidgetsEditEnd(true, containerRef);
            }
        }

        updateShowConditionsObject({
            configuringWidget: false,
            activeConfiguredWidget: null,
            activeConfiguredWidgetLayout: null,
            saveToApi: showConditions.edittingWidgets ? false : saveToApi,
        });
    };

    /**
     * Function called when create schedule report is closed.
     * @param resetChanges
     * @param saveChanges
     * @param containerRef
     */
    const onCreateScheduledReportEnd = (
        containerRef?: any
    ) => {
        if (containerRef) {
            setConfigurePanelRef(containerRef);
        }
        updateShowConditionsObject({
            scheduledReport: false,
            activeConfiguredWidget: null,
            activeConfiguredWidgetLayout: null,
        });
    };

    /**
     * Function called when widget data upon configuration updates.
     * @param newWidgetData
     */
    const onWidgetConfigureUpdate = (newWidgetData: DynamicObject) => {
        const mergedWidgetsLayoutData = mergeByKey(
            widgetsState.items,
            currentWidgetsLayout,
            'i'
        );

        const mergedWidgetsData = mergeByKey(
            mergedWidgetsLayoutData,
            [newWidgetData],
            'i'
        );

        currentWidgetsLayout = mergedWidgetsData;

        updateWidgetsStateObject({
            items: mergedWidgetsData,
            configuredWidgetHasChanges: true,
        });
    };

    /**
     * Function that is called upon window resize.
     */
    const checkWindowSize = () => {
        setWindowWidth(window.innerWidth);
    };

    /**
     * Callback function that will be called whenever a window resize is triggered.
     * Applies debounce to keep a succeeding function from being called when resize is trigger in
     * a short span of time.
     */
    const resizeWindowHandler = useCallback(debounce(checkWindowSize, 400), []);

    useLayoutEffect(() => {
        window.addEventListener('resize', resizeWindowHandler);
        return () => window.removeEventListener('resize', resizeWindowHandler)
    }, []);

    const setEditingDashboardRedux = () => {
        const isEditting = checkIfEditingDashboard();
        dispatch(setIsEditingDashboard(isEditting));
    };

    useEffect(setEditingDashboardRedux, [
        showConditions.edittingWidgets,
        showConditions.saveToApi,
    ]);

    const checkIfEditingDashboard = () => {
        if (showConditions.edittingWidgets || showConditions.saveToApi) {
            return true;
        } else {
            return false;
        }
    };

    const isLg = getIfIsLg();
    const computedInnerWidth = isLg ? windowWidth - 80 : windowWidth - 240;
    let gridLayoutWidth = computedInnerWidth < 800 ? 800 : computedInnerWidth;

    if (menuCollapsed) {
        gridLayoutWidth += 130;
    }

    let dashboardContainerWidth: any = showConditions.edittingWidgets
        ? gridLayoutWidth - 380
        : '100%';
    if (windowWidth > gridLayoutWidth) {
        dashboardContainerWidth = showConditions.edittingWidgets
            ? gridLayoutWidth - 380
            : gridLayoutWidth;
    }

    // if (showConditions.configuringWidget && !showConditions.edittingWidgets) {
    //     dashboardContainerWidth -= 450;
    // }

    const gridLayoutHeight = window.innerHeight - 185;
    const isDraggable =
        showConditions.edittingWidgets &&
        showConditions.activeConfiguredWidget === null;

    let widgetsInitializing = widgetsState.loading;
    if (!dashboardsState.loading && isEmpty(dashboardsState.data)) {
        widgetsInitializing = false;
    }

    const EmptyDashboardsText = () => {
        // Assume this is the translated text: "未找到仪表板。点击此处[添加仪表板]以开始。"
        let translatedText: string = getTranslatedText("No dashboards found. Click this Add dashboard to get started.");

        // Split the text at the placeholder
        const parts = translatedText.split(/\[|\]/); // This splits the text into parts: before [, button text, and after ]

        return (
            <div>
                {parts[0]} {/* Text before the button */}
                <Button className="mlr-5" type="ghost" onClick={handleShowSaveDashboardModal}>
                    {parts[1]} {/* Button text */}
                </Button>
                {parts[2]} {/* Text after the button */}
            </div>
        );
    };
    
    return (
        <Col span={24} className="flex-i">
            <div className="w-100">
                <RouteLeavingGuard
                    when={checkIfEditingDashboard()}
                    navigate={(path: string) => {
                        dispatch(setIsEditingDashboard(false));
                        history.push(path);
                    }}
                    shouldBlockNavigation={() => {
                        if (checkIfEditingDashboard()) {
                            return true;
                        }
                        return false;
                    }}
                />
                <QueueAnim type={['right', 'left']} leaveReverse>
                    <Row key="title-container">
                        <Col span={24}>
                            <Title level={3}>{getTranslatedText('Dashboard')}</Title>
                        </Col>
                    </Row>
                    <div className="spacer-15" />
                    <Row key="body-container" className="transform-i">
                        <div
                            style={{
                                display: showConditions.edittingWidgets
                                    ? 'none'
                                    : 'block',
                            }}
                        >
                            <ActionBar
                                pageName={pageName}
                                loading={dashboardsState.loading}
                                setPageViewsLoading={setPageViewsLoading}
                                actionItems={[
                                    {
                                        actionKey: 'task-filter',
                                        actionType: 'select-with-button',
                                        selectValue:
                                            dashboardActiveData.selectedId,
                                        selectReadOnly:
                                            showConditions.saveToApi,
                                        selectDropdownRender:
                                            selectDashboardDropdownRenderer,
                                        onSelectChange:
                                            onSelectedDashboardChange,
                                        buttonContent: (
                                            <>
                                                <FontAwesome
                                                    icon={['fa', 'sync']}
                                                    className="mr-8"
                                                />
                                                <span>{getTranslatedText('Refresh')}</span>
                                            </>
                                        ),
                                        buttonDisabled:
                                            dashboardsState.loading ||
                                            !hasActiveData ||
                                            showConditions.saveToApi,
                                        onButtonClick: onRefreshButtonClick,
                                    },
                                    {
                                        actionKey: 'dashboard-edit',
                                        actionType: 'protected-button',
                                        allowedRoles:
                                            isEditAllowed &&
                                                !showConditions.saveToApi
                                                ? undefined
                                                : [1], // [1] - makes it not empty and not valid permission thus hiding the button
                                        buttonDisabled: !isEditAllowed,
                                        onButtonClick: onDashboarWidgetsdEdit,
                                        buttonContent: (
                                            <>
                                                <FontAwesome
                                                    icon={['fas', 'edit']}
                                                />
                                                <span>{getTranslatedText('Edit')}</span>
                                            </>
                                        ),
                                    },
                                    {
                                        actionKey: 'dashboard-save-edit',
                                        actionType: 'protected-button',
                                        buttonType: 'primary',
                                        allowedRoles:
                                            isEditAllowed &&
                                                showConditions.saveToApi
                                                ? undefined
                                                : [1], // [1] - makes it not empty and not valid permission thus hiding the button
                                        buttonDisabled: !isEditAllowed,
                                        onButtonClick: () =>
                                            onDashboardWidgetsEditEnd(),
                                        buttonContent: (
                                            <>
                                                <FontAwesome
                                                    icon={['fas', 'save']}
                                                />
                                                <span>{getTranslatedText('Save changes')}</span>
                                            </>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                        {showConditions.edittingWidgets && (
                            <div className="ta-center">
                                {getTranslatedText('Add, edit, move or resize your widgets')} &nbsp;
                                <Button
                                    type="primary"
                                    onClick={() => onDashboardWidgetsEditEnd()}
                                >
                                    {getTranslatedText('Done editing')}
                                </Button>
                            </div>
                        )}
                        <div className="spacer-15" />
                        <Spin
                            spinning={widgetsInitializing}
                            tip={getTranslatedText("Initializing widgets. . .")}
                        >
                            <Col md={24} sm={24} xs={24}>
                                <div
                                    id="dashboard-container-scrolling"
                                    className="dashboard-container"
                                    style={{
                                        height: gridLayoutHeight,
                                        width: dashboardContainerWidth,
                                        backgroundColor:
                                            showConditions.edittingWidgets
                                                ? 'rgba(128, 128, 128, 0.08)'
                                                : 'white',
                                        overflowX:
                                            showConditions.edittingWidgets
                                                ? 'auto'
                                                : 'hidden',
                                        overflowY:
                                            showConditions.configuringWidget
                                                ? 'hidden'
                                                : 'auto',
                                    }}
                                >
                                    {isEmpty(dashboardsState.data) &&
                                        !dashboardsState.loading && (
                                            <div className="empty-dashboards-text">
                                                <div>{EmptyDashboardsText()}</div>
                                            </div>
                                        )}
                                    {!isEmpty(dashboardActiveData.record) &&
                                        !showConditions.edittingWidgets &&
                                        !showConditions.saveToApi &&
                                        isEmpty(widgetsState.items) &&
                                        !dashboardsState.loading &&
                                        !widgetsState.loading && (
                                            <div className="empty-widgets-text">
                                                <div className="mb-10">
                                                    {getTranslatedText("This dashboard doesn't have widgets just yet!")}
                                                </div>
                                                <div>
                                                    <Button
                                                        type="primary"
                                                        onClick={
                                                            onDashboarWidgetsdEdit
                                                        }
                                                    >
                                                    {getTranslatedText('Add a widget')}
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    {hasActiveData &&
                                        (!isEmpty(widgetsState.items) ||
                                            showConditions.edittingWidgets) && (
                                            <GridLayout
                                                className="dashboard-grid-layout"
                                                layout={widgetsState.items}
                                                cols={12}
                                                rowHeight={dashboardRowHeight}
                                                width={gridLayoutWidth - 10}
                                                onLayoutChange={onLayoutChange}
                                                onDrop={onWidgetDrop}
                                                isDroppable={isDraggable}
                                                isResizable={isDraggable}
                                                isDraggable={isDraggable}
                                                containerPadding={[0, 0]}
                                                // margin={[0, 0]}
                                                droppingItem={{
                                                    i: get(
                                                        draggedWidget,
                                                        'i',
                                                        '__dropping-elem__'
                                                    ),
                                                    w: get(
                                                        draggedWidget,
                                                        'w',
                                                        3
                                                    ),
                                                    h: get(
                                                        draggedWidget,
                                                        'h',
                                                        3
                                                    ),
                                                }}
                                                // compactType={null}
                                                style={{
                                                    width: gridLayoutWidth - 10,
                                                    height:
                                                        gridLayoutHeight - 10,
                                                    minWidth: 1000,
                                                }}
                                            >
                                                {populateWidgets()}
                                            </GridLayout>
                                        )}
                                </div>
                            </Col>
                        </Spin>
                    </Row>
                </QueueAnim>
            </div>
            <AddWidgetsPanel
                visible={showConditions.edittingWidgets}
                onDragWidget={onWidgetDrag}
                onAddWidget={onAddWidgetClick}
            />
            <ConfigureWidgetPanel
                visible={showConditions.configuringWidget}
                onClose={onWidgetConfigurationEnd}
                onSave={(containerRef: any) =>
                    onWidgetConfigurationEnd(false, true, containerRef)
                }
                widgetDetails={showConditions.activeConfiguredWidget}
                widgetLayout={showConditions.activeConfiguredWidgetLayout}
                onWidgetConfigureUpdate={onWidgetConfigureUpdate}
                hasFormChanges={widgetsState.configuredWidgetHasChanges}
                customFieldsFilterList={customFieldsFilterList}
                isOrgView={isOrgView}
                organisationCurrenciesAll={allOrgCurrencyList}
            />

            <DashboardScheduleReportPanel
                visible={showConditions.scheduledReport}
                onClose={onCreateScheduledReportEnd}
                widgetDetails={showConditions.activeConfiguredWidget}
                widgetLayout={showConditions.activeConfiguredWidgetLayout}
                onWidgetConfigureUpdate={onWidgetConfigureUpdate}
                isOrgView={isOrgView}
                organisationCurrenciesAll={allOrgCurrencyList}
                widgetQuery={showConditions.widgetQuery}
            />

            {saveModalConditions.showModal && (
                <Suspense fallback={null}>
                    <SaveDashboardModal
                        visible={saveModalConditions.showModal}
                        handleSave={saveDashboard}
                        handleCancel={handleHideSaveDashboardModal}
                        formInitialValue={saveModalFormInitialValues}
                        allowedRolesCompany={
                            rolePermissions.DASHBOARD_EDIT_COMPANY
                        }
                        allowedRolesOrganisation={
                            rolePermissions.ORGANISATION_DASHBOARD_EDIT
                        }
                    />
                </Suspense>
            )}
            {saveModalConditions.showSubmitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Saving dashboard")}
                        modalVisible={saveModalConditions.showSubmitLoading}
                        displayMessage={getTranslatedText("Please wait while saving the dashboard . . .")}
                        containerRef={configurePanelRef}
                    />
                </Suspense>
            )}
            {showConditions.downloadToExcel && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText(populateDownloadToExcelModalTitle())}
                        modalVisible={showConditions.downloadToExcel}
                    displayMessage={getTranslatedText(populateDownloadToExcelModalDisplayMessage())}
                    />
                </Suspense>
            )}
            {previewWidgetState.visible && (
                <Suspense fallback={null}>
                    <PreviewWidgetModal
                        {...previewWidgetState}
                        onClose={onPreviewWidgetClose}
                        isOrgView={isOrgView}
                        organisationCurrenciesAll={allOrgCurrencyList}
                        downloadToExcelHandler={downloadToExcelHandler}
                    />
                </Suspense>
            )}
        </Col>
    );
};

export default withRouter(DashboardManagementPage);
