import {
    companiesSortByOptions,
    organisationUsersSortByOptions,
} from '../../constants/organisationsSortAndFilters';
import { PageData } from '../common/types';
import { Company } from '../companies/types';
import { DynamicObject } from './../../utils/commonInterfaces';
export enum OrganisationsActionTypes {
    GET_USER_ORGANISATIONS_REQUEST = '@@organisations/GET_USER_ORGANISATIONS_REQUEST',
    GET_USER_ORGANISATIONS_SUCCESS = '@@organisations/GET_USER_ORGANISATIONS_SUCCESS',
    SELECT_USER_ORGANISATION_REQUEST = '@@organisations/SELECT_USER_ORGANISATION_REQUEST',
    SELECT_USER_ORGANISATION_SUCCESS = '@@organisations/SELECT_USER_ORGANISATION_SUCCESS',

    GET_ORGANISATION_COMPANIES_REQUEST = '@@organisations/GET_ORGANISATION_COMPANIES_REQUEST',
    GET_ORGANISATION_COMPANIES_SUCCESS = '@@organisations/GET_ORGANISATION_COMPANIES_SUCCESS',
    GET_ORGANISATION_COMPANIES_ERROR = '@@organisations/GET_ORGANISATION_COMPANIES_ERROR',
    GET_ORGANISATION_COMPANIES_FOR_USER_REQUEST = '@@organisations/GET_ORGANISATION_COMPANIES_FOR_USER_REQUEST',
    GET_ORGANISATION_COMPANIES_FOR_USER_SUCCESS = '@@organisations/GET_ORGANISATION_COMPANIES_FOR_USER_SUCCESS',
    GET_ORGANISATION_COMPANIES_FOR_USER_ERROR = '@@organisations/GET_ORGANISATION_COMPANIES_FOR_USER_ERROR',
    UPDATE_ORGANISATION_COMPANIES_FILTERS = '@@organisations/UPDATE_ORGANISATION_COMPANIES_FILTERS',
    UPDATE_ORGANISATION_COMPANIES_SORT_BY = '@@organisations/UPDATE_ORGANISATION_COMPANIES_SORT_BY',
    CLEAR_ORGANISATION_COMPANIES_STATE_ALL_TABLE_FILTERS = '@@organisations/CLEAR_ORGANISATION_COMPANIES_STATE_ALL_TABLE_FILTERS',
    CLEAR_ORGANISATION_COMPANIES_STATE_DATA = '@@organisations/CLEAR_ORGANISATION_COMPANIES_STATE_DATA',
    UPDATE_ORGANISATION_COMPANIES_TABLE_FILTER = '@@organisations/UPDATE_ORGANISATION_COMPANIES_TABLE_FILTER',

    SAVE_USER_ORGANISATION_REQUEST = '@@organisations/SAVE_USER_ORGANISATION_REQUEST',
    SAVE_USER_ORGANISATION_RESPONSE = '@@organisations/SAVE_USER_ORGANISATION_RESPONSE',
    LOAD_CURRENCIES_DATA_REQUEST = '@@organisations/LOAD_CURRENCIES_DATA_REQUEST',

    GET_ORGANISATION_CUSTOM_FIELDS_FILTER_LIST = '@@organisations/GET_ORGANISATION_CUSTOM_FIELDS_FILTER_LIST',

    CLOSE_ORGANISATION_COMPANY = '@@organisations/CLOSE_ORGANISATION_COMPANY',

    GET_ORGANISATION_USERS_REQUEST = '@@organisations/GET_ORGANISATION_USERS_REQUEST',
    GET_ORGANISATION_USERS_SUCCESS = '@@organisations/GET_ORGANISATION_USERS_SUCCESS',
    GET_ORGANISATION_USERS_ERROR = '@@organisations/GET_ORGANISATION_USERS_ERROR',
    UPDATE_ORGANISATION_USERS_FILTERS = '@@organisations/UPDATE_ORGANISATION_USERS_FILTERS',
    UPDATE_ORGANISATION_USERS_SORT_BY = '@@organisations/UPDATE_ORGANISATION_USERS_SORT_BY',
    CLEAR_ORGANISATION_USERS_STATE_ALL_TABLE_FILTERS = '@@organisations/CLEAR_ORGANISATION_USERS_STATE_ALL_TABLE_FILTERS',
    CLEAR_ORGANISATION_USERS_STATE_DATA = '@@organisations/CLEAR_ORGANISATION_USERS_STATE_DATA',
    CLEAR_ORGANISATION_ACTIVE_TAB_DATA = '@@organisations/CLEAR_ORGANISATION_ACTIVE_TAB_DATA',
    UPDATE_ORGANISATION_USERS_TABLE_FILTER = '@@organisations/UPDATE_ORGANISATION_USERS_TABLE_FILTER',
    REMOVE_ORGANISATION_USERS_REQUEST = '@@organisations/REMOVE_ORGANISATION_USERS_REQUEST',

    SET_ORGANISATION_USER_SELECTED_ID_REQUEST = '@@organisations/SET_ORGANISATION_USER_SELECTED_ID_REQUEST',
    SET_ORGANISATION_USER_SELECTED_ID_SUCCESS = '@@organisations/SET_ORGANISATION_USER_SELECTED_ID_SUCCESS',
    GET_ORGANISATION_USER_DATA_REQUEST = '@@organisations/GET_ORGANISATION_USER_DATA_REQUEST',
    GET_ORGANISATION_USER_DATA_SUCCESS = '@@organisations/GET_ORGANISATION_USER_DATA_SUCCESS',
    GET_ORGANISATION_USER_DATA_ERROR = '@@organisations/GET_ORGANISATION_USER_DATA_ERROR',
    CLEAR_ORGANISATION_USER_DATA_SUCCESS = '@@organisations/CLEAR_ORGANISATION_USER_DATA_SUCCESS',
    SET_SELECTED_TAB = '@@organisations/SET_SELECTED_TAB',

    /**
     * Organisation Tasks History section
     */
}

export interface OrganisationsState {
    readonly loading: boolean;
    readonly userOrganisations: Organisation[];
    readonly selectedUserOrganisation: Organisation | undefined;
    readonly companies: {
        readonly loading: boolean;
        readonly errorMessages: string[];
        readonly data: Company[];
        readonly pageData: PageData;
        readonly filters: GetOrganisationCompaniesRequestPayload['filters'];
        readonly sortBy: GetOrganisationCompaniesRequestPayload['sortBy'];
        readonly sortAscending: boolean;
        readonly tableFilter: string | undefined;
    };
    readonly userCompanies: {
        readonly loading: boolean;
        readonly data: Company[];
    };
    readonly users: {
        readonly loading: boolean;
        readonly errorMessages: string[];
        readonly editUserLoading: boolean;
        readonly data: OrganisationUser[];
        readonly pageData: PageData;
        readonly filters: GetOrganisationUsersRequestPayload['filters'];
        readonly sortBy: GetOrganisationUsersRequestPayload['sortBy'];
        readonly sortAscending: boolean;
        readonly tableFilter: string | undefined;
        readonly activeData: {
            loading: boolean;
            record: {};
            selectedId: string | null;
            errorMessages: string[];
        };
    };
    readonly saveLoading: boolean;
    readonly activeActionTab: OrganisationActionTab | undefined;
    readonly historyActionTab: OrganisationActionTab | undefined;
}

export interface Organisation {
    readonly OrganisationId: string;
    readonly Name: string;
    readonly LogoPath: string;
    readonly DefaultCurrency: Currency;
    readonly Currencies: Currency[];
    readonly SupportedRegions: string[];
}

export interface Currency {
    readonly Name: string;
    readonly Value: string;
    readonly Rate: number;
}

export interface GetOrganisationCompaniesRequestPayload extends DynamicObject {
    callback?: (response: any) => void;
    filters: {};
    sortBy: typeof companiesSortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    tableFilter?: string;
    includeTotalUser?: boolean;
}

export interface OrganisationUser {
    readonly User: {
        UserId: string;
        GivenName: string;
        FamilyName: string;
        Email: string;
        // PhoneNumber: string;
        // CreatedDateTime: string;
    };
    readonly IsAdminRole: boolean;
    readonly CompanyNames?: string[];
    readonly CreatedDateTime?: string;
}

export interface GetOrganisationUsersRequestPayload {
    filters: DynamicObject;
    sortBy?: typeof organisationUsersSortByOptions[number]['value'];
    sortAscending?: boolean;
    pageSize?: number;
    currentPage?: number;
}

export interface OrganisationActionTab {
    tabName: string,
    region: string,
    usingCustomerWorkflow: boolean,
    companies: {
        CompanyId: string, 
        Name: string,
        IsCalendarView: boolean
    } []
}
