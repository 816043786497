/**
 * Component for populating the details drawer.
 */

import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { isEmpty, map, omitBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import { SmsCustomizationItem, SmsProviderOptions } from '../../store/smsProviders/types';
import { generateGUID } from '../../utils/commonFunctions';
import FontAwesome from '../common/FontAwesome';

const FormItem = Form.Item;
const DEFAULT_TELSTRA_URL = "https://products.api.telstra.com/messaging/v3/messages";
const DEFAULT_TELSTRA_OAUTH_URL = "https://products.api.telstra.com/v2/oauth/token";
const DEFAULT_OPTUS_URL = "https://api.sms.optus.com.au/rest/gateway/messages";

interface IProps {
    readonly visible: boolean;
    readonly closeDrawer: () => void;
    readonly addSmsCustomizationItem: (item: SmsCustomizationItem) => void | undefined;
    readonly record: DynamicObject;
    readonly form: any;
    readonly isAddNew: boolean | undefined;
}

const SmsProviderItemDetailsComponent: React.FC<IProps> = ({
    visible,
    closeDrawer,
    addSmsCustomizationItem,
    record,
    form,
    isAddNew,
}: IProps) => {
    const basicAuthUserNameRef: any = useRef(null);
    const basicAuthPasswordRef: any = useRef(null);
    const clientIdRef: any = useRef(null);
    const clientSecretRef: any = useRef(null);
    const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue } = form;
    const { Name, Type, BasicAuthUserName, BasicAuthPassword, ClientId, ClientSecret, Url, OauthUrl, SenderNumber } = record;
    const { Option } = Select;
    
    const allValues = omitBy(getFieldsValue(), isEmpty);
    const isSaveBtnDisabled = isEmpty(allValues);
    
    const handleFormSave = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        validateFields((err: any, item: SmsCustomizationItem) => {
            if (!err) {
                addSmsCustomizationItem({
                    ...item,
                    Id: generateGUID()
                });
            }
        });
        closeDrawer();
    }; 
    
    /**
    * Function that resets the field values if drawer is closed.
    */
   const initializeValues = () => {
       if (visible === false) {
           resetFields();
       }
   };

   const handleCancelClick = () => {
        closeDrawer();
   };

   useEffect(initializeValues, [visible]);

   const populateSmsProviderOptions = () =>
        map(
            Object.keys(SmsProviderOptions).map(key => ({
                value: SmsProviderOptions[key as keyof typeof SmsProviderOptions],
                label: key
              })),
              ({ label, value }: { label: string; value: string }) => (
                <Option key={value} value={label}>
                    {label}
                </Option>
            )
    );
    
    /**
     * Function that handles copying the token value to clipboard.
     * @param event
     */
    const copyApplicationNameToClipboard = (event: any) => {
        copyToClipboard(event, basicAuthUserNameRef, 'Application Name')
    }

    const copyBasicAuthPasswordToClipboard = (event: any) => {
        copyToClipboard(event, basicAuthPasswordRef, 'Application Password')
    }

    const copyClientIdToClipboard = (event: any) => {
        copyToClipboard(event, basicAuthUserNameRef, 'Client Id')
    }

    const copyClientSecretToClipboard = (event: any) => {
        copyToClipboard(event, basicAuthPasswordRef, 'Client Secret')
    }

    const copyToClipboard = (event: any, boxRef: any, currentInput: string) => {
        if (boxRef && boxRef.current) {
            navigator.clipboard.writeText(boxRef.current.input.value).then(() => {
                Modal.success({
                    zIndex: 1400,
                    title: 'Success',
                    content: `${currentInput} copied to the clipboard!`,
                });
              }).catch((err) => {
                console.error('Failed to copy text: ', err);
              });
        }
    };

    const RenderPrivateInput = (ref: any, onClickAction: any) => {
        return (
            <Input 
                ref={ref}
                type={isAddNew ? 'text' : 'password'}
                disabled={!isAddNew}
                addonAfter={
                    !isAddNew && (
                        <div
                            className="cursor-p-div"
                            onClick={
                                onClickAction
                            }
                        >
                            <FontAwesome
                                icon={['fa', 'copy']}
                                className="blueCustom"
                            />
                        </div>)
                }
            />
        )
    }

    const currentFieldTypeValue = Type || getFieldValue("Type");

    function InitUrlValue() {
        if (!isAddNew) return Url;
        if (currentFieldTypeValue == SmsProviderOptions.Optus) return DEFAULT_OPTUS_URL;
        if (currentFieldTypeValue == SmsProviderOptions.Telstra) return DEFAULT_TELSTRA_URL;
        return null;
    }

    return (
        <Form className="form-lbl-lh-24" colon={false}>
            <Row type="flex" align="middle">
                <Col span={24}>
                    <FormItem label="Name">
                        {getFieldDecorator('Name', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Name required!',
                                },
                            ],
                            initialValue: Name,
                        })(
                            <Input disabled={!isAddNew}/>
                        )}
                    </FormItem>
                </Col>
            </Row>
            <Row type="flex" align="middle">
                <Col span={24}>
                    <FormItem label="Type">
                        {getFieldDecorator('Type', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Type required!',
                                },
                            ],
                            initialValue: Type,
                        })(<Select disabled={!isAddNew}>
                            {populateSmsProviderOptions()}
                          </Select>)}
                    </FormItem>
                </Col>
            </Row>
            <Row type="flex" align="middle">
                <Col span={24}>
                    <FormItem label="URL">
                        {getFieldDecorator('Url', {
                            rules: [
                                {
                                    required: true,
                                    message: 'URL required!',
                                },
                            ],
                            initialValue: InitUrlValue(),
                        })(<Input disabled={!isAddNew} />)}
                    </FormItem>
                </Col>
            </Row> 
            {
                currentFieldTypeValue === SmsProviderOptions.Optus && (
                    <>
                        <Row type="flex" align="middle">
                            <Col span={24}>
                                <FormItem label="Application name">
                                    {getFieldDecorator('BasicAuthUserName', {
                                        rules: [
                                            {
                                                required: currentFieldTypeValue === SmsProviderOptions.Optus,
                                                message: 'Application name required!',
                                            },
                                        ],
                                        initialValue: BasicAuthUserName,
                                    })(
                                        RenderPrivateInput(basicAuthUserNameRef, copyApplicationNameToClipboard)
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row type="flex" align="middle">
                            <Col span={24}>
                                <FormItem label="Application password">
                                    {getFieldDecorator('BasicAuthPassword', {
                                        rules: [
                                            {
                                                required: currentFieldTypeValue === SmsProviderOptions.Optus,
                                                message: 'Application password required!',
                                            },
                                        ],
                                        initialValue: BasicAuthPassword,
                                    })(RenderPrivateInput(basicAuthPasswordRef, copyBasicAuthPasswordToClipboard))
                                    }
                                </FormItem>
                            </Col>
                        </Row>
                    </>
                )
            }
            {
                currentFieldTypeValue === SmsProviderOptions.Telstra && (
                    <>
                        <Row type="flex" align="middle">
                            <Col span={24}>
                                <FormItem label="Oauth URL">
                                    {getFieldDecorator('OauthUrl', {
                                        rules: [
                                            {
                                                required: currentFieldTypeValue === SmsProviderOptions.Telstra,
                                                message: 'Oauth Url required!',
                                            },
                                        ],
                                        initialValue: isAddNew ? DEFAULT_TELSTRA_OAUTH_URL : OauthUrl,
                                    })(<Input disabled={!isAddNew} />)}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row type="flex" align="middle">
                            <Col span={24}>
                                <FormItem label="Sender phone number">
                                    {getFieldDecorator('SenderNumber', {
                                        rules: [
                                            {
                                                required: currentFieldTypeValue === SmsProviderOptions.Telstra,
                                                message: 'Sender phone number required!',
                                            },
                                        ],
                                        initialValue: SenderNumber,
                                    })(<Input disabled={!isAddNew} />)}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row type="flex" align="middle">
                            <Col span={24}>
                                <FormItem label="Client id">
                                    {getFieldDecorator('ClientId', {
                                        rules: [
                                            {
                                                required: currentFieldTypeValue === SmsProviderOptions.Telstra,
                                                message: 'Client id required!',
                                            },
                                        ],
                                        initialValue: ClientId,
                                    })(
                                        RenderPrivateInput(clientIdRef, copyClientIdToClipboard)
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row type="flex" align="middle">
                            <Col span={24}>
                                <FormItem label="Client secret">
                                    {getFieldDecorator('ClientSecret', {
                                        rules: [
                                            {
                                                required: currentFieldTypeValue === SmsProviderOptions.Telstra,
                                                message: 'Client secret required!',
                                            },
                                        ],
                                        initialValue: ClientSecret,
                                    })(RenderPrivateInput(clientSecretRef, copyClientSecretToClipboard))
                                    }
                                </FormItem>
                            </Col>
                        </Row>
                    </>
                )
            }
            <br />
            {
                isAddNew && (
                    <div className="mt-10 ta-right">
                        <Button
                            className="mr-10"
                            onClick={handleFormSave}
                            disabled={isSaveBtnDisabled}
                            type="primary"
                        >
                            Create
                        </Button>
                        <Button
                            className="buttonGrey"
                            onClick={handleCancelClick}
                        >
                            Cancel
                        </Button>
                    </div>
                )
            }
        </Form>
    );
};

const SmsProviderItemDetailsComponentForm = Form.create({
    name: 'sms-provider-view-form',
})(SmsProviderItemDetailsComponent);

export default withRouter(
    withDateFormatHandler(SmsProviderItemDetailsComponentForm)
);
