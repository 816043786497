/**
 * Function for populating a button that triggers a drawer component.
 */

import { Button, Checkbox, Col, Modal, Row, Select, Spin } from 'antd';
import { capitalize, filter, find, forEach, get, includes, isEmpty, sum, toLower } from 'lodash';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { populatePopoverContainer, getTranslatedText } from '../../../utils/commonFunctions';
import { getRolePermissions } from '../../../store/roles/sagas';
import { taskHistoryAutomation, taskHistoryBatchTypes } from '../../../constants/tasksSortAndFilters';
import { getCustomerUILabel } from '../../../store/customers/sagas';
import TaskResolveCustomerAssistanceTicketPanelContent from './TaskResolveCustomerAssistanceTicketPanelContent';
import TaskPaymentScheduleActionPanelContent from '../../../components/tasks/active_tasks/TaskPaymentPlanActionPanelContent';
import TaskActionPaymentBehaviorInsightContent from '../../../components/tasks/active_tasks/TaskActionPaymentBehaviorInsightContent';
import { TasksState } from '../../../store/tasks/types';
import { DynamicObject } from '../../../utils/commonInterfaces';
import { getResolveTicketOptionsRequestAction } from '../../../store/tickets/actions';
import { WorkflowTransition } from '../../../store/tickets/types';
import { actionOrganisationPaymentPlanRequestAction, actionOrganisationPaymentPlanTasks, actionPaymentPlanRequestAction, actionPaymentPlanTasks } from '../../../store/paymentPlans/actions';
import TaskEscalateCollectionsPanel from './TaskEscalateCollectionsPanel';
import TaskActionManuallyAllocatePaymentPanelOptionsContent from './TaskActionManuallyAllocatePaymentPanelOptionsContent';
import { getTriggersForWorkflowRequestAction, notifyCustomersRequestAction, notifyOrganisationCustomersRequestAction } from '../../../store/tasks/actions';
import { CompanyUserRole } from '../../../store/companies/types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { confirmModalCancelText, confirmModalOkText } from '../../../config/config';
import ModalWithSpinner from '../../common/ModalWithSpinner';
import AvatarNameInitialsComponent from '../../common/AvatarNameInitialsComponent';
import { getViewingUser } from '../../../store/tasks/sagas';
import { getCurrentUser } from '../../../store/users/sagas';
import { IsOrganisationViewAttribute, OrganisationRoleIdAttribute, SubAttribute } from '../../../constants/authUserAttributes';
import TaskPhoneCallDrawerContent from './TaskPhoneCallDrawerContent';
import { ContactOptions } from '../../../store/common/types';
import TaskActionRemittanceAdvicePanelOptionContent from './TaskActionRemittanceAdvicePanelOptionContent';
import TaskActionPaymentPanelOptionContent from './TaskActionPaymentPanelOptionContent';


const Drawer = lazy(() => import('antd/lib/drawer'));

const { confirm } = Modal;
const { Option } = Select;

interface IProps {
    readonly zIndex?: number;
    readonly containerRef?: any;
    readonly popoverVisible?: boolean;
    readonly selectedType?: string;
    readonly selectedAutomation?: string;
    readonly selectedTaskId?: string;
    readonly selectedTaskIds: string[];
    readonly getSelectedTasksValues?: any;
    readonly filterObject?: DynamicObject;
    readonly onClose?: (refresh?: boolean) => void;
    readonly onNotifyCustomerResponseOk?: () => void;
}

const TaskActionDrawer: React.FC<IProps> = (props: IProps) => {
    const {
        zIndex,
        containerRef,
        popoverVisible,
        selectedType,
        selectedAutomation,
        selectedTaskId,
        selectedTaskIds,
        filterObject,
        onClose
    } = props
    let { getSelectedTasksValues } = props;
    const dispatch = useDispatch();
    const [taskActionDrawerTitle, setTaskActionDrawerTitle] = useState<string>('');

    const currentUser = useSelector(getCurrentUser);
    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
    const currentUserId: string = get(currentUser, SubAttribute);
    
    const roleList = useSelector(
        (state: ApplicationState) => state.roles.allRoles
    );
    
    const orgUserRoleId = get(currentUser, OrganisationRoleIdAttribute);
    const orgUserRole = find(roleList, ['RoleId', orgUserRoleId]);
    let userRole = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Role.Name')
    );
    if (isOrgView) {
        userRole = get(orgUserRole, 'Name');
    }

    const customerLabel = useSelector(getCustomerUILabel);
    const tasksState: TasksState['activeTasks'] = useSelector(
        (state: ApplicationState) => state.tasks.activeTasks
    );
    const rolePermissions = useSelector(getRolePermissions);
    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );
    
    const allowedRoles = rolePermissions.TASK_ACTION_SEND_NOTIFICATION;
    let lockedDeliveryMethod = get(
        selectedUserCompany,
        'Company.LockedDeliveryMethod'
    );

    const communicationMethod = get(
        selectedUserCompany,
        'Company.CommunicationMethod'
    );

    // communicationMethod has high priority
    if (communicationMethod !== undefined || communicationMethod !== null) {
        lockedDeliveryMethod = false;
    }

    const { loading: viewingUserLoading, user: viewingUser } = useSelector(getViewingUser);
    const viewerGivenName = get(viewingUser, 'GivenName');
    const viewerFamilyName = get(viewingUser, 'FamilyName'); 

    getSelectedTasksValues = getSelectedTasksValues || (() => {
        return {
            allExcept: false,
            keysToUse: selectedTaskIds,
            filterObject: filterObject
        };
    });

    const getActionOptions = (updateActionOptionsCallback: (settings: DynamicObject) => void) => {
        updateActionOptionsCallback({
            loading: true,
        });
        const taskList = tasksState.data;
        const taskRecord = get(filter(taskList, ['Id', selectedTaskId]), 0);
        const companyId = isOrgView ? get(taskRecord, 'CompanyId') : get(selectedUserCompany, 'Company.CompanyId');

        dispatch(
            getResolveTicketOptionsRequestAction(
                get(taskRecord, 'Workflow.WorkflowId'),
                get(taskRecord, 'Workflow.StateName'),
                companyId,
                (res: WorkflowTransition[]) => {
                    const options: string[] = [];
                    const workflowTransitionList: DynamicObject = {};
                    forEach(res, (r: WorkflowTransition) => {
                        const trigger = get(r, 'Trigger');
                        if (!includes(options, trigger)) {
                            options.push(trigger);
                        }
                        workflowTransitionList[trigger] = r;
                    });
                    updateActionOptionsCallback({
                        options,
                        workflowTransitionList,
                        loading: false,
                    });
                }
            )
        );
    };

    const getActionPaymentAllocationOptions = (updateActionOptionsCallback: (settings: DynamicObject) => void) => {
        updateActionOptionsCallback({
            loading: true,
        });
        const taskList = tasksState.data;
        const taskRecord = get(filter(taskList, ['Id', selectedTaskId]), 0);
        const companyId = isOrgView ? get(taskRecord, 'CompanyId') : get(selectedUserCompany, 'Company.CompanyId');
        dispatch(
            getTriggersForWorkflowRequestAction(
                get(taskRecord, 'Workflow.WorkflowId'),
                get(taskRecord, 'Workflow.StateName'),
                companyId,
                (res: WorkflowTransition[]) => {
                    const options: string[] = [];
                    const workflowTransitionList: DynamicObject = {};
                    forEach(res, (r: WorkflowTransition) => {
                        const trigger = get(r, 'Trigger');
                        if (!includes(options, trigger)) {
                            options.push(trigger);
                        }
                        workflowTransitionList[trigger] = r;
                    });
                    updateActionOptionsCallback({
                        options,
                        workflowTransitionList,
                        loading: false,
                    });
                }
            )
        );
    };

    const dispatchActionPaymentPlanTasks = (payload: DynamicObject) => {
        const { allExcept, keysToUse, filterObject } = getSelectedTasksValues();
        const taskAction = isOrgView ? actionOrganisationPaymentPlanTasks : actionPaymentPlanTasks
        dispatch(taskAction({
            filter: filterObject,
            taskIds: keysToUse,
            excludeTasks: allExcept,
            ...payload
        }));
    };

    const dispatchActionSinglePaymentPlanTask = (payload: DynamicObject) => {
        const taskAction = isOrgView ? actionOrganisationPaymentPlanRequestAction : actionPaymentPlanRequestAction;
        dispatch(taskAction({
            Task: {
                Id: selectedTaskId,
            },
            ...payload
        }));
    };

    const getActionDrawerTitleMessageNoteBasedOnContactType = (customCommunication?: any) => {
        let contactTypeSum = sum(get(tasksState, 'filters.ContactType'));

        const isHaveComMethod = communicationMethod !== undefined && communicationMethod !== null;
        const isHaveCustomCom = customCommunication !== undefined && customCommunication !== null && customCommunication !== 0;

        if (isHaveComMethod) {
            contactTypeSum = communicationMethod;
        }

        if (isHaveCustomCom) {
            contactTypeSum = customCommunication;
        }

        let title = 'Send email, SMS, and postal letter';

        if (isHaveComMethod && !isHaveCustomCom) {
            title = 'Send email, SMS, and postal letter - company settings';
        }

        let message = `Each ${toLower(
            customerLabel
        )} is going to be notified using the best combination of email, sms, and postal letter.`;
        let note = `** You are notified when the postal letters are ready for printing.`;
        if (lockedDeliveryMethod) {
            message = `Each ${toLower(
                customerLabel
            )} is going to be notified using the best contact methods available for the task.`;
        } else {
            if (contactTypeSum === 3) {
                title = 'Send email and SMS only';

                if (isHaveComMethod && !isHaveCustomCom) {
                    title = 'Send email and SMS only - company settings';
                }

                message = `Use the best combination of email and sms for each ${toLower(
                    customerLabel
                )}.`;
                note = '';
            } else if (contactTypeSum === 5) {
                title = 'Send email and postal letter only';

                if (isHaveComMethod && !isHaveCustomCom) {
                    title = 'Send email and postal letter only - company settings';
                }

                message = `Each ${toLower(customerLabel)} is going to be notified using the best combination of email and postal letter, with a preference to email.`;
            } else if (contactTypeSum === 6) {
                title = 'Send SMS and postal letter only';

                if (isHaveComMethod && !isHaveCustomCom) {
                    title = 'Send SMS and postal letter only - company settings';
                }

                message = `Each ${toLower(customerLabel)} is going to be notified using the best combination of SMS and postal letter, with a preference to SMS.`;
            } else if (contactTypeSum === 1) {
                title = 'Send email only';

                if (isHaveComMethod && !isHaveCustomCom) {
                    title = 'Send email only - company settings';
                }

                message = `Only send emails to each ${toLower(customerLabel)}.`;
                note = '';
            } else if (contactTypeSum === 2) {
                title = 'Send SMS only';

                if (isHaveComMethod && !isHaveCustomCom) {
                    title = 'Send SMS only - company settings';
                }

                message = `Only send SMS to each ${toLower(customerLabel)}.`;
                note = '';
            } else if (contactTypeSum === 4) {
                title = 'Send postal letters only';

                if (isHaveComMethod && !isHaveCustomCom) {
                    title = 'Send postal letters only - company settings';
                }

                message = `Only produce letters for each ${toLower(customerLabel)}.`;
                note = '** You are required to print and send these letters. You are notified when the postal letters are ready for printing.';
            } else if (contactTypeSum === 8) {
                title = 'No communication';

                if (isHaveComMethod && !isHaveCustomCom) {
                    title = 'No communication - company settings';
                }

                message = `No communication will be made to each ${toLower(customerLabel)}.`;
                note = '';
            }
        }

        return {
            title,
            message,
            note,
        };
    };

    const getActionPopoverTitleAndContent = () => {
        if (!popoverVisible) {
            return {
                actionTitle: '',
                actionContent: '',
            };
        } else {
            if (
                selectedType ===
                taskHistoryBatchTypes.Ticket
            ) {
                const { keysToUse } = getSelectedTasksValues();

                return {
                    actionTitle: `Action ${toLower(
                        customerLabel
                    )} assistance ticket`,
                    actionContent: (
                        <TaskResolveCustomerAssistanceTicketPanelContent
                            visible={popoverVisible}
                            closePanel={onClose}
                            selectedTicketTaskId={keysToUse.length === 1 ? selectedTaskId : null}
                            getSelectedTasksValues={getSelectedTasksValues}
                            customerLabel={customerLabel}
                            containerRef
                        />
                    ),
                };
            } else if (
                selectedType ===
                taskHistoryBatchTypes.PaymentSchedule &&
                selectedAutomation ===
                taskHistoryAutomation.Manual
            ) {
                return {
                    actionTitle: `Complete a payment plan action`,
                    actionContent: (
                        <TaskPaymentScheduleActionPanelContent
                            visible={popoverVisible}
                            closePanel={onClose}
                            getActionOptions={getActionOptions}
                            dispatchActionPaymentPlanTask={dispatchActionSinglePaymentPlanTask}
                            includeCommentVisible={true}
                        />
                    ),
                };
            } else if (
                selectedType === taskHistoryBatchTypes.PaymentPlan
                &&
                selectedAutomation === taskHistoryAutomation.Manual
            ) {
                return {
                    actionTitle: `Complete a payment plan action`,
                    actionContent: (
                        <TaskPaymentScheduleActionPanelContent
                            visible={popoverVisible}
                            closePanel={onClose}
                            getActionOptions={getActionOptions}
                            dispatchActionPaymentPlanTask={dispatchActionPaymentPlanTasks}
                            includeCommentVisible={false}
                        />
                    ),
                };
            } else if (
                selectedType ===
                taskHistoryBatchTypes.Collection
            ) {
                return {
                    actionTitle: 'Escalate to collections',
                    actionContent: (
                        <TaskEscalateCollectionsPanel
                            visible={popoverVisible}
                            closePanel={onClose}
                            getSelectedTasksValues={getSelectedTasksValues}
                        />
                    ),
                };
            } else if (
                selectedType ===
                taskHistoryBatchTypes.PaymentVerification
            ) {
                // return {
                //     actionTitle: 'Complete payment information',
                //     actionContent: (
                //         <TaskActionPaymentPanelContent
                //             visible={popoverVisible}
                //             closePanel={onClose}
                //             selectedTaskId={selectedTaskId}
                //             customerLabel={customerLabel}
                //         />
                //     ),
                // };
                const {keysToUse} = getSelectedTasksValues();
                return {
                    actionTitle: 'Action payment information',
                    actionContent: (
                        <TaskActionPaymentPanelOptionContent
                            visible={popoverVisible}
                            closePanel={onClose}
                            getSelectedTasksValues={getSelectedTasksValues}
                            selectedTaskId={keysToUse.length == 1 ? selectedTaskId : null}
                            customerLabel={customerLabel}
                            containerRef
                        />
                    ),
                };
            } else if (
                selectedType ===
                taskHistoryBatchTypes.PaymentManualAllocation
            ) {
                // Get Payment from taskList 
                const taskList = tasksState.data || [];
                const paymentVerificationStatus = taskList.filter(task => task.Payment !== null && task.Payment !== undefined 
                                                        && task.Type === 'PaymentManualAllocation'
                                                        && selectedTaskIds.includes(task.Id))
                                              .map(task => task.Payment.VerificationState);
                
        
                const paymentHasMultipleCustomer = taskList.filter(task => task.Payment !== null && task.Payment !== undefined 
                    && task.Type === 'PaymentManualAllocation'
                    && selectedTaskIds.includes(task.Id)
                    && task.Payment.Customer === null);

                const taskRecord = get(filter(taskList, ['Id', selectedTaskId]), 0);

                return {
                    actionTitle: 'Allocate payment information',
                    actionContent: (
                        <TaskActionManuallyAllocatePaymentPanelOptionsContent
                            visible={popoverVisible}
                            closePanel={onClose}
                            getActionOptions={getActionPaymentAllocationOptions}
                            getSelectedTasksValues={getSelectedTasksValues}
                            selectedTaskId={selectedTaskId}
                            selectedTaskIds={selectedTaskIds}
                            customerLabel={customerLabel}
                            paymentVerificationStatus={paymentVerificationStatus}
                            hasMultipleCustomer={paymentHasMultipleCustomer.length > 0}
                            taskRecord={taskRecord}
                        />
                    ),
                };
            } else if (
                selectedType ===
                taskHistoryBatchTypes.RemittanceAdvice
            ) {
                const {keysToUse} = getSelectedTasksValues();
                return {
                    actionTitle: 'Action remittance information',
                    actionContent: (
                        <TaskActionRemittanceAdvicePanelOptionContent
                            visible={popoverVisible}
                            closePanel={onClose}
                            getSelectedTasksValues={getSelectedTasksValues}
                            selectedTaskId={keysToUse.length == 1 ? selectedTaskId : null}
                            containerRef
                        />
                    ),
                    //drawerWidth: 1400
                };
            } else if (
                selectedType ===
                taskHistoryBatchTypes.PaymentBehaviourInsights
            ) {
                const taskList = tasksState.data;
                const taskRecord = get(filter(taskList, ['Id', selectedTaskId]), 0);

                return {
                    actionTitle: `Payment behavior insights - ${get(taskRecord, 'Workflow.StateName')}`,
                    actionContent: (
                        <TaskActionPaymentBehaviorInsightContent
                            visible={popoverVisible}
                            closePanel={onClose}
                            selectedTaskId={selectedTaskId}
                        />
                    ),
                    drawerWidth: 500
                };
            } else if (
                selectedType ===
                taskHistoryBatchTypes.PhoneCall
            ) {
                const taskList = tasksState.data;
                const taskRecord = get(filter(taskList, ['Id', selectedTaskId]), 0);

                return {
                    actionTitle: `Phone call`,
                    actionContent: <TaskPhoneCallDrawerContent
                        visible={popoverVisible}
                        cancelVisible={true}
                        closePanel={onClose}
                        taskRecord={taskRecord}
                        getSelectedTasksValues={getSelectedTasksValues}
                    />
                };
            } else {
                const { title, message, note } =
                    getActionDrawerTitleMessageNoteBasedOnContactType();

                return {
                    actionTitle: getTranslatedText(title),
                    actionContent: <SendNotificationPanelContent
                        {...props}
                        message={getTranslatedText(message)}
                        note={getTranslatedText(note)}
                        getActionDrawerTitleMessageNote={getActionDrawerTitleMessageNoteBasedOnContactType}
                        setTaskActionDrawerTitle = {setTaskActionDrawerTitle}
                        getSelectedTasksValues={getSelectedTasksValues}
                    />,
                };
            }
        }
    };

    const populateNotifyCustomerRequestModalTitle = () =>
        `Sending ${toLower(customerLabel)} notifications`;

    const populateNotifyCustomerRequestModalDisplayMessage = () =>
        `Please wait while sending the notifications. . .`;

    const populateDrawerContent = () => { 
        const { actionContent, actionTitle, drawerWidth } = getActionPopoverTitleAndContent();

        if (isEmpty(allowedRoles) || includes(allowedRoles, userRole)) {
            return (
                <Suspense fallback={<Spin />}>
                    <Drawer
                        visible={popoverVisible}
                        getContainer={populatePopoverContainer(containerRef)}
                        closable={false}
                        title={<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>{getTranslatedText(taskActionDrawerTitle || actionTitle)}</div>
                            {!viewingUserLoading && viewingUser && get(viewingUser, 'UserId') !== currentUserId
                                && <div>
                                    <AvatarNameInitialsComponent
                                        fullName={`${viewerGivenName} ${viewerFamilyName}`}
                                        size={40}
                                        fontSize={16}
                                        showTooltip={true}
                                    />
                                </div>
                            }
                        </div>}
                        zIndex={zIndex}
                        width={drawerWidth || 420}
                        className="protected-drawer"
                    >
                        {popoverVisible && actionContent}
                    </Drawer>
                </Suspense>
            );
        } else {
            return null;
        }
    };

    return <>
        {populateDrawerContent()}
        {tasksState.notifyCustomersLoading && (
            <Suspense fallback={null}>
                <ModalWithSpinner
                    modalTitle={getTranslatedText(populateNotifyCustomerRequestModalTitle())}
                    modalVisible={tasksState.notifyCustomersLoading}
                    displayMessage={getTranslatedText(populateNotifyCustomerRequestModalDisplayMessage())}
                />
            </Suspense>
        )}
    </>
};

export default TaskActionDrawer;

interface ISendNotificationPanelContentProps extends IProps {
    message: string,
    note: string,
    getActionDrawerTitleMessageNote: (customCommunication: number) => {
        title: string, 
        message: string, 
        note: string
    },
    setTaskActionDrawerTitle: (title: string) => void
}

const SendNotificationPanelContent: React.FC<ISendNotificationPanelContentProps> = ({
    message, note, getActionDrawerTitleMessageNote, setTaskActionDrawerTitle, selectedTaskIds,
    getSelectedTasksValues,
    onNotifyCustomerResponseOk,
    onClose,
}) => {
    const dispatch = useDispatch();
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const customerLabel = useSelector(getCustomerUILabel);
    const [certifyNotPaid, setCertifyNotPaid] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState(message);
    const [notificationNote, setNotificationNote] = useState(note);
    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );
    const currentUser = useSelector(getCurrentUser);
    
    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';

    const isPaymentPlanEnabled = get(
        selectedUserCompany,
        'Company.CompanyPaymentPlan.IsEnabled'
    );

    useEffect(() => {
        const customCommunication = sum(selectedItems);
        const { title, message, note } = getActionDrawerTitleMessageNote(customCommunication);
        setNotificationMessage(message);
        setNotificationNote(note);
        setTaskActionDrawerTitle(title);
    }, [selectedItems])

    const onCertifyNotPaidChange = (e: CheckboxChangeEvent) => {
        setCertifyNotPaid(e.target.checked);
    };

    const notifyCustomers = () => {
        const { allExcept, keysToUse, filterObject, sortBy, sortAscending, recordLimit } = getSelectedTasksValues();
        const notifyCustonerAction = isOrgView ? notifyOrganisationCustomersRequestAction : notifyCustomersRequestAction

        confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText('Confirm'),
            content: getTranslatedText(`Are you sure you want to send ${toLower(customerLabel)}s notification ${selectedTaskIds.length > 1 ? 'these tasks' : 'this task'}?`),
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
            onOk() {
                dispatch(
                    notifyCustonerAction({
                        filter: filterObject,
                        taskIds: keysToUse,
                        excludeTasks: allExcept,
                        callback: notifyCustomersResponseModal,
                        sortBy,
                        sortAscending,
                        recordLimit,
                        isPaymentPlanEnabled,
                        isCommunicationSelectedByUser: selectedItems.length > 0 ? true : undefined,
                        selectedContact: selectedItems.length > 0 ? selectedItems.reduce((acc, curr) => acc + curr, 0) : undefined
                    })
                );
            },
        });
    };

    const notifyCustomersResponseModal = ({
        IsSuccess,
    }: {
        IsSuccess: boolean;
    }) => {
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: getTranslatedText(`${capitalize(
                    customerLabel
                )}s notification sent successfully!`),
                onOk: () => {
                    onNotifyCustomerResponseOk && onNotifyCustomerResponseOk();
                    setCertifyNotPaid(false);
                },
                okText: getTranslatedText('OK'),
            });
        } else {
            Modal.error({
                title: getTranslatedText('Error'),
                content: (
                    <div>
                        <div>{getTranslatedText(`Failed to notify ${toLower(customerLabel)}s!`)}</div>
                    </div>
                ),
                onOk: () => {
                    setCertifyNotPaid(false);
                },
            });
        }
    };

    const handleChange = (values: any) => {
        const numberValues = values.map(Number);
        let updatedValues: number[] = [];
        let unselected: boolean = false;

        // unselected the list item
        if (!numberValues.includes(8)) {
            if (selectedItems.some(item => !numberValues.includes(item))) {
                updatedValues = numberValues;
                unselected = true;
            }
        }

        if (!unselected) {
            numberValues.forEach((value: number) => {
                const intValue = value;

                if (intValue === 8) {
                    updatedValues = selectedItems.includes(8) ? [] : [8];
                } else {
                    updatedValues = selectedItems.includes(intValue)
                        ? selectedItems.filter((item) => item !== intValue)
                        : [...selectedItems.filter((item) => item !== 8), intValue];
                }
            });
        }

        setSelectedItems(updatedValues);
    };

    return (
        <div className="pa-5">
            <div className="mb-10">
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Select communication method"
                    onChange={handleChange}
                    value={selectedItems.map(String)} // Convert numbers to strings for Select
                >
                    {ContactOptions.map(contact => (
                        <Option key={contact.value} value={String(contact.value)}>{getTranslatedText(contact.label)}</Option>
                    ))}
                </Select>
            </div>
            <div className="mb-10">{getTranslatedText(message)}</div>
            <div className="mb-10">{getTranslatedText(note)}</div>
            <div className="mb-10 mt-10">
                {getTranslatedText('For information on how to change what communication is used')},{' '}
                <a
                    href="https://www.manula.com/manuals/iodm-limited/iodm/connect/en/topic/actioning-tasks"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {getTranslatedText('click here')}
                </a>
                .
            </div>
            <div>
                <div className="mb-10">
                    {getTranslatedText('To satisfy compliance needs, you must click on the tick box')}
                </div>
                <div className="mb-10">
                    <Checkbox
                        onChange={onCertifyNotPaidChange}
                        checked={certifyNotPaid}
                    >
                        <span className="grey">
                            {getTranslatedText(`To the best of my knowledge I certify that these ${toLower(customerLabel)} accounts have not been paid, are not disputed, and have the correct contact details.`)}
                        </span>
                    </Checkbox>
                </div>
                <div className="mt-10 ta-right">
                    <Button
                        className={`${certifyNotPaid ? 'buttonBlue' : ''
                            } mr-10`}
                        onClick={notifyCustomers}
                        disabled={!certifyNotPaid}
                        type="primary"
                    >
                        {getTranslatedText(`Notify ${capitalize(customerLabel)}s`)}
                    </Button>
                    <Button
                        className="buttonGrey"
                        onClick={() => {
                            onClose && onClose();
                        }}
                    >
                        {getTranslatedText('Cancel')}
                    </Button>
                </div>
            </div>
        </div>
    );
}