import { Col, Row, Tabs, Typography } from 'antd';
import {
    capitalize, find, get, isEmpty, isUndefined, map
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QuickSearchComponent, {
    qsFilterNames,
} from '../../../components/common/QuickSearchComponent';
import { CUSTOM_FIELD_TYPES } from '../../../config/tableAndPageConstants';
import '../../../less/compact.less';
import { ApplicationState } from '../../../store';
import { getCustomFieldsFilterListByTypesForOrganisation } from '../../../store/common/actions';
import { getCustomerUILabel } from '../../../store/customers/sagas';
import { TasksState } from '../../../store/tasks/types';

import OrganisationActiveTasksContent from '../../../components/tasks/active_tasks/OrganisationActiveTasksContent';
import { Organisation, OrganisationActionTab, OrganisationsState } from '../../../store/organisations/types';
import { IsOrganisationViewAttribute, OrganisationIdAttribute } from '../../../constants/authUserAttributes';
import { getCurrentUser } from '../../../store/users/sagas';
import { setSelectedTabAction } from '../../../store/organisations/actions';
import { computeOrganisationTabs, getSelectedCurrencyRate, getTranslatedText } from '../../../utils/commonFunctions';
import { clearTasksStateAllTableFiltersAction, getTasksActionFilterOptionsRequestAction } from '../../../store/tasks/actions';

const { Title } = Typography;
const { TabPane } = Tabs;

interface IProps {
    readonly isUsingCloudImportType: boolean;
    readonly handlePageViewSelection: (
        tableFilterValue: string | undefined,
        applyFiltersFunction: Function,
        actionBarRefCurrent?: any,
        pageName?: string
    ) => void;
    readonly customerLabel: string;
}
const OrganisationActiveTasksManagementPage: React.FC<IProps> = ({ }: IProps) => {
    const dispatch = useDispatch();
    const customerLabel = useSelector(getCustomerUILabel);
    
    const currentUser = useSelector(getCurrentUser);
    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
    const tasksState: TasksState['activeTasks'] = useSelector(
        (state: ApplicationState) => state.tasks.activeTasks
    );
    const userCompanies: OrganisationsState['userCompanies']['data'] = useSelector(
        (state: ApplicationState) => state.organisations.userCompanies.data
    );
    const [selectedTaskCount, setSelectedTaskCount] = useState<number>(0);
    const [customFieldsFilterList, setCustomFieldsFilterList] = useState<[]>(
        []
    );
    const functionRefObj = useMemo<{
        applyFilters: any,
        updateShowConditionsObject: any
    }>(() => ({
        applyFilters: undefined,
        updateShowConditionsObject: undefined
    }), []);
    const [selectedTab, setSelectedTab] = useState<string>();
    const selectedUserOrganisation: Organisation = useSelector(
        (state: ApplicationState) => state.organisations.selectedUserOrganisation
    );
    const currencyCode: string = useSelector(
        (state: ApplicationState) => state.app.currencyCode
    );
    const conversionCurrency = getSelectedCurrencyRate(
        selectedUserOrganisation,
        currencyCode
    );
    const tabs = useMemo(() => computeOrganisationTabs(userCompanies), [userCompanies]);

    useEffect(() => {
        if (isUndefined(tabs) || isEmpty(tabs)) return;
        setSelectedTab(tabs[0].tabName);
        dispatch(setSelectedTabAction(tabs[0]));
    }, [tabs]);

    const activeTab = find(
        tabs,
        (tab: OrganisationActionTab) => tab.tabName === selectedTab
    );

    let companyIds: string[] = [];
    if (!isUndefined(activeTab)) {
        companyIds = activeTab.companies.map((company) => company.CompanyId);
    }

    const usingCustomerWorkflow = !isUndefined(activeTab)
        ? activeTab.usingCustomerWorkflow
        : false;

    useEffect(() => {
        if (!companyIds || isEmpty(companyIds)) return;

        dispatch(getTasksActionFilterOptionsRequestAction(companyIds, isOrgView));
        getCustomFieldsFilterList(companyIds);
    }, [selectedTab]);
    
    const handleTabChange = (value: string) => {
        setSelectedTab(value);
        if (tabs) {
            const tab = tabs.find(x => x.tabName == value)
            if (tab) {
                dispatch(clearTasksStateAllTableFiltersAction());
                dispatch(setSelectedTabAction(tab));
            }
        }
    };

    /**
     * Function that fetches the custom fields filter list
     */
    const getCustomFieldsFilterList = (companyIds: string[]) => {
        const organisationIdCognito = get(currentUser, OrganisationIdAttribute);
        const selectedOrgId = get(selectedUserOrganisation, 'OrganisationId');

        if (organisationIdCognito === selectedOrgId) {
            const customFieldTypes = usingCustomerWorkflow
                ? [CUSTOM_FIELD_TYPES.CUSTOMER]
                : [CUSTOM_FIELD_TYPES.INVOICE, CUSTOM_FIELD_TYPES.CUSTOMER];
            dispatch(
                getCustomFieldsFilterListByTypesForOrganisation(
                    companyIds,
                    customFieldTypes, // Filters are sorted based on the orders of types here and Number property
                    (response: any) => {
                        if (response.IsSuccess) {
                            setCustomFieldsFilterList(response.CustomFields);
                        }
                    }
                )
            );
        }
    };

    /**
     * Common function for applying the quick search filters
     * @param filters
     * @param fromFilterBar
     */
    const applyQuickSearchFilters = (
        filters?: any,
        fromFilterBar?: boolean
    ) => {
        functionRefObj.updateShowConditionsObject({
            allSelected: false,
            filterBar: true,
        });
        functionRefObj.applyFilters(filters, fromFilterBar, true);
    };

    /**
     * Common function for populating the quick search common filter section
     */
    const populateQSCommonFilterOptions = () => {
        const filterOptions = [
            {
                label: getTranslatedText("Invoice number"),
                value: qsFilterNames.INVOICE,
            },
            {
                label: getTranslatedText(capitalize(customerLabel)),
                value: qsFilterNames.CUSTOMER,
            },
        ];

        if (usingCustomerWorkflow) filterOptions.splice(0, 1);

        return filterOptions;
    };

    const populateContent = () => {
        if (tabs && tabs.length === 1) {
            return <OrganisationActiveTasksContent
                customFieldsFilterList={customFieldsFilterList}
                functionRefObj={functionRefObj}
                setSelectedTaskCount={setSelectedTaskCount}
                region={tabs[0].region}
                companies={tabs[0].companies}
                usingCustomerWorkflow={tabs[0].usingCustomerWorkflow}
                conversionCurrency={conversionCurrency}
            />
        }

        return <Tabs
            activeKey={selectedTab}
            onChange={handleTabChange}
        >
            {map(tabs, (tab) => (
                <TabPane
                    tab={tab.tabName}
                    key={tab.tabName}
                >
                    {selectedTab === tab.tabName && <OrganisationActiveTasksContent
                        customFieldsFilterList={customFieldsFilterList}
                        functionRefObj={functionRefObj}
                        setSelectedTaskCount={setSelectedTaskCount}
                        region={tab.region}
                        companies={tab.companies}
                        usingCustomerWorkflow={tab.usingCustomerWorkflow}
                        conversionCurrency={conversionCurrency}
                    />}
                </TabPane>)
            )}
        </Tabs>
    };

    const getTotalSelectedTaskCount = (selectedTaskCount: number): string => {
        let response: string = getTranslatedText("{selectedTaskCount} selected");
        response = response.replace("{selectedTaskCount}", selectedTaskCount.toString());

        return response;
    }

    return (
        <QueueAnim type={['right', 'left']} leaveReverse>
            <Row key="title-container" type="flex" justify="space-between">
                <Col>
                    <Title level={3}>
                        {getTranslatedText("Active Tasks")}&nbsp;
                        {
                            selectedTaskCount > 0 && (
                                <span className='text-grey'>({getTotalSelectedTaskCount(selectedTaskCount)})</span>
                            )
                        }
                    </Title>
                </Col>
                <Col className="ta-right">
                    <QuickSearchComponent
                        loading={tasksState.loading}
                        customFieldsFilterList={customFieldsFilterList}
                        applyFilters={applyQuickSearchFilters}
                        filterOptions={populateQSCommonFilterOptions()}
                        selectedUserCompany={undefined}
                        companyIds={companyIds}
                        conversionCurrency={conversionCurrency}
                        isOrgView={isOrgView}
                    />
                </Col>
            </Row>
            <div className="spacer-5" />
            {populateContent()}
        </QueueAnim>
    );
};

export default OrganisationActiveTasksManagementPage;